import './HomeStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext, useEffect } from 'react'
import { imgElement3, imgLogo, imgPlacePf3x } from '../../../assets/images/mappet'

import { GoogleLoginButton } from '../../components'
import { MappetContext } from '../../../utils'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const redirectUrl = useNavigate()
  const { user } = useContext(MappetContext)
  const { iconsImages } = useContext(MappetContext)

  useEffect(() => {
    if (user?.id_user && user?.id) redirectUrl('/main/reports')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='home-view'>
      <div className='home-view-left'>
        {iconsImages?.INDEX_IMAGE_2 ? <img className='img-logo' src={iconsImages.INDEX_IMAGE_2.images.original} alt='home cover' /> : <img src={imgPlacePf3x} alt='home-cover' />}
      </div>
      <div className='home-view-right'>
        {iconsImages?.BACKGROUND_IMAGE_3 ? (
          <img className='corner-decoration' src={iconsImages.BACKGROUND_IMAGE_3.images.sm} alt='corner images' />
        ) : (
          <img className='corner-decoration' src={imgElement3} alt='' />
        )}

        {iconsImages?.LOGO_MAPPET ? <img className='img-logo' src={iconsImages.LOGO_MAPPET.images.sm} alt='img-logo-mappet' /> : <img className='img-logo' src={imgLogo} alt='img-logo-mappet' />}
        <p>Inicia sesión para ingresar a la comunidad de MAPPET</p>
        <div className='auth-buttons-container'>
          <GoogleLoginButton />
        </div>
      </div>
    </div>
  )
}

export default Home
