import React, { useContext, useEffect } from 'react'

import { ListImages } from '../../../types'
import { MappetContext } from '../../../utils'
import Modal from './Modal'
import deleteImages from '../../../services/deleteImages'
import deleteImagesRegisterPets from '../../../services/deleteImagesRegisterPets'
import { getErrorsImages } from '../../../utils/getErrorsImages'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  cantImages: ListImages
  setImageModal: React.Dispatch<React.SetStateAction<boolean>>
  setCantImages: React.Dispatch<React.SetStateAction<ListImages>>
  setError: React.Dispatch<React.SetStateAction<string>>
}
const ModalImages = ({ cantImages, setImageModal, setCantImages, setError }: Props) => {
  const { petData, updateReportId, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const deleteImage = (img: File) => {
    if (getErrorsImages(img.name)) setError('')
    let imagerOrigin = cantImages.images
    let indexImages = imagerOrigin.findIndex((image) => image.name === img.name)
    let imagesDelete = cantImages.images.splice(indexImages, 1)
    console.log(imagesDelete)
    setCantImages({
      ...cantImages,
      images: imagerOrigin
    })
    const imageDel = cantImages.imagesDel?.find((image) => image.image === img.name)
    if (updateReportId) deleteImages(imageDel?.id as number, token)
    if (petData) deleteImagesRegisterPets(imageDel?.id as number, token)
  }
  useEffect(() => {
    if (cantImages.images.length === 0) {
      setError('')
      setImageModal(false)
      document.getElementById('img-profile')?.setAttribute('src', iconsImages?.PROFILE_EMPTY?.images.sm as string)
    }
  }, [cantImages.images.length, setError, setCantImages, setImageModal, iconsImages?.PROFILE_EMPTY?.images.sm])

  return (
    <Modal>
      <section className='list-images'>
        <div className='container-image-main'>
          {cantImages.images.map((image, index) => (
            <div className='image-iten-container' key={index}>
              <span className='delete-images' onClick={() => deleteImage(image)}>
                X
              </span>
              <img src={image.name.startsWith('http') ? image.name : URL.createObjectURL(image)} alt={image.name} />
            </div>
          ))}
        </div>
        <button className='btn btn-cancel' onClick={() => setImageModal(false)}>
          Cerrar
        </button>
      </section>
    </Modal>
  )
}

export default ModalImages
