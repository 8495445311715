import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { Pet } from '../../../types'
import ic_delete from '../../../assets/images/mappet/ic_delete.png'
import ic_edit from '../../../assets/images/mappet/ic_edit.png'
import imgDefault from '../../../assets/images/profile/huella.png'
import { petListEfects } from '../../../utils/petListEfects'

interface Props {
  setIdPet: (id: string) => void
}

const PetListResponsive = ({ setIdPet }: Props) => {
  const { petList, setModalAddPet, setPetData, iconsImages } = useContext(MappetContext)
  const handlerShowModal = (id: string) => {
    setIdPet(id)
    petListEfects(petList)
    setModalAddPet(true)
  }

  const handleEdit = (data: Pet | undefined) => {
    petListEfects(petList)
    setPetData(data as Pet)
  }
  return (
    <>
      <div className='list-pets-responsive'>
        <div className='container-list-pets' onClick={() => petListEfects(petList)}>
          <div className='pets'>
            <p>
              Mascotas registradas <span className='length-pets hidden-length'>({petList?.length})</span>
            </p>
            <div className='list-img-pets'>
              <div className='scroll-images'>
                {petList?.map((pet) =>
                  iconsImages?.DEFAULT_ICON ? (
                    <img className='pet-img-responsive' src={pet.images.length === 0 ? iconsImages.DEFAULT_ICON.images.sm : pet.images[0]['image']} alt='pet-group' key={pet.id} />
                  ) : (
                    <img className='pet-img-responsive' src={pet.images.length === 0 ? imgDefault : pet.images[0]['image']} alt='pet-group' key={pet.id} />
                  )
                )}
              </div>
            </div>
          </div>
          <div className='arrows-responsive'>
            <i className='fa-solid fa-chevron-up'></i>
            <i className='fa-solid fa-chevron-down'></i>
          </div>
        </div>
        <div className='display-list-pets'>
          {petList?.map((pet) => (
            <div className='pet-info pet-info-responsive' key={pet.id}>
              {iconsImages?.DEFAULT_ICON ? (
                <img className='pet-img-responsive' src={pet.images.length === 0 ? iconsImages.DEFAULT_ICON.images.sm : pet.images[0]['image']} alt='pet-group' key={pet.id} />
              ) : (
                <img className='pet-img-responsive' src={pet.images.length === 0 ? imgDefault : pet.images[0]['image']} alt='pet-group' key={pet.id} />
              )}
              <div className='info-pet-responsive'>
                <p>{pet.name}</p>
                <p>
                  {pet.breed?.pet_type.name} -- {pet.breed?.breed_name}
                </p>
              </div>
              <div className='buttons-pets'>
                <button className='button btn-edit' onClick={() => handleEdit(pet)}>
                  <img src={ic_edit} alt='editar mascota' />
                </button>
                <button className='button btn-del' onClick={() => handlerShowModal(pet.id as string)}>
                  <img src={ic_delete} alt='eliminar mascota' />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PetListResponsive
