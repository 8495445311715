import { MappetContext, starClass } from '../../../utils'
import React, { useCallback, useContext } from 'react'

import { getDistancePlaceCloser } from '../../../utils/getDistancePlaceCloser'
import { imgMappet } from '../../../assets/images/mappet'

interface PlaceCommentCardProps {
  place: any
}

const PlaceCommentCard = ({ place }: PlaceCommentCardProps) => {
  const { showReportDetails, placeDetailsService, setPlaceDetailsService, setRouteCoords, setGoogleRouteResponse, userLocation, setShowPlaceMap, setShowOtherUser, showOtherUser } =
    useContext(MappetContext)
  place.distance = getDistancePlaceCloser(userLocation?.lat as number, userLocation?.lng as number, place?.latitude, place?.longitude) as unknown as number

  const updateShow = useCallback(() => {
    setPlaceDetailsService(place)
    setRouteCoords(null)
    setGoogleRouteResponse(null)
    setShowPlaceMap(true)
    setShowOtherUser({ ...showOtherUser, isVisible: false })
  }, [place, setGoogleRouteResponse, setPlaceDetailsService, setRouteCoords, setShowOtherUser, setShowPlaceMap, showOtherUser])

  return (
    <div
      className={`report-card ${place.uuid === showReportDetails?.uuid ? 'selected-card' : ''} ${placeDetailsService?.google_place_id === place.google_place_id ? 'selected-service-card' : ''}`}
      onClick={() => {
        updateShow()
      }}
    >
      <div className='left-column'>
        <img src={place.place_images.length > 0 ? place.place_images[0].image : imgMappet} alt='card-cover' />
      </div>
      <div className='right-column'>
        <h4>{place.name}</h4>
        {/* <p>Ubicación: {place.address}</p> */}
        <p>Tel. 3014561821</p>
        <p>Distancia: {place.distance}Km</p>
        <div className='rating-stars-container'>
          {Array(5)
            .fill('')
            .map((e, index) => {
              return <i key={index} className={starClass(index, place.average_rating)}></i>
            })}
        </div>
      </div>
    </div>
  )
}

export default PlaceCommentCard
