import './SurveyStyles.css'
import '../../../assets/css/styles.css'

import React from 'react'
import { Question } from '../../../types'

interface SurveyQuestionProps {
    question: Question
}

const SurveyQuestion = ({ question }: SurveyQuestionProps) => {
    return (
        <div className='form-group survey-question'>
            <label htmlFor='name-pets'>{question.question}</label>
            <input
            type={question.answerType}
            id='name-pets'
            // placeholder='EX. Cocoa'
            />
        </div>
    )
}

export default SurveyQuestion