export const getErrorsImages = (name: string) => {
  let errors = ''
  const regex = ['jpeg', 'png', 'jpg', 'gif', 'bmp']
  const imagesExt = name.split('.')[1]
  if (regex.indexOf(imagesExt as string) !== -1) {
    return errors
  }
  errors = 'Tipo de imagen no permitido'
  return errors
}
