import { PetBreed, PetType } from '../types'

import { getPetBreed } from '../services/getPetBreed'

export const getBreed = (name: string, petType: PetType[] | undefined, setPetBreed: (c: PetBreed[]) => void, token: string) => {
  let pet: PetType | undefined
  pet = petType?.find((pt) => pt.name === name)
  if (pet) {
    getPetBreed(pet?.id as string, token)
      .then((result) => {
        setPetBreed(result.results)
      })

      .catch((err) => console.log(err))
    document.getElementById('breed')?.removeAttribute('disabled')
  } else {
    setPetBreed([])
  }
}
