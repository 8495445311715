import './ReportsStyles.css'

import { PetReport, Place } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'
import { getMyPlaces, getMyReports } from '../../../services'

import ButtonFilter from '../../components/ButtonFilters/ButtonFilter'
import { MappetContext } from '../../../utils'
import { ReportCardList } from '../../components'
import { ReportHook } from '../../../hooks/reportHook'
import { useGetToken } from 'hooks/useGetToken'

const Reports = () => {
  const [filter, setFilter] = useState<string>('all')
  const { showDeleteReportModal, petReports, setPetReports } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const { commentedPlaces, setCommentedPlaces, loadingReports, setLoadingReports } = ReportHook()

  useEffect(() => {
    if (token) {
      getMyReports(token)
        .then((res) => setPetReports(res.results))
        .catch((err) => console.log(err))

      getMyPlaces(token)
        .then((res) => setCommentedPlaces(res.results))
        .catch((err) => console.log(err))

      setLoadingReports(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadingReports, setPetReports, setCommentedPlaces, showDeleteReportModal, token])

  let reports: Array<PetReport | Place> = [...petReports, ...commentedPlaces]

  reports.sort(function (a, b) {
    a.date = a.register_type === 'place' ? a.last_comment_date : a.created_at
    b.date = b.register_type === 'place' ? b.last_comment_date : b.created_at
    var keyA = new Date(a.date!)
    var keyB = new Date(b.date!)
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  return (
    <div className='reports-view'>
      {loadingReports ? (
        <p>Cargando datos...</p>
      ) : reports.length === 0 ? (
        <p>No hay reportes que mostrar</p>
      ) : (
        <>
          <div className='select-filter'>
            <ButtonFilter name='Todos' value='all' setFilter={setFilter} />
            <ButtonFilter name='Perdidas' value='missing' setFilter={setFilter} />
            <ButtonFilter name='Encontradas' value='found' setFilter={setFilter} />
            <ButtonFilter name='Lugares' value='place' setFilter={setFilter} />
          </div>
          <ReportCardList reports={reports} filter={filter as string} />
        </>
      )}
    </div>
  )
}

export default Reports
