import '../../../assets/css/styles.css'
import './DetailsOtherUser.css'

import { MappetContext, starClass } from '../../../utils'
import { Pet, PetReport } from '../../../types'
import { useContext, useState } from 'react'

import ButtonFilter from '../ButtonFilters/ButtonFilter'
import ViewerImagesOtherUser from '../ViewerImages/ViwerImagesOtherUser'
import defaultImg from '../../../assets/images/mappet/img-mappet.png'
import ic_back from '../../../assets/images/mappet/ic_back.png'
import useGetOtherUsers from '../../../hooks/useGetOtherUsers'

const DetailsOtherUser = () => {
  const [filter, setFilter] = useState<string>('all')
  const [petName, setPetName] = useState<string>('')
  const [showViewer, setShowViewer] = useState(false)
  const { setShowOtherUser, setShowPlaceMap, showOtherUser, iconsImages } = useContext(MappetContext)
  const { ProfileOtherUser, petOtherUser, reportOtherUser, placestOtherUser } = useGetOtherUsers(showOtherUser.idPlace)

  function hideOtherUser() {
    setShowOtherUser({ ...showOtherUser, isVisible: false })
    setShowPlaceMap(true)
  }
  const reportFilter = reportOtherUser?.filter((report: PetReport) => {
    if (filter === 'missing') return report.report_type === filter
    if (filter === 'found') return report.report_type === filter
    return null
  })

  function viewImagesMax(name: string) {
    setPetName(name)
    setShowViewer(true)
  }

  return (
    <div className='main-details-other-user'>
      <button className='back-button-other-user' onClick={hideOtherUser}>
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt='back to the last view' /> : <img src={ic_back} alt='back to the last view' />}
      </button>
      <section className='section-info-other-user'>
        <div className='details-imgs-other-user'>
          <img
            src={ProfileOtherUser?.profile_picture ? (ProfileOtherUser?.profile_picture as string) : iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.md : defaultImg}
            alt='imagen de perfil'
          />
        </div>
        <h2>{ProfileOtherUser?.name}</h2>
        <p style={{ color: 'red' }}>@Instagram del otro usuario</p>
        <div className='slides-images-other-user'>
          <div className='container-images-other-user'>
            <img src={defaultImg} alt='imagenes de instagram' />
          </div>
          <div className='container-images-other-user'>
            <img src={defaultImg} alt='imagenes de instagram' />
          </div>
          <div className='container-images-other-user'>
            <img src={defaultImg} alt='imagenes de instagram' />
          </div>
          <div className='container-images-other-user'>
            <img src={defaultImg} alt='imagenes de instagram' />
          </div>
          <div className='container-images-other-user'>
            <img src={defaultImg} alt='imagenes de instagram' />
          </div>
        </div>
      </section>
      <hr />
      <section className='section-pets-other-user'>
        <h3>Mascotas</h3>
        <div className='pets-other-user'>
          {petOtherUser?.map((pet) => (
            <>
              <div className='info-pets-other-user' key={pet.id}>
                <div className='img-pets'>
                  {pet.images.length === 0 ? (
                    iconsImages?.DEFAULT_ICON ? (
                      <img src={iconsImages.DEFAULT_ICON.images.sm} alt='local images' />
                    ) : (
                      <img src={defaultImg} alt='local images' />
                    )
                  ) : (
                    <img src={pet.images[0].image} alt='local images' />
                  )}
                </div>
                <div className='description-pets-other-user'>
                  <div className='contain-info-pet'>
                    <p>{pet.name}</p>
                    <span>{pet.breed?.breed_name}</span>
                  </div>
                  <div className='slider-images-pet-other-user'>
                    {pet.images.length !== 0 ? (
                      pet.images.map((petImage) => (
                        <div className='container-images-pet-other-user' key={petImage.id} onClick={() => viewImagesMax(pet.name)}>
                          <img src={petImage.image} alt={petImage.image} />
                        </div>
                      ))
                    ) : (
                      <p>No hay imagenes que mostrar</p>
                    )}
                  </div>
                  {pet.instagram_username ? <p style={{ color: 'red' }}>@{pet.instagram_username}</p> : <p style={{ color: 'red' }}>@Instagram de la mascota</p>}
                  <div className='slider-instagram-pet-other-user'>
                    {pet.instagram_images.length !== 0 ? (
                      pet.instagram_images.map((image, index) => (
                        <div className='container-instagram-pet-other-user' key={`${image.url}-${index}`}>
                          <img src={image.url} alt='instagram images' />
                        </div>
                      ))
                    ) : (
                      <p>No hay Imagenes que mostrar</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
      <hr />
      <section className='section-reports-other-user'>
        <h3>Reportes</h3>
        <div className='buttons-filters-reports'>
          <ButtonFilter name='Todos' value='all' setFilter={setFilter} />
          <ButtonFilter name='Perdidas' value='missing' setFilter={setFilter} />
          <ButtonFilter name='Encontradas' value='found' setFilter={setFilter} />
          <ButtonFilter name='Lugares' value='place' setFilter={setFilter} />
        </div>
        <div className='list-report-other-user'>
          {!placestOtherUser && !reportOtherUser ? (
            <p>No hay reportes que mostrar</p>
          ) : filter === 'all' ? (
            placestOtherUser?.map((places) => (
              <div className='info-reports-other-user' key={places.id}>
                <div className='img-pets'>
                  <img
                    src={places.place.place_images.length > 0 ? places.place.place_images[0].image : iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : defaultImg}
                    alt='Place cover images'
                  />
                </div>
                <div className='description-reports-other-user'>
                  <div className='contain-info-reports'>
                    <p>{places.place.name}</p>
                    <div className='rating-stars-container'>
                      {Array(5)
                        .fill('')
                        .map((e, index) => {
                          return <i key={index} className={starClass(index, places.place.average_rating)}></i>
                        })}
                    </div>
                    <p>{places.comment}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            filter === 'place' &&
            placestOtherUser?.map((places) => (
              <div className='info-reports-other-user' key={places.id}>
                <div className='img-pets'>
                  <img
                    src={places.place.place_images.length > 0 ? places.place.place_images[0].image : iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : defaultImg}
                    alt='Place cover images'
                  />
                </div>
                <div className='description-reports-other-user'>
                  <div className='contain-info-reports'>
                    <p>{places.place.name}</p>
                    <div className='rating-stars-container'>
                      {Array(5)
                        .fill('')
                        .map((e, index) => {
                          return <i key={index} className={starClass(index, places.place.average_rating)}></i>
                        })}
                    </div>
                    <p>{places.comment}</p>
                  </div>
                </div>
              </div>
            ))
          )}
          {filter === 'all'
            ? reportOtherUser?.map((reportPet) => (
                <div className='info-reports-other-user' key={reportPet.id}>
                  <div className='img-pets'>
                    <img src={reportPet.report_image.length !== 0 ? reportPet.report_image[0].image : iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : defaultImg} alt='local images' />
                  </div>
                  <div className='description-reports-other-user'>
                    <div className='contain-info-reports'>
                      {reportPet.pet_name !== 'sin nombre' ? <p>{reportPet.pet_name} - reportPet.breed</p> : <p>Desconocido</p>}
                      <p>{reportPet.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : reportFilter?.map((reportPet) => (
                <div className='info-reports-other-user' key={reportPet.id}>
                  <div className='img-pets'>
                    <img
                      src={reportPet.report_image[0].image ? reportPet.report_image[0].image : iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : defaultImg}
                      alt={reportPet.report_image[0].image}
                    />
                  </div>
                  <div className='description-reports-other-user'>
                    <div className='contain-info-reports'>
                      {reportPet.pet_name !== 'sin nombre' ? <p>{reportPet.pet_name} - reportPet.breed</p> : <p>Desconocido</p>}
                      <p>{reportPet.description}</p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </section>
      {showViewer && (
        <ViewerImagesOtherUser
          setShowViewer={setShowViewer}
          petOtherUser={petOtherUser as Pet[]}
          reportOtherUser={reportOtherUser}
          placesOtherUser={placestOtherUser}
          petName={petName}
          currentImages='0'
          typeDoc='mascotas'
        />
      )}
    </div>
  )
}

export default DetailsOtherUser
