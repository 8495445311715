import './ViewerImages.css'

import { Image, Pet, PetReport, PlaceComment } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import Modal from '../Modal/Modal'
import ic_close from '../../../assets/images/mappet/ic_close.png'

type Props = {
  setShowViewer: React.Dispatch<React.SetStateAction<boolean>>
  currentImages: string | undefined
  typeDoc: string
  petOtherUser: Pet[] | null
  reportOtherUser: PetReport[] | undefined
  placesOtherUser: PlaceComment[] | undefined
  petName: string
}

const ViewerImagesOtherUser = ({ setShowViewer, petOtherUser, petName }: Props) => {
  const [current, setCurrent] = useState(0)
  const [listImages, setListImages] = useState<Image[]>([])
  const { iconsImages } = useContext(MappetContext)
  let indexCurrent: number = petOtherUser?.findIndex((item) => item.name === petName) as number

  function hanldeDirection(direction: string) {
    if (direction === 'left') setCurrent(current === 0 ? 0 : current! - 1)
    if (direction === 'right') setCurrent(current === listImages.length - 1 ? listImages.length - 1 : current! + 1)
  }

  useEffect(() => {
    let index: number = petOtherUser?.findIndex((item) => item.name === petName) as number
    setListImages(petOtherUser ? petOtherUser[index].images : [])
  }, [petName, petOtherUser])

  useEffect(() => {
    const NodeList = document.getElementById('list-img-viewer') as HTMLUListElement
    const currentNode = NodeList?.querySelectorAll('li>img')[current!]
    currentNode &&
      currentNode.scrollIntoView({
        behavior: 'smooth'
      })
  }, [current])
  return (
    <Modal>
      <div className='viewer-container'>
        <section className='header-viewer'>
          <button className='btn-close-viewer' onClick={() => setShowViewer(false)}>
            {iconsImages?.CLOSE ? <img src={iconsImages.CLOSE.images.sm} alt='close modal' /> : <img src={ic_close} alt='close modal' />}
          </button>
          <h2 style={{ textAlign: 'center' }}>Imagen {current + 1}</h2>
          <p style={{ color: 'red' }}>{petOtherUser && petOtherUser[indexCurrent].name}</p>
        </section>
        <div className='container-slide-images-viewer'>
          <ul className='slide-images-viewer' id='list-img-viewer'>
            {listImages.map((image: Image, index) => (
              <li className='container-images-viewer' key={`${index}`}>
                <img src={image.image} alt={image.image} />
              </li>
            ))}
          </ul>
        </div>

        <div className='arrow-images'>
          <button className='arrow-left' onClick={() => hanldeDirection('left')}>
            <i className='fa-solid fa-chevron-left'></i>
          </button>
          <p style={{ color: 'red' }}>
            {current! + 1}/{listImages.length}
          </p>
          <button className='arrow-rigth' onClick={() => hanldeDirection('right')}>
            <i className='fa-solid fa-chevron-right'></i>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ViewerImagesOtherUser
