import './TipoMascotaStyles.css'

import React, { useContext, useEffect } from 'react'

import { MappetContext } from '../../../utils'
import { Pet } from '../../../types'
import { getBreed } from '../../../utils/getBreed'
import puntos from '../../../assets/images/typesPets/puntos-suspensivos.png'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  petData: Pet | undefined
  setOtherTypes: React.Dispatch<React.SetStateAction<string>>
  otherTypes: string
}

const TipoMascotas = ({ petData, setOtherTypes, otherTypes }: Props) => {
  const { petType, setPetBreed, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const petDefaults = petType?.filter((pet) => pet.icon !== null).map((pet) => pet.name)
  useEffect(() => {
    if (petData) {
      const nameSearch = petData?.breed?.pet_type.name
      const listPetNames = petType?.map((pet) => pet.name)
      if (listPetNames?.includes(nameSearch as string)) {
        if (nameSearch === otherTypes && !petDefaults?.includes(otherTypes as string)) {
          let element = document.getElementById('otros') as HTMLInputElement
          element.checked = true
          document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: flex')
        } else if (petDefaults?.includes(nameSearch as string) || petDefaults?.includes(otherTypes as string)) {
          let element = document.getElementById(nameSearch as string) as HTMLInputElement
          if (element) element.checked = true
          document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: none')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherTypes, petData])
  return (
    <div className='pet-list-container'>
      {petType
        ?.filter((pet) => pet.icon !== null)
        .map((pet) => (
          <div className='pet-type' key={pet.id}>
            <label htmlFor={pet.name} className='img-container'>
              <img src={pet.icon} alt={pet.name} />
            </label>
            <input
              type='radio'
              name='list-pets'
              id={pet.name}
              onChange={(e) => {
                getBreed(pet.name, petType, setPetBreed, token)
                document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: none')
                setOtherTypes('')
              }}
              defaultChecked={petData ? petData?.breed?.pet_type.name === pet.name && true : 'Perro' === pet.name && true}
            />
            <label htmlFor={pet.name}>{pet.name}</label>
          </div>
        ))}
      <div className='pet-type' style={{ marginLeft: '1%' }}>
        <label htmlFor='otros' className='img-container'>
          {iconsImages?.OTHER ? <img src={iconsImages.OTHER.images.sm} alt='puntos sumpensivos' /> : <img src={puntos} alt='puntos sumpensivos' />}
        </label>
        <input
          type='radio'
          name='list-pets'
          id='otros'
          onClick={(e) => {
            document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: flex')
            setOtherTypes('')
          }}
        />
        <label htmlFor='otros'>Otros</label>
      </div>
    </div>
  )
}

export default TipoMascotas
