export const createReportPetFriendly = async (data: FormData, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/places/comment/create/`, {
    method: 'POST',
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: data
  })
  const resJson = await response.json()
  return resJson
}
