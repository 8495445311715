import '../../../assets/css/styles.css'
import './AccountComponentsStyles.css'

import { Pet, UserData } from '../../../types'
import React, { useContext, useEffect } from 'react'

import { MappetContext } from '../../../utils'
import { getListPets } from '../../../services/getListPets'
import { imgMappet } from '../../../assets/images/mappet'
import { useGetToken } from 'hooks/useGetToken'
import { useNavigate } from 'react-router-dom'

interface Props {
  setShowDocPets: React.Dispatch<React.SetStateAction<boolean>>
  setDocPets: React.Dispatch<React.SetStateAction<Pet | undefined>>
}

const AccountPetList = ({ setShowDocPets, setDocPets }: Props) => {
  const { user, setPetList, petList, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const redirectUrl = useNavigate()

  function handleShowDocPets(pet: Pet, showPet: boolean) {
    setDocPets(pet)
    setShowDocPets(showPet)
  }
  useEffect(() => {
    //traer el listado de mascotas
    if (token) {
      getListPets(user as UserData, token)
        .then((pets) => {
          setPetList(pets.results)
        })
        .catch((error) => console.log(error))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div className='petlist-container'>
      {petList?.length !== 0 ? (
        petList?.map((pet) => (
          <div
            className='pet-info-reports'
            key={pet.id}
            onClick={() => {
              handleShowDocPets(pet, true)
            }}
          >
            <img src={pet.images.length > 0 ? pet.images[0]['image'] : imgMappet} alt='pet-group' />
            <div className='info-pet-reports'>
              <p>{pet.name}</p>
              <p>
                {pet.breed?.pet_type.name} -- {pet.breed?.breed_name}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className='no-pets'>
          <p>No hay mascotas que mostrar</p>
        </div>
      )}
      <div className='petlist-footer'>
        <button onClick={() => redirectUrl('/main/register/pets')} className='add-button'>
          {iconsImages?.ADD ? <img src={iconsImages.ADD.images.sm} alt={iconsImages.ADD.key} /> : <i className='fa-solid fa-plus'></i>}
        </button>
        <div className='petlist-footer-text'>
          <h3>Agregar</h3>
          <p>Nueva mascota</p>
        </div>
      </div>
    </div>
  )
}

export default AccountPetList
