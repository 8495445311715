import './LoggedViewStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { Navbar } from '../../components'

interface LoggedViewProps {
  component: JSX.Element
}

const LoggedView = ({ component }: LoggedViewProps) => {
  // TODO: colocar validaciones aquí
  const { navbarCollapseClass, setNavbarCollapseClass } = useContext(MappetContext)

  const onToggleCollapse = () => {
    let collapseClass: string = 'collapse'
    setNavbarCollapseClass(navbarCollapseClass.includes(collapseClass) ? '' : collapseClass)
  }

  return (
    <div className='logged-view'>
      <div className={`logged-view-left ${navbarCollapseClass}`}>
        <Navbar />
      </div>
      <div className={`logged-view-right ${navbarCollapseClass}`}>
        <button onClick={() => onToggleCollapse()} className='toggle-collapse'>
          <i className='fa-solid fa-bars'></i>
        </button>
        {component}
      </div>
    </div>
  )
}

export default LoggedView
