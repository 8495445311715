export const getPetType = async (token: string) => {
  const API_TOKEN = token
  const url = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${url}/api/v1/pets/pet_type/list/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const resTypePet = await response.json()
  resTypePet.results.forEach((res: any) => (res.icon = res.icon && res.icon.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, url)))
  return resTypePet
}
