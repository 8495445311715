import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import { imgSubmitted } from '../../../assets/images/mappet'

const FinishedSurveyModal = () => {
  const { showFinishedSurveyModal, setShowFinishedSurveyModal } = useContext(MappetContext)

  return (
    <Modal>
      <div className='container-submitted-modal'>
        <img className='' src={imgSubmitted} alt='' />
        <h3>¡Gracias!</h3>
        <p>{showFinishedSurveyModal}</p>
        <div className='details-buttons'>
          <button className='btn btn-edit' onClick={() => setShowFinishedSurveyModal(null)}>
            Continuar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default FinishedSurveyModal
