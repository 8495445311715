import './ServicesStyles.css'

import { useContext, useEffect, useState } from 'react'

import CardsViews from './CardsViews'
import DetailsOtherUser from '../../components/OtherUser/DetailsOtherUser'
import Filters from '../../components/Services/Filters'
import Map from './Map'
import { MappetContext } from '../../../utils'
import ModalGetCoords from '../../components/Modal/ModalGetCoords'
import { PlaceDetailService } from '../../components'
import { useLoadScript } from '@react-google-maps/api'

const Services = () => {
  const { typeView, setUpdateReportId } = useContext(MappetContext)
  const { placeDetailsService, showOtherUser, showPlaceMap, showGetCoord } = useContext(MappetContext)
  const [nameFilters, setNameFilters] = useState('')

  useEffect(() => {
    setUpdateReportId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
  })

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div className='main-map'>
      <Filters setNameFilters={setNameFilters} />
      {showOtherUser.isVisible && <DetailsOtherUser />}
      {showPlaceMap && <PlaceDetailService placeData={placeDetailsService} />}
      {typeView === 'map' ? <Map nameFilters={nameFilters} /> : <CardsViews />}
      {showGetCoord && <ModalGetCoords />}
    </div>
  )
}

export default Services
