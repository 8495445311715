import './ReportsStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'

import Background1 from '../../../assets/images/backgrounds/img-element2.png'
import { DetailSlider } from '..'
import { Image } from '../../../types'
import { MappetContext } from '../../../utils'
import { ProfileDefaultImg } from '../../../assets/images/default_images'
import { imgMappet } from '../../../assets/images/mappet'

interface Report {
  // reportData: PetReport
  reportData: any
}

const PetReportDetail = ({ reportData }: Report) => {
  const { setShowReportDetails, showDeleteReportModal, setSelectPage, setUpdateReportId, iconsImages } = useContext(MappetContext)

  let reportImages: Array<string> = reportData.report_image.map((image: Image) => {
    return image.image
  })

  const switchTab = () => {
    const myReportsTab = document.getElementById('mis-reportes')
    const reportTab = document.getElementById('reportar')

    reportTab?.classList.add('active-page')
    myReportsTab?.classList.remove('active-page')

    setSelectPage('reportar')
    setShowReportDetails(null)
    setUpdateReportId(reportData.id)
  }
  return (
    <div className={`details-container ${showDeleteReportModal ? 'showing-delete-report-modal' : ''}`}>
      <button className='back-button' onClick={() => setShowReportDetails(null)}>
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt='back images' /> : <i className='fa-solid fa-arrow-left'></i>}
      </button>
      <div className='details-imgs'>
        {reportData.report_image.length === 0 ? (
          <img className='report-img' src={iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : imgMappet} alt='report-img1' />
        ) : reportData.report_image.length === 1 ? (
          <img className='report-img' src={reportData.report_image[0].image} alt='report-img2' />
        ) : (
          <DetailSlider slides={reportImages} />
        )}
        {iconsImages?.BACKGROUND_IMAGE_2 ? <img className='background-img' src={iconsImages.BACKGROUND_IMAGE_2.images.md} alt='' /> : <img className='background-img' src={Background1} alt='' />}
      </div>
      <h2>{`Mascota ${reportData.report_type === 'missing' ? 'perdida' : 'encontrada'}`}</h2>
      {reportData.report_type === 'missing' && (
        <h3 style={{ textAlign: 'center' }}>
          {reportData.pet_name} - {reportData.breed}
        </h3>
      )}
      <p className='place-categories'>{reportData.address}</p>
      <hr />
      <div className='comment-container'>
        <div className='comment-left'>
          <img
            className='user-profile-img'
            src={reportData.profile.profile_picture ? reportData.profile.profile_picture : iconsImages?.DEFAULT_USER_COMMENT ? iconsImages.DEFAULT_USER_COMMENT.images.sm : ProfileDefaultImg}
            alt={reportData.profile.profile_picture ? 'tiene-foto' : 'no-tiene'}
          />
        </div>
        <div className='comment-right'>
          <h4>{reportData.profile.name}</h4>
          <div className='comment'>
            <p>{reportData.description}</p>
          </div>
        </div>
      </div>
      <button className='edit-button' onClick={switchTab}>
        <i className='fa fa-solid fa-pen-to-square'></i>
      </button>
    </div>
  )
}

export default PetReportDetail
