import './PhoneViewWhatsapp.css'

import React, { useState } from 'react'

import { Footer } from '../../components'
import ModalValidateWhatsApp from '../../components/Modal/ModalValidateWhatsApp/ModalValidateWhatsApp'
import logo from '../../../assets/images/mappet/img-logo.png'

let regexTestCel = /^3[0-2]{1}[0-9]{1}[0-9]{7}$/
const PhoneViewWhatsapp = () => {
  const [ModalValidate, setModalValidate] = useState(false)
  const [cellValue, setCellValue] = useState<string>('')
  const [warning, setwarning] = useState('Llegará un mensaje al WhatsApp')

  function handleChangeCell(e: React.ChangeEvent<HTMLInputElement>) {
    document.getElementById('number')?.addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault()
      }
    })
    if (typeof parseInt(e.target.value) !== 'number') return
    if (cellValue.length !== 0) {
      setwarning('Llegará un mensaje al WhatsApp')
    }
    setCellValue(e.target.value)
  }

  function handleGetCellPhone(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!cellValue) return setwarning('Debe digitar un numero valido para continuar')
    if (cellValue.length < 10 || cellValue.length > 10) return setwarning('Por favor, ingrese un número válido')
    if (!regexTestCel.test(cellValue)) return setwarning('Por favor, ingrese un numero de celular colombiano valido')
  }
  return (
    <div className='container-cel'>
      <div className='container-form form-phone'>
        <img className='img-logo' src={logo} alt='img-logo-mappet' />
        <p>Ingresa tu teléfono para ingresar a la comunidad. Recuerda que llegará un codigo de seguridad a tu WhatsApp.</p>
        <form className='form' onSubmit={(e) => handleGetCellPhone(e)}>
          <div className='form-data'>
            <div className='form-group'>
              <label htmlFor='number'>Numero de teléfono:</label>
              <input style={{ width: '30%', minWidth: '250px' }} className='cell-phone' type='number' id='number' placeholder='Ex. 3002568765' value={cellValue} onChange={handleChangeCell} />
              <span style={{ color: 'red', marginTop: '.2em' }}>{warning}</span>
            </div>
          </div>
          <div className='form-group'>
            <button className='button button-wcolor'>Continuar</button>
          </div>
        </form>
        {ModalValidate && <ModalValidateWhatsApp setModalValidate={setModalValidate} />}
      </div>
      <Footer />
    </div>
  )
}

export default PhoneViewWhatsapp
