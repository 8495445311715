function starClass(pos: number, rating: number): string {
  let className: string = 'rating-paw fa-paw fa-solid'

  if (rating < pos + 0.5) {
    return `${className} empty`
  }

  return `${className} fill`
}

export default starClass
