import { Place } from '../types'

const getMyPlaces = async (token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const placesListPromise = await fetch(`${URL}/api/v1/places/list/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })

  const placesList = await placesListPromise.json()

  placesList.results.forEach((place: Place) => {
    place.register_type = 'place'

    place.place_images.forEach((element: any) => {
      element.image = element.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
    })
  })

  return placesList
}

export default getMyPlaces
