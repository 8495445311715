import './GoogleLoginButtonStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'
import { UseGoogleLoginOptionsAuthCodeFlow, useGoogleLogin } from '@react-oauth/google'

import CryptoJS from 'crypto-js'
import { GoogleUser } from '../../../types'
import { Link } from 'react-router-dom'
import { MappetContext } from '../../../utils'
import axios from 'axios'
import { logoGoogle } from '../../../assets/images/modal'
import { useNavigate } from 'react-router-dom'
import { userExists } from '../../../services/userExists'

const GoogleLoginButton = () => {
  const { setModalVisible, setUser } = useContext(MappetContext)
  let redirectUrl = useNavigate()

  const login = useGoogleLogin({
    onSuccess: async (response: UseGoogleLoginOptionsAuthCodeFlow) => {
      try {
        const data = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${Object(response).access_token}`
          }
        })
        const dataGoogle: GoogleUser | undefined = {
          name: data.data.name,
          email: data.data.email,
          sub: data.data.sub,
          authMethod: 'google',
          email_verified: data.data.email_verified,
          azp: data.data.azp,
          aud: data.data.aud,
          exp: data.data.exp,
          family_name: data.data.family_name,
          given_name: data.data.given_name,
          iat: data.data.iat,
          iss: data.data.iss,
          jti: data.data.jti,
          nbf: data.data.nbf,
          picture: data.data.picture
        }
        localStorage.setItem('dataGoogle', JSON.stringify(dataGoogle))
        setModalVisible(false)
        userExists(dataGoogle.sub as string)
          .then((res: any) => {
            if (res.detail === 'Usuario no existe') {
              redirectUrl('/main/register/users')
            } else {
              let userDataString = JSON.stringify(res)
              const cipherUserData = CryptoJS.AES.encrypt(userDataString, process.env.REACT_APP_ENC_SECRET_KEY!).toString()
              localStorage.setItem('user', cipherUserData)
              // localStorage.setItem('user', JSON.stringify(res))
              setUser(res)
              localStorage.setItem('token', JSON.stringify(res.token))
              redirectUrl('/main/reports')
            }
          })
          .catch((e) => {
            console.log('Error de conexión')
            console.log(e)
          })
      } catch (err) {
        console.log(err)
      }
    }
  })

  return (
    <div>
      <Link onClick={() => login()} to='#' className='button button-google'>
        <img className='logo-button' src={logoGoogle} alt='logo-google' />
        Google
      </Link>
    </div>
  )
}

export default GoogleLoginButton
