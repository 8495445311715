import './ViewerImages.css'

import { DocumentsPets, Pet } from '../../../types'
import React, { useEffect, useState } from 'react'

import EditViewerDocs from './EditViewerDocs'
import Modal from '../Modal/Modal'
import OptionsVIewer from './OptionsVIewer'
import { getListDocPets } from '../../../services/getListDocPets'
import ic_close from '../../../assets/images/mappet/ic_close.png'
import { useGetToken } from 'hooks/useGetToken'

type Props = {
  listDocs: DocumentsPets[]
  petName: Pet | undefined
  setShowViewer: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentImages: React.Dispatch<React.SetStateAction<number | undefined>>
  currentImages: number | undefined
  setListDocs: (value: any) => void
  titleDoc: React.MutableRefObject<string | undefined>
}

const ViewerImages = ({ listDocs, petName, setShowViewer, currentImages, setListDocs, setCurrentImages, titleDoc }: Props) => {
  const [current, setCurrent] = useState(0)
  const { data: token } = useGetToken()
  const [showOptions, setShowOptions] = useState(false)
  const [showEditDoc, setShowEditDoc] = useState(false)
  const [filterDocs, setFilterDocs] = useState<DocumentsPets[]>(listDocs.filter((image) => image.document_type === titleDoc.current))

  const [dataEdit, setDataEdit] = useState({
    name: '',
    id: 0,
    image: ''
  })

  function showOptionViewer() {
    setShowOptions(true)
  }

  function hanldeDirection(direction: string) {
    if (direction === 'left') setCurrent(current === 0 ? 0 : current! - 1)
    if (direction === 'right') setCurrent(current === listDocs!.length - 1 ? listDocs!.length - 1 : current! + 1)
  }
  useEffect(() => {
    if (filterDocs.length === 0) {
      setShowViewer(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDocs])
  useEffect(() => {
    if (token) {
      getListDocPets(petName?.id as string, token).then((docs: any) => {
        const newArray = docs.results.filter((image: DocumentsPets) => image.document_type === titleDoc.current)
        setFilterDocs(newArray)
        setListDocs(docs.results)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditDoc, token])
  useEffect(() => {
    if (filterDocs) {
      let newIndex = filterDocs?.findIndex((item) => item.id === currentImages)

      if (newIndex === -1) {
        setCurrent(filterDocs?.length - 1)
        if (filterDocs[`${current as number}`] !== undefined) setCurrentImages(filterDocs[`${current as number}`].id)
      } else {
        setCurrent(newIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImages, filterDocs, setCurrentImages])
  useEffect(() => {
    const NodeList = document.getElementById('list-img-viewer') as HTMLUListElement
    const currentNode = NodeList?.querySelectorAll('li>img')[current!]
    currentNode &&
      currentNode.scrollIntoView({
        behavior: 'smooth'
      })
  }, [current, showEditDoc])

  useEffect(() => {
    if (filterDocs && filterDocs[`${current as number}`] !== undefined) {
      setDataEdit({
        ...dataEdit,
        name: filterDocs[`${current as number}`].name as string,
        id: filterDocs[`${current as number}`].id as number,
        image: filterDocs[`${current as number}`].image as string
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, filterDocs, showEditDoc])
  return (
    <Modal>
      <div className='viewer-container'>
        {!showEditDoc && (
          <>
            <section className='header-viewer'>
              <button className='btn-close-viewer' onClick={() => setShowViewer(false)}>
                <img src={ic_close} alt='cerrar modal' />
              </button>
              {current !== -1 && listDocs && listDocs[`${current as number}`] !== undefined ? <h2 style={{ textAlign: 'center' }}>{listDocs[`${current as number}`].name}</h2> : <p>sin nombre</p>}

              <p style={{ color: 'red' }}>{petName && petName.name}</p>
            </section>
            <div className='container-slide-images-viewer'>
              <ul className='slide-images-viewer' id='list-img-viewer'>
                {listDocs ? (
                  filterDocs.map((doc, index) => (
                    <li className='container-images-viewer' key={`${doc.name}-${index}`}>
                      <img src={doc.image} alt={doc.name} />
                    </li>
                  ))
                ) : (
                  <li>sin imagen</li>
                )}
              </ul>
            </div>
            {!showOptions ? (
              <div className='option-menu' onClick={showOptionViewer}>
                <i className='fa-solid fa-ellipsis'></i>
              </div>
            ) : (
              <OptionsVIewer setShowOptions={setShowOptions} setShowEditDoc={setShowEditDoc} />
            )}

            <div className='arrow-images'>
              <button className='arrow-left' onClick={() => hanldeDirection('left')}>
                <i className='fa-solid fa-chevron-left'></i>
              </button>
              <p style={{ color: 'red' }}>
                {current! + 1}/{filterDocs?.length}
              </p>
              <button className='arrow-rigth' onClick={() => hanldeDirection('right')}>
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </div>
          </>
        )}

        {showEditDoc && <EditViewerDocs name={dataEdit.name as string} id={dataEdit.id as number} setShowEditDoc={setShowEditDoc} image={dataEdit.image as string} />}
      </div>
    </Modal>
  )
}

export default ViewerImages
