import { UserData } from '../types'

export const uploadProfilePicture = async (reference: React.MutableRefObject<HTMLInputElement | null>, user: Partial<UserData>) => {
  const form_data = new FormData()
  form_data.append('profile_picture', reference.current?.files?.item(0)!)
  const API_TOKEN = user?.token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/photo/`, {
    method: 'PATCH',
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: form_data
  }).then((res) => res.json())

  return response
}
