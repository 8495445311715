import './CardStyles.css'

import { ModalDeleteReport, PetReportCard, PlaceCommentCard } from '..'
import { PetReport, Place } from '../../../types'
import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import { MappetContext } from '../../../utils'

interface ReportProps {
  cardData: PetReport | Place
}

const Card = ({ cardData }: ReportProps) => {
  const { setShowReportDetails, setCurrentIndex } = useContext(MappetContext)
  const { showDeleteReportModal, setShowDeleteReportModal } = useContext(MappetContext)

  return (
    <div>
      {showDeleteReportModal && <ModalDeleteReport reportId={cardData.id} setShowDeleteReportModal={setShowDeleteReportModal} />}
      <Link
        to='#'
        className='report-link'
        onClick={() => {
          setShowReportDetails(cardData)
          setCurrentIndex(0)
        }}
      >
        {cardData.register_type === 'place' ? <PlaceCommentCard place={cardData} /> : <PetReportCard petReport={cardData as PetReport} setShowDeleteReportModal={setShowDeleteReportModal} />}
      </Link>
    </div>
  )
}

export default Card
