import './Page404Styles.css'

import React from 'react'
import imgLost from '../../../assets/images/mappet/me_perdi_404.jpeg'

const Page404 = () => {
  return (
    <div className='main-404'>
      <section className='sub-main-404'>
        <img className='imagen-lost' src={imgLost} alt='imagen cuando entran a una url que no existe' />
        <span>404</span>
      </section>
      <h3>Page not found</h3>
    </div>
  )
}

export default Page404
