import React from 'react'
interface Props {
  title: string
  description: string
}
const ModalRegisterSuccess = ({ title, description }: Props) => {
  return (
    <div className='container-modal-new-pet' id='reg-success'>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}

export default ModalRegisterSuccess
