import '../../../assets/css/styles.css'
import './RatingStarsStyles.css'

import React from 'react'
import { starClass } from '../../../utils'

interface RatingStarsProps {
  rating: number
}

const RatingStars = ({ rating }: RatingStarsProps) => {
  return (
    <div className='rating-stars-container'>
      {Array(5)
        .fill('')
        .map((e, index) => {
          return <i key={index} className={starClass(index, rating)}></i>
        })}
    </div>
  )
}

export default RatingStars
