export async function unlinkInstagram(id: string | null, token: string) {
  const URL = process.env.REACT_APP_BACKEND_URL
  if (!id) {
    const response = await fetch(`${URL}/api/v1/profile/unlink_instagram/`, {
      method: 'PATCH',
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: `${token}`
      }
    })

    const resUnlink = await response.json()
    return resUnlink
  } else {
    const response = await fetch(`${URL}/api/v1/pets/unlink_instagram/${id}/`, {
      method: 'PATCH',
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: `${token}`
      }
    })

    const resUnlink = await response.json()
    return resUnlink
  }
}
