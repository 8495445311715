import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import { UserData } from '../../../types'
import { deletePets } from '../../../services/deletePets'
import { getListPets } from '../../../services/getListPets'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  idPet: string
}

const ModalDetailsPets = ({ idPet }: Props) => {
  const { setModalAddPet, setPetList, user, setPetData } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const handlerDelete = () => {
    deletePets(idPet as string, user?.token as string).then((res) => {
      if (res.ok) {
        setPetData(undefined)
        getListPets(user as UserData, token)
          .then((pets) => {
            setPetList(pets.results)
          })
          .catch((error) => console.log(error))
        setModalAddPet(false)
      }
    })
  }
  return (
    <Modal>
      <div className='container-details-modal'>
        <span className='close' onClick={() => setModalAddPet(false)}>
          X
        </span>
        <div className='details-pets'>
          <div className='details-info'>
            <h2>Eliminar mascota</h2>
            <p>Una vez se realiza el proceso no se podrá recuperar la informacion, incluidas las imagenes de la ficha de la mascota: ¿Estas seguro que deseas eliminarla?</p>
          </div>
        </div>
        <div className='details-buttons'>
          <button className='btn button-close-terms' onClick={() => handlerDelete()}>
            Eliminar
          </button>
          <button className='btn' style={{ color: '#1e1547' }} onClick={() => setModalAddPet(false)}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDetailsPets
