import './ReportsStyles.css'

import { PetReport, Place } from '../../../types'

import { Card } from '..'
import React from 'react'

interface ReportListProps {
  reports: Array<PetReport | Place>
  filter: string
}

const ReportCardList = ({ reports, filter }: ReportListProps) => {
  const reportFilter = reports.filter((report) => {
    if (filter === 'place') return report.register_type === filter
    return report.report_type === filter
  })

  // const currentUserId: string = 'asdf4568da'
  // const currentUserReports: Array<PetReport | Place> = reports.filter((report) => report.uuid === currentUserId)

  return (
    <div className='report-cards-container'>
      {filter !== 'all'
        ? reportFilter.map((report, index) => {
            return <Card key={report.uuid} cardData={report} />
          })
        : reports.map((report, index) => {
            return <Card key={report.uuid} cardData={report} />
          })}
    </div>
  )
}

export default ReportCardList
