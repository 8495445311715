export const getAboutMappet = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/about`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'text/html',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    }
  })
  const res = await response.text()
  return res
}
