import './ViewerImages.css'

import React, { useEffect, useState } from 'react'

import ModalDeleteDoc from '../Modal/ModalDeleteDoc'
import ic_back from '../../../assets/images/mappet/ic_back.png'
import ic_delete from '../../../assets/images/mappet/ic_delete.png'
import { updateDocPet } from '../../../services/updateDocPets'
import { useGetToken } from 'hooks/useGetToken'

type Props = {
  name: string
  id: number
  image: string
  setShowEditDoc: React.Dispatch<React.SetStateAction<boolean>>
}

const EditViewerDocs = ({ name, id, setShowEditDoc, image }: Props) => {
  const [nameDoc, setNameDoc] = useState<string>('')
  const [showModalDel, setShowModalDel] = useState(false)
  const { data: token } = useGetToken()

  function updateDocumentsPets() {
    updateDocPet(nameDoc, id, token).then((res) => setShowEditDoc(false))
  }

  useEffect(() => {
    setNameDoc(name)
  }, [name])
  return (
    <div className='container-edit-docs'>
      {showModalDel && <ModalDeleteDoc idDoc={id} setShowEditDoc={setShowEditDoc} setShowModalDel={setShowModalDel} />}
      <header className='group-buttons-doc-image'>
        <button className='btn-back-doc' onClick={() => setShowEditDoc(false)}>
          <img src={ic_back} alt='regresar a la vista anterior' />
        </button>
        <button className='btn-del-image-doc' onClick={() => setShowModalDel(true)}>
          <img src={ic_delete} alt='eliminar documento' />
        </button>
      </header>
      <section className='container-image-edit'>
        <img src={image} alt='imagen del documento' />
      </section>
      <footer className='footer-save-doc'>
        <div className='form-group form-group-doc'>
          <label htmlFor='title-imge'>Titulo de la imagen</label>
          <input type='text' name='title-image' id='title-image' placeholder='Ex. Mi Imagen' value={nameDoc} onChange={(e) => setNameDoc(e.target.value)} />
          <button className='btn btn-save-doc' onClick={updateDocumentsPets}>
            Guardar Informacion
          </button>
        </div>
      </footer>
    </div>
  )
}

export default EditViewerDocs
