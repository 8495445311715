import { PetReport } from '../types'

export const createReportPetLots = async (data: PetReport, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/reports/create/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: JSON.stringify(data)
  })
  const resJson = await response.json()
  return resJson
}
