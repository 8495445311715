import './ReportsStyles.css'

import React, { useContext, useEffect } from 'react'

import FormReports from './FormReports'
import { Link } from 'react-router-dom'
import { MappetContext } from '../../../utils'
import ModalGetCoords from '../../components/Modal/ModalGetCoords'
import { ReportDetail } from '../../components'
import Reports from './Reports'
import { updateClass } from '../../../utils/updateClass'

const ReportsViews = () => {
  const { setShowReportDetails, showReportDetails, showDeleteReportModal, selectPage, setSelectPage, setUpdateReportId, setShowOtherUser, showOtherUser, setShowPlaceMap, showGetCoord } =
    useContext(MappetContext)

  const handleSelectPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setSelectPage(e.currentTarget.id)
    updateClass(e)
  }
  useEffect(() => {
    setShowOtherUser({ ...showOtherUser, isVisible: false })
    setShowPlaceMap(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setSelectPage('reportar')
  }, [setSelectPage])

  return (
    <div className={`report-container ${showReportDetails ? 'showing-details' : ''} ${showDeleteReportModal ? 'showing-delete-report-modal' : ''}`}>
      {/*  <img className='imgCorner' src={imagenCorner} alt='imagen que va en la esquina superior derecha' />*/}
      {showReportDetails && <ReportDetail reportData={showReportDetails} />}
      <div className={`report-container-right ${showReportDetails ? 'showing-details' : ''} ${showDeleteReportModal ? 'showing-delete-report-modal' : ''}`}>
        <nav className='navbar-report'>
          <Link
            className='active-page'
            to='#'
            onClick={(e) => {
              handleSelectPage(e)
              setShowReportDetails(null)
            }}
            id='reportar'
          >
            Reportar
          </Link>
          <Link
            to='#'
            onClick={(e) => {
              handleSelectPage(e)
              setUpdateReportId(null)
            }}
            id='mis-reportes'
          >
            Mis Reportes
          </Link>
        </nav>
        <div className='form-report-container'>{selectPage === 'reportar' ? <FormReports /> : <Reports />}</div>
        DetailsOtherUser
      </div>
      {showGetCoord && <ModalGetCoords />}
    </div>
  )
}

export default ReportsViews
