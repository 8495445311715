import './ConfigureSearchStyles.css'
import '../../../assets/css/styles.css'

import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { imgElement3, imgLogo } from '../../../assets/images/mappet'

import { MappetContext } from '../../../utils'
import { PreferencesCard } from '../../components'

const ConfigureSearch = () => {
  const [isMapSelected, setIsMapSelected] = useState<boolean>(true)
  const [view, setView] = useState<string>('map')

  const redirectUrl = useNavigate()
  const { isRegistering, setTypeView } = useContext(MappetContext)

  const handlerView = ()=>{
    setTypeView(view)
  }

  useEffect(() => {
    if (!isRegistering) {
      redirectUrl('/main/services')
    }
  })

  return (
    <div className='configure-search-view'>
      <img className='corner-decoration' src={imgElement3} alt='' />
      <img src={imgLogo} alt='mappet-logo' />
      <h3>Configura tus búsquedas</h3>
      <p>Tu cuenta está creada, pero queremos que la información la veas de la forma más cómoda. Selecciona una opción:</p>
      <div className='buttons-container'>
        <div className='visualization-options'>
          <span onClick={() => {
            setIsMapSelected(true)
            setView('map')}}>
            <PreferencesCard isSelected={isMapSelected} preference='Mapa' />
          </span>
          <span onClick={() => {
            setIsMapSelected(false)
            setView('cards')}}>
            <PreferencesCard isSelected={!isMapSelected} preference='Tarjetas' />
          </span>
        </div>
        <div className='finish-button-container'>
          <Link to='/main/register/success' className='button finish-button' onClick={handlerView}>
            Finalizar
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ConfigureSearch
