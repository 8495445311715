import { ListImages } from '../types'
import { MappetContext } from '../utils/contextApi'
import { createReportPetFriendly } from '../services/createReportPetFriendly'
import toast from 'react-hot-toast'
import { useContext } from 'react'
import { useGetToken } from './useGetToken'

export const useFormReportPetFriendly = (data: any, addr: any, cantImage: ListImages | undefined) => {
  const { user, setShowCommentPlaceModal } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const sentData = (dataForm: any, addr: any, cantImage: ListImages | undefined) => {
    const data = new FormData()

    if (addr) {
      dataForm.address = addr.address
      dataForm.google_place_id = addr.place_id
    }
    dataForm.profile_id = user?.id
    data.append('place_image', cantImage?.images[0] === undefined ? '' : cantImage?.images[0])
    Object.entries(dataForm).forEach(([key, value]) => {
      data.append(`${key}`, `${value}`)
    })
    createReportPetFriendly(data, token)
      .then((res) => {
        console.log('datos del lugar', res)
        toast(
          <div>
            <p style={{ fontWeight: 'bold' }}>Reporte Guardado con exito</p>
            <p>Los datos se guardaron exitosamente</p>
          </div>,
          {
            position: 'top-right',
            style: {
              color: '#ffffff',
              backgroundColor: 'red'
            }
          }
        )
        setShowCommentPlaceModal(null)
      })
      .catch((err) => console.log(err))
  }

  return {
    sentData
  }
}
