import FormPets from '../../components/Form/FormPets'
import FormUser from '../../components/Form/FormUser'
import React from 'react'
import { useLocation } from 'react-router-dom'

const RegisterView = () => {
  // TODO: colocar validaciones aquí

  const { pathname } = useLocation()
  return <>{pathname === '/register/users' ? <FormUser /> : <FormPets />} </>
}

export default RegisterView
