import React, { useState } from 'react'

import FriendlyReport from './FriendlyReport'
import PetLostReports from './PetsLostReports'
import { Toaster } from 'react-hot-toast'

const FormReports = () => {
  const [selectForm, setSelectForm] = useState<String>('petLost')
  const [modalAdr, setModalAdr] = useState<Boolean>(false)

  return (
    <>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla veritatis mollitia, explicabo molestias incidunt atque, voluptas doloribus inventore ratione quasi porro vero dolor! Quos
        voluptatum maiores ex corporis molestias praesentium?{' '}
      </p>
      <div className='input-radio'>
        <input id='friendly' type='radio' name='reporte' value='friendly' onClick={(e) => setSelectForm(e.currentTarget.value)} />
        <label htmlFor='friendly' className='checkbox'>
          Lugar pet friendly
        </label>
      </div>
      <div className='input-radio'>
        <input id='petLost' type='radio' name='reporte' value='petLost' defaultChecked onClick={(e) => setSelectForm(e.currentTarget.value)} />
        <label htmlFor='petLost' className='checkbox'>
          Mascota perdida
        </label>
      </div>
      {selectForm === 'petLost' ? <PetLostReports modalAdr={modalAdr} setModalAdr={setModalAdr} /> : <FriendlyReport modalAdr={modalAdr} setModalAdr={setModalAdr} />}
      <Toaster />
    </>
  )
}

export default FormReports
