export const getProfileOtherUser = async (id: string, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/detail/${id}/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const profileOtherUser = await response.json()
  profileOtherUser.profile_picture = profileOtherUser.profile_picture && profileOtherUser.profile_picture.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, process.env.REACT_APP_BACKEND_URL)
  profileOtherUser.pets.forEach((list: any) => {
    list.images.forEach((element: any) => {
      element.image = element.image && process.env.REACT_APP_BACKEND_URL + element.image
    })
  })
  profileOtherUser.comment_places.forEach((list: any) => {
    list.place.place_images.forEach((element: any) => {
      element.image = element.image && process.env.REACT_APP_BACKEND_URL + element.image
    })
  })

  profileOtherUser.reports.forEach((list: any) => {
    list.report_image.forEach((element: any) => {
      element.image = process.env.REACT_APP_BACKEND_URL + element.image
    })
  })

  return profileOtherUser
}
