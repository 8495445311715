export const getPetBreed = async (id: string, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/pets/breed/list/?pet_type_id=${id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const resPetBreed = await response.json()
  return resPetBreed
}
