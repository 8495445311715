import { User } from '../types'

export const createUser = async (data: User) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/create/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    },
    body: JSON.stringify(data)
  })
  const resJson = await response.json()
  return resJson
}
