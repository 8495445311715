export const createPet = async (data: FormData) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/pets/create/`, {
    method: 'POST',
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    },
    body: data
  })
  const resJson = await response.json()
  resJson.images.forEach((element: any) => {
    element.image = element.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
  })
  return resJson
}
