import { ImagesInstagram, Pet, PetReport, PlaceComment, ProfileOtherUser } from '../types'
import { useEffect, useState } from 'react'

import { getProfileOtherUser } from '../services/getProfileOtherUser'
import { useGetToken } from './useGetToken'

const useGetOtherUsers = (id: string) => {
  const [ProfileOtherUser, setProfileOtherUser] = useState<ProfileOtherUser>()
  const [petOtherUser, setPetOtherUser] = useState<Pet[]>()
  const [reportOtherUser, setReportOtherUser] = useState<PetReport[]>()
  const [placestOtherUser, setPlacestOtherUser] = useState<PlaceComment[]>()
  const [listImagesInstagram, setListImagesInstagram] = useState<ImagesInstagram[]>()
  const { data: token } = useGetToken()

  useEffect(() => {
    if (token) {
      getProfileOtherUser(id, token).then((res: ProfileOtherUser) => {
        setProfileOtherUser(res)
        setPetOtherUser(res.pets)
        setReportOtherUser(res.reports)
        setPlacestOtherUser(res.comment_places)
        setListImagesInstagram(res.instagram_images_profile)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token])
  return { ProfileOtherUser, petOtherUser, reportOtherUser, placestOtherUser, listImagesInstagram }
}

export default useGetOtherUsers
