import React from 'react'
import usePlacesCloser from '../../../hooks/usePlacesCloser'

interface Props {
  directions: any
  setDirections: React.Dispatch<React.SetStateAction<never[]>>
  coord: React.MutableRefObject<number[]>
}

const DropDownDirections = ({ directions, setDirections, coord }: Props) => {
  const inputE = document.getElementById('address') as HTMLInputElement
  const { handleGetresults } = usePlacesCloser(setDirections, coord.current, inputE)

  return (
    <div className='container-address'>
      {directions.map((addr: any) => (
        <section className='iten-address' onClick={() => handleGetresults(addr)} key={addr.place_id}>
          <h3>{addr.name}</h3>
          <p>{addr.address}</p>
          <hr />
        </section>
      ))}
    </div>
  )
}

export default DropDownDirections
