import { ImagesEdit, ListImagesEdit, Pet, UserData } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import ModalDeleteImagePet from '../Modal/ModalDeleteImagePet'
import ModalDetailsPets from '../Modal/ModalDetailsPets'
import { getBreed } from '../../../utils/getBreed'
import { getListPets } from '../../../services/getListPets'
import { getPetType } from '../../../services/getPetType'
import ic_back from '../../../assets/images/mappet/ic_back.png'
import ic_delete from '../../../assets/images/mappet/ic_delete.png'
import img_add_image from '../../../assets/images/mappet/img_add_image.png'
import { unlinkInstagram } from '../../../services/unlinkInstagram'
import { useForm } from 'react-hook-form'
import { useGetToken } from 'hooks/useGetToken'
import { useSaveEditPet } from '../../../hooks/useSaveEditPet'

type Props = {
  pet: Pet
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>
  setShowDocPets: React.Dispatch<React.SetStateAction<boolean>>
  setDocPets: React.Dispatch<React.SetStateAction<Pet | undefined>>
}

const AccountEditPet = ({ pet, setShowEdit, setShowDocPets, setDocPets }: Props) => {
  const [images, setImages] = useState<ListImagesEdit>({ imagesLocal: [], imagesServer: [] })
  const [modalDelImg, setModalDelImg] = useState({ view: false, name: '', id: 0 })
  const { iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors }
  } = useForm<Pet>()
  const { sentData } = useSaveEditPet()

  const { petList, petType, user, setPetBreed, petBreed, setPetType, setModalAddPet, modalAddPet, setPetList } = useContext(MappetContext)

  const handlerChangePetType = (name: string = 'Perro') => {
    getBreed(name, petType, setPetBreed, user?.token as string)
  }

  const onSubmit = handleSubmit((values) => {
    sentData(values, images, pet, setShowEdit)
    reset()
  })
  const handlerChangeCantImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImages({ ...images, imagesLocal: [...images.imagesLocal, e.target.files?.item(0)!] })
  }
  function handlerUnlinkInstagram() {
    unlinkInstagram(pet.id as string, user.token as string).then((res: Pet) => {
      setDocPets({ ...pet, instagram_username: res.instagram_username })
    })
  }

  useEffect(() => {
    //obteniendo los tipos de mascotas
    getPetType(user?.token as string)
      .then((res) => {
        setPetType(res.results)
      })
      .catch((error) => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const ifexist = pet.images.some((image) => images.imagesServer?.map((imageS) => imageS.id).includes(image.id))
    if (!ifexist) {
      setImages({
        ...images,
        imagesServer: [...images.imagesServer!, ...pet.images] as ImagesEdit[]
      })
    }
    if (token) getListPets(user as UserData, token).then((res) => setPetList(res.results))
  }, [images, pet.images, setPetList, user, token])

  useEffect(() => {
    getBreed(pet ? (pet.breed?.pet_type.name as string) : 'Perro', petType, setPetBreed, user?.token as string)
    if (pet) {
      setValue('name', pet.name)
      setValue('pet_type', pet.breed?.pet_type.name)
      setTimeout(() => {
        setValue('breed_id', pet?.breed?.id as string)
      }, 1200)
    }
  }, [pet, petType, setPetBreed, setValue, user?.token])

  useEffect(() => {
    let isExist = petList?.find((item) => item.id === pet.id)
    if (isExist === undefined) {
      setShowEdit(false)
      setShowDocPets(false)
    }
  }, [pet.id, petList, setShowDocPets, setShowEdit])
  return (
    <div className='container-edit-pet'>
      {modalDelImg.view && <ModalDeleteImagePet setModalDelImg={setModalDelImg} modalDelImg={modalDelImg} setImages={setImages} images={images} />}
      <button className='btn-back' onClick={() => setShowEdit(false)} style={{ cursor: 'pointer' }}>
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt={iconsImages.BACK.key} /> : <img src={ic_back} alt='back.' />}
      </button>
      <h1>Editar Mascota</h1>
      <div className='container-pet-images'>
        <label htmlFor='add_images' className='images-item'>
          {iconsImages?.ADD_IMAGE ? <img src={iconsImages.ADD_IMAGE.images.sm} alt={iconsImages.ADD_IMAGE.key} /> : <img src={img_add_image} alt='add images' />}
        </label>
        <input
          {...register('images', {
            onChange: (e) => {
              handlerChangeCantImages(e)
            }
          })}
          type='file'
          id='add_images'
          hidden
          multiple
          accept='image/jpeg, image/jpg, image/png, image/gif, image/bmp'
        />
        {images.imagesLocal &&
          images.imagesLocal.map((image: File, idx) => (
            <div className='images-item' key={`${image.name}-${idx}`}>
              <img src={URL.createObjectURL(image as Blob)} alt={image.name} />
              {iconsImages?.DELETE ? (
                <img
                  src={iconsImages.DELETE.images.sm}
                  alt={iconsImages.DELETE.key}
                  className='delete-images'
                  onClick={() => {
                    setModalDelImg({ ...modalDelImg, view: true, name: image.name })
                  }}
                />
              ) : (
                <img
                  src={ic_delete}
                  alt='delete-images'
                  className='delete-images'
                  onClick={() => {
                    setModalDelImg({ ...modalDelImg, view: true, name: image.name })
                  }}
                />
              )}
            </div>
          ))}
        {images.imagesServer &&
          images.imagesServer.map((image: ImagesEdit) => (
            <div className='images-item' key={image.id}>
              <img src={image.image} alt={image.image} />
              {iconsImages?.DELETE ? (
                <img
                  src={iconsImages.DELETE.images.sm}
                  alt={iconsImages.DELETE.key}
                  className='delete-images'
                  onClick={() => {
                    setModalDelImg({ ...modalDelImg, view: true, name: image.image, id: image.id })
                  }}
                />
              ) : (
                <img
                  src={ic_delete}
                  alt='delete-images'
                  className='delete-images'
                  onClick={() => {
                    setModalDelImg({ ...modalDelImg, view: true, name: image.image, id: image.id })
                  }}
                />
              )}
            </div>
          ))}
      </div>
      <hr />
      <section className='container-form-edit'>
        <form id='form-edit-pet' className='form' encType='multipart/form-data' onSubmit={onSubmit}>
          <div className='form-group'>
            <label htmlFor='name-pets'>Nombre de tu mascota:</label>
            <input
              type='text'
              {...register('name', {
                required: {
                  value: true,
                  message: 'Nombre de mascota requerido'
                }
              })}
              id='name-pets'
              placeholder='EX. Cocoa'
            />
            {errors?.name && <p className='errors'>{errors.name.message}</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='pet_type'>Tipo de mascota</label>
            <select
              {...register('pet_type', {
                onChange: (e) => handlerChangePetType(e.target.value)
              })}
              id='pet_type'
            >
              {petType?.map((pet) => (
                <option key={pet.id} value={pet.name}>
                  {pet.name}
                </option>
              ))}
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
          </div>

          <div className='form-group'>
            <label htmlFor='breed'>Raza:</label>
            <select
              {...register('breed_id', {
                required: {
                  value: true,
                  message: 'La raza es requerida'
                }
              })}
              id='breed'
            >
              <option value=''>Selecciona una raza</option>
              {petBreed?.map((breed) => (
                <option key={breed.id} value={breed.id}>
                  {breed.breed_name}
                </option>
              ))}
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
            {errors.breed_id && <p className='errors'>{errors.breed_id.message}</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='gender'>Genero:</label>
            <select {...register('gender')} id='gender'>
              <option value='male'>Macho</option>
              <option value='female'>Hembra</option>
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
          </div>
          <input type='submit' id='submit-button' value='enviar' hidden />
        </form>
      </section>
      <hr />
      <section className='container-instagram'>
        <p className='info-instagran-edit-pet'>
          <span>Instagran</span>
          {pet.instagram_username ? <span style={{ color: 'red' }}>{pet.instagram_username}</span> : <span style={{ color: 'red' }}>@instagram-de-prueba</span>}
        </p>
        <button className='delete-instagran-edit-pet' type='button' onClick={handlerUnlinkInstagram} style={{ cursor: 'pointer' }}>
          {iconsImages?.DELETE ? <img src={iconsImages.DELETE.images.sm} alt={iconsImages.DELETE.key} /> : <img src={ic_delete} alt='delete-instagram' />}
        </button>
      </section>
      <hr />
      <section className='container-button-edit'>
        <button className='btn btn-red' onClick={() => setModalAddPet(true)}>
          Eliminar Mascota
        </button>
        <button
          className='btn btn-edit'
          onClick={() => {
            document.getElementById('submit-button')?.click()
          }}
        >
          Guardar información
        </button>
      </section>
      {modalAddPet && <ModalDetailsPets idPet={pet.id as string} />}
    </div>
  )
}

export default AccountEditPet
