import { useEffect, useRef, useState } from 'react'

import { PlaceComment } from '../types'
import { PlaceDetailServiceProps } from '../ui/components/Services/PlaceDetailService'
import { getMyPlaces } from '../services'
import { useGetToken } from './useGetToken'

const useListPlaces = ({ placeData }: PlaceDetailServiceProps, placeComments: PlaceComment[]) => {
  const [listPlaces, setListPlaces] = useState([])
  const listImagesPlaces: any = useRef([])
  const [arrayImages, setArrayImages] = useState<string[]>([])
  const { data: token } = useGetToken()

  useEffect(() => {
    if (token) {
      getMyPlaces(token).then((res) => {
        setListPlaces(res.results)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListPlaces, arrayImages.length, token])
  useEffect(() => {
    listImagesPlaces.current = listPlaces.filter((place: any) => place.name === placeData.name)
  }, [arrayImages, listPlaces, placeComments.length, placeData.name])

  useEffect(() => {
    listImagesPlaces.current.forEach((placeImage: any) => {
      placeImage.place_images.forEach((images: any) => {
        arrayImages.length > 0 ? arrayImages.indexOf(images.image) === -1 && setArrayImages([...arrayImages, images.image]) : setArrayImages([...arrayImages, images.image])
      })
    })
  }, [arrayImages, listPlaces])
  return {
    arrayImages,
    setListPlaces
  }
}

export default useListPlaces
