import './ReportsStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { PlaceComment } from '../../../types'
import ProfileDefaultImg from '../../../assets/images/mappet/img-mappet.png'
import { RatingStars } from '..'

interface CommentProps {
  comment: PlaceComment
}

const Comment = ({ comment }: CommentProps) => {
  const { setShowOtherUser, setShowPlaceMap, showOtherUser, iconsImages } = useContext(MappetContext)
  function viewOtherUser(id: string) {
    setShowOtherUser({ ...showOtherUser, isVisible: true, idPlace: id })
    setShowPlaceMap(false)
  }
  return (
    <div className='comment-container'>
      <div className='comment-left' onClick={() => viewOtherUser(comment.profile.id as string)}>
        <img
          className='user-profile-img'
          src={comment.profile.profile_picture ? (comment.profile.profile_picture as string) : iconsImages?.DEFAULT_USER_COMMENT ? iconsImages.DEFAULT_USER_COMMENT.images.sm : ProfileDefaultImg}
          alt=''
        />
      </div>
      <div className='comment-right'>
        <h4>{comment.profile.name}</h4>
        <RatingStars rating={comment.rating} />
        <p>{comment.comment}</p>
      </div>
    </div>
  )
}

export default Comment
