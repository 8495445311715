export const getAddress = async (address: string, coords: number[]) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/places/google_places/?query=${address}&latlng=${coords[0]},${coords[1]}`, {
    //&latlng=10.4013,-75.5267
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    }
  })
  const resJson = await response.json()
  return resJson
}
