import './SuccessfulRegisterStyles.css'
import '../../../assets/css/styles.css'

import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { imgElement3, imgMappet } from '../../../assets/images/mappet'

import { MappetContext } from '../../../utils'

const SuccessfulRegister = () => {
  const redirectUrl = useNavigate()
  const { isRegistering, iconsImages } = useContext(MappetContext)

  useEffect(() => {
    if (!isRegistering) {
      redirectUrl('/main/services')
    }
  })

  return (
    <div className='successful-register-view'>
      {iconsImages?.BACKGROUND_IMAGE_3 ? <img className='corner-decoration' src={iconsImages.BACKGROUND_IMAGE_3.images.md} alt='' /> : <img className='corner-decoration' src={imgElement3} alt='' />}
      <div className='message'>
        {iconsImages?.DEFAULT_ICON ? <img className='logo' src={iconsImages.DEFAULT_ICON.images.sm} alt='' /> : <img className='logo' src={imgMappet} alt='logo' />}

        <h3>Registro finalizado</h3>
        <p>Califícalo y compártelo en Mappet para hacer crecer la comunidad</p>
        <Link to='/main/reports' className='button exit-button'>
          Salir
        </Link>
      </div>
    </div>
  )
}

export default SuccessfulRegister
