import React, { useContext, useEffect } from 'react'
import { deleteReport, getMyReports } from '../../../services'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import { PetReport } from '../../../types'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  setShowDeleteReportModal: (c: PetReport | null) => void
  reportId: number
}

const ModalDeleteReport = ({ setShowDeleteReportModal, reportId }: Props) => {
  const { setShowReportDetails, showDeleteReportModal, setPetReports } = useContext(MappetContext)
  const { data: token } = useGetToken()

  useEffect(
    () => setShowReportDetails(null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handlerDelete = () => {
    deleteReport(showDeleteReportModal?.id!, token)
      .then((res) => {
        getMyReports(token)
          .then((res) => {
            console.log(res)
            setPetReports(res.results)
          })
          .catch((err) => console.log(err))

        setShowDeleteReportModal(null)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Modal>
      <div className='container-details-modal'>
        <span className='close' onClick={() => setShowDeleteReportModal(null)}>
          X
        </span>
        <div className='details-pets'>¿Estás seguro de que desea eliminar este reporte?</div>
        <div className='details-buttons'>
          <button className='btn btn-edit' onClick={() => setShowDeleteReportModal(null)}>
            Cancelar
          </button>
          <button className='btn button-close-terms' onClick={() => handlerDelete()}>
            Confirmar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteReport
