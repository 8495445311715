import React, { useContext, useState } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import { PetType } from '../../../types'
import ic_back from '../../../assets/images/mappet/ic_back.png'

type Props = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  setOtherTypes: React.Dispatch<React.SetStateAction<string>>
}
const ModalTypesPets = ({ setModal, setOtherTypes }: Props) => {
  const { petType } = useContext(MappetContext)
  const [resultSearch, setresultSearch] = useState<PetType[] | undefined>(petType)
  function sortType(types: PetType[]) {
    return types.sort((a: PetType, b: PetType) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
  }

  function handleSearchType(e: React.ChangeEvent<HTMLInputElement>) {
    const nameSearchType = petType?.filter((pet) => pet.name.toLowerCase().includes(e.target.value.toLowerCase()))
    if (nameSearchType) {
      setresultSearch(sortType(nameSearchType))
    } else {
      setresultSearch(sortType(petType as PetType[]))
    }
    console.log(resultSearch)
  }
  return (
    <Modal>
      <div className='modal-type-pets-main'>
        <header className='modal-header-pets'>
          <img src={ic_back} alt='regresar' onClick={() => setModal(false)} />
          <input type='text' placeholder='Perro, gato, etc...' onChange={handleSearchType} />
        </header>
        <section className='modal-result-pets'>
          {resultSearch?.length === 0 ? (
            <div className='type-no-found'>
              <h3>Tipo no encontrado</h3>
              <p>Revisa que la palabra esté bien escrita o busca términos más generales.</p>
            </div>
          ) : (
            resultSearch?.map((pet) => (
              <div key={pet.id}>
                <p
                  onClick={() => {
                    setOtherTypes(pet.name)
                    setModal(false)
                  }}
                >
                  {pet.name}
                </p>
              </div>
            ))
          )}
        </section>
      </div>
    </Modal>
  )
}

export default ModalTypesPets
