import { Pet } from '../types'

export const petListEfects = (petList: Pet[] | undefined) => {
  let elem = document.getElementsByClassName('arrows-responsive')
  let div_list_pets = document.getElementsByClassName('list-img-pets').item(0) as HTMLDivElement
  let span_length = document.getElementsByClassName('length-pets').item(0) as HTMLSpanElement
  let list_pets_responsive = document.getElementsByClassName('display-list-pets').item(0)
  let pet_info = document.getElementsByClassName('pet-info-responsive')

  elem.item(0)?.children.item(0)?.classList.toggle('active-arrows')
  elem.item(0)?.children.item(1)?.classList.toggle('active-arrows')
  div_list_pets.toggleAttribute('hidden')
  span_length.classList.toggle('hidden-length')
  list_pets_responsive?.classList.toggle('active-list-responsive')
  petList?.forEach((_, index) => {
    pet_info?.item(index)?.classList.toggle('active-pet-info-responsive')
  })
}
