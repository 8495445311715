import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { PetReport } from '../../../types'
import { imgMappet } from '../../../assets/images/mappet'

interface PetReportCardProps {
  petReport: PetReport
  setShowDeleteReportModal: (c: PetReport | null) => void
}

const PetReportCard = ({ petReport, setShowDeleteReportModal }: PetReportCardProps) => {
  const { showReportDetails } = useContext(MappetContext)

  return (
    <div className={`report-card ${petReport.uuid === showReportDetails?.uuid ? 'selected-card' : ''}`}>
      <div className='left-column'>
        <img src={petReport.report_image[0] ? petReport.report_image[0].image : imgMappet} alt='card-cover' />
      </div>
      <div className='right-column'>
        <h4>{`Mascota ${petReport.report_type === 'missing' ? 'perdida' : 'encontrada'}`}</h4>
        <p>Ubicación: {petReport.address}</p>
        <p>{petReport.profile?.phone}</p>
      </div>
      <button
        onClick={() => {
          setShowDeleteReportModal(petReport)
          // setShowReportDetails(null)
        }}
        className='delete-report-button'
      >
        <i className='fa fa-solid fa-xmark'></i>
      </button>
    </div>
  )
}

export default PetReportCard
