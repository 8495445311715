const deleteImages = (idImage: number, token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const deleteResImages = fetch(`${URL}/api/v1/reports/delete/picture/${idImage}/`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })

  return deleteResImages
}

export default deleteImages
