import React, { useContext, useEffect, useState } from 'react'
import { helpAnimal, placesPetFriendly, shopping } from '../../../mocks'

import { DropDownFilters } from './Filters'
import { MappetContext } from '../../../utils'
import { PetDefaultImg } from '../../../assets/images/default_images'
import { getListPlaces } from '../../../services/getListPlacesCloser'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  coord: React.MutableRefObject<number[]>
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>
  setCloseCategory: React.Dispatch<React.SetStateAction<boolean>>
  setFilterSelect: React.Dispatch<React.SetStateAction<DropDownFilters | undefined>>
}

type OptionsPlaces = {
  id: string
  img: string
  name: string
  value: string
}

const DropDownCategories = ({ coord, setCloseCategory, setCloseFilter, setFilterSelect }: Props) => {
  const [options, setOptions] = useState({
    animalhelp: [] as OptionsPlaces[],
    placesPetFriendly: [] as OptionsPlaces[],
    shoppingPet: [] as OptionsPlaces[]
  })
  const { iconsImages } = useContext(MappetContext)
  const { setPlaceCloser, userLocation } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const arraycoord: number[] = [userLocation!.lat as number, userLocation!.lng as number]
  const handleCategory = (e: React.MouseEvent<HTMLInputElement>, imageFilter: string) => {
    let nameFilter = ''
    let valueName = ''
    if (e.currentTarget.checked) {
      nameFilter = e.currentTarget.name
      valueName = e.currentTarget.value
      getListPlaces(arraycoord, e.currentTarget.value, '', token).then((res) => {
        setPlaceCloser(res.results)
        getInfoCategory(nameFilter, res.results.length, imageFilter, valueName)
      })
      setCloseCategory(false)
      setCloseFilter(false)
      e.currentTarget.checked = false
    }
  }

  function getInfoCategory(nameFilter: string, lengthFilter: number, imageFilter: string, valueName: string) {
    setFilterSelect({
      lengthFilters: lengthFilter,
      image: imageFilter,
      nameFilter: nameFilter,
      valueName
    })
  }
  const handlerSelectAll = () => {
    getListPlaces(arraycoord, '', '', token).then((res) => {
      setPlaceCloser(res.results)
      getInfoCategory('Mostrando Todas', res.results.length, iconsImages?.DEFAULT_ICON?.images.sm as string, '')
    })
    setCloseCategory(false)
    setCloseFilter(false)
    setFilterSelect(undefined)
  }

  useEffect(() => {
    if (
      iconsImages?.EMERGENCIES ||
      iconsImages?.VETERINARY_CARE ||
      iconsImages?.SALOON ||
      iconsImages?.SPA ||
      iconsImages?.COFFEE ||
      iconsImages?.RESTAURANT ||
      iconsImages?.BAR ||
      iconsImages?.SHOPPING_MALL ||
      iconsImages?.PARK ||
      iconsImages?.EVENTS ||
      iconsImages?.FOOD ||
      iconsImages?.MEDICINES ||
      iconsImages?.TOYS ||
      iconsImages?.CLOTHES
    ) {
      const animalHelp = helpAnimal.help_animal.map((item) => {
        if (item.name === 'Urgencias') {
          item.img = iconsImages.EMERGENCIES?.images.sm as string
        } else if (item.name === 'Veterinaria') {
          item.img = iconsImages.VETERINARY_CARE?.images.sm as string
        } else if (item.name === 'Peluquerias') {
          item.img = iconsImages.SALOON?.images.sm as string
        } else if (item.name === 'Spa') {
          item.img = iconsImages.SPA?.images.sm as string
        }
        return item
      })

      const placesPet = placesPetFriendly.places_petFriendly.map((item) => {
        if (item.name === 'Cafe') {
          item.img = iconsImages.COFFEE?.images.sm as string
        } else if (item.name === 'Restaurantes') {
          item.img = iconsImages.RESTAURANT?.images.sm as string
        } else if (item.name === 'Bar') {
          item.img = iconsImages.BAR?.images.sm as string
        } else if (item.name === 'C. Comercial') {
          item.img = iconsImages.SHOPPING_MALL?.images.sm as string
        } else if (item.name === 'Parques') {
          item.img = iconsImages.PARK?.images.sm as string
        } else if (item.name === 'Eventos') {
          item.img = iconsImages.EVENTS?.images.sm as string
        }
        return item
      })

      const shoppint_pet = shopping.shopping.map((item) => {
        if (item.name === 'Comida') {
          item.img = iconsImages.FOOD!.images.sm as string
        } else if (item.name === 'Medicamentos') {
          item.img = iconsImages.MEDICINES!.images.sm as string
        } else if (item.name === 'Juguetes') {
          item.img = iconsImages.TOYS!.images.sm as string
        } else if (item.name === 'Ropa') {
          item.img = iconsImages.CLOTHES!.images.sm as string
        }
        return item
      })
      setOptions({ ...options, animalhelp: animalHelp, placesPetFriendly: placesPet, shoppingPet: shoppint_pet })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconsImages?.BAR, iconsImages?.COFFEE, iconsImages?.EVENTS, iconsImages?.PARK, iconsImages?.RESTAURANT, iconsImages?.SALOON, iconsImages?.SHOPPING_MALL, iconsImages?.SPA])

  return (
    <div className='main-categories'>
      <section className='title-category'>
        {iconsImages?.DEFAULT_ICON ? <img src={iconsImages.DEFAULT_ICON.images.sm} alt='categorias' /> : <img src={PetDefaultImg} alt='categorias' />}
        <h2>Catgorias</h2>
      </section>
      <hr />
      <section className='group-help-animal'>
        <p>CUIDADO ANIMAL</p>
        <div className='container-places'>
          {options.animalhelp.map((help) => (
            <div className='places-iten' key={help.id}>
              <label htmlFor={help.name} className='places-iten'>
                <img src={help.img} alt={help.name} />
                <input type='checkbox' name={help.name} id={help.name} value={help.value} hidden onClick={(e) => handleCategory(e, help.img)} />
                {help.name}
              </label>
            </div>
          ))}
        </div>
      </section>
      <hr />
      <section className='group-places-category'>
        <p>LUGARES PET FRIENDLY</p>
        <div className='container-places'>
          {options.placesPetFriendly.map((place) => (
            <div className='places-iten' key={place.id}>
              <label htmlFor={place.name} className='places-iten'>
                <img src={place.img} alt={place.name} />
                <input type='checkbox' name={place.name} id={place.name} value={place.value} onClick={(e) => handleCategory(e, place.img)} hidden />
                {place.name}
              </label>
            </div>
          ))}
        </div>
      </section>
      <hr />
      <section className='group-shop'>
        <p>COMPRAS</p>
        <div className='container-places'>
          {options.shoppingPet.map((shop) => (
            <div className='places-iten' key={shop.id}>
              <label htmlFor={shop.name} className='places-iten'>
                <img src={shop.img} alt={shop.name} />
                <input type='checkbox' name={shop.name} id={shop.name} value={shop.value} onClick={(e) => handleCategory(e, shop.img)} hidden />
                {shop.name}
              </label>
            </div>
          ))}
        </div>
      </section>
      <hr />
      <section className='group-button'>
        <button className='btn btn-select' onClick={handlerSelectAll}>
          Seleccionar todas
        </button>
      </section>
    </div>
  )
}

export default DropDownCategories
