export const getListDocPets = async (id: string, token: string) => {
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/pets/list_pets_documents/?id_pet=${id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const resListDocs = await response.json()
  resListDocs.results.forEach((list: any) => {
    if (list) list.image = list.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
  })
  return resListDocs
}
