import '../../../assets/css/styles.css'
import './AccountComponentsStyles.css'

import { Dispatch, SetStateAction } from 'react'

import { useNavigate } from 'react-router-dom'

type AccountActions = 'pets' | 'notifications' | 'edit' | 'about' | 'policy' | 'logout'
interface AccountNotificationsProps {
  setSelectedAction: Dispatch<SetStateAction<AccountActions>>
}

const AccountNotifications = ({ setSelectedAction }: AccountNotificationsProps) => {
  const nav = useNavigate()

  return (
    <div className='account-notifications-container'>
      <i onClick={() => setSelectedAction('pets')} className='back-button fa-solid fa-arrow-left'></i>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus ipsam perferendis rem dolore.</p>
      <div className='notification-card'>
        <div className='notification-card-left'>
          <i className='fa-solid fa-newspaper'></i>
        </div>
        <div className='notification-card-right'>¿Greiz come bien?</div>
        <div className='right-arrow' onClick={() => nav('/main/survey')}>
          <i className='fa-solid fa-chevron-right'></i>
        </div>
      </div>
    </div>
  )
}

export default AccountNotifications
