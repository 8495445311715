import { ListImages, Pet } from '../types'

import { MappetContext } from '../utils'
import { UserData } from './../types.d'
import { createPet } from '../services/createPet'
import { getBreed } from '../utils/getBreed'
import { getListPets } from '../services/getListPets'
import { saveImagesPetRegister } from '../services/saveImagesPetsRegister'
import toast from 'react-hot-toast'
import { updatePet } from '../services/updatePets'
import { useContext } from 'react'
import { useGetToken } from './useGetToken'

export const useFormPet = () => {
  const { setPetList, petType, setPetBreed, user } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const sentData = (dataForm: Pet, image: ListImages, petData: Pet | undefined, setPetData: (c: Pet | undefined) => void) => {
    const data = new FormData()
    data.append('profile_id', user?.id as unknown as string)
    data.delete('pet')
    data.delete('instagram')
    Object.entries(dataForm).forEach(([key, value]) => {
      data.append(`${key}`, `${value}`)
    })
    if (!petData && token) {
      createPet(data)
        .then((res) => {
          if (image.images.length !== 0) {
            saveImagesPetRegister(res.id, image.images, token).then((response) => {
              getBreed('Perro', petType, setPetBreed, token)
              getListPets(user as UserData, token)
                .then((pets) => {
                  setPetList(pets.results)
                })
                .catch((error) => console.log(error))
            })
          }
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>Mascota guardada con exito</p>
              <p>Los datos se guardaron exitosamente</p>
            </div>,
            {
              position: 'top-right',
              style: {
                color: '#ffffff',
                backgroundColor: 'red'
              }
            }
          )

          getBreed('Perro', petType, setPetBreed, token)
          getListPets(user as UserData, token)
            .then((pets) => {
              setPetList(pets.results)
            })
            .catch((error) => console.log(error))
        })
        .catch((err) => console.log('error de conection', err))
    } else if (token) {
      updatePet(data, petData?.id as string, token).then((res: any) => {
        const isChangeImages = Array.from(image.images).some((image) => !image.name.startsWith('http:/') && !image.name.startsWith('https:/'))
        if (isChangeImages) {
          saveImagesPetRegister(res.id, image.images, token).then((response) => {
            setTimeout(() => {
              setPetData(undefined)
            }, 5000)
            getBreed('Perro', petType, setPetBreed, token)
            getListPets(user as UserData, token)
              .then((pets) => {
                setPetList(pets.results)
              })
              .catch((error) => console.log(error))
          })
        }
        toast(
          <div>
            <p style={{ fontWeight: 'bold' }}>Mascota editada con exito</p>
            <p>Los datos se editaron exitosamente</p>
          </div>,
          {
            position: 'top-right',
            style: {
              color: '#ffffff',
              backgroundColor: 'red'
            }
          }
        )
        setTimeout(() => {
          setPetData(undefined)
        }, 4000)
        getBreed('Perro', petType, setPetBreed, token)
        getListPets(user as UserData, token)
          .then((pets) => {
            setPetList(pets.results)
          })
          .catch((error) => console.log(error))
      })
    }
  }
  return {
    sentData
  }
}
