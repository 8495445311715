export const deleteDocPet = async (id: number, token: string) => {
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/pets/pets_documents/delete/${id}/`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  return response
}
