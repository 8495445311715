export const deletePets = async (id: string, token: string) => {
  const API_TOKEN = token
  const resDeletePet = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/pets/delete/${id}/`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  return resDeletePet
}
