import './FormStyles.css'
import '../TiposMascotas/TipoMascotaStyles.css'

import { ListImages, Pet, UserData } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import ModalDetailsPets from '../Modal/ModalDetailsPets'
import ModalImages from '../Modal/ModalImages'
import ModalTypesPets from '../Modal/ModalTypesPets'
import PetList from '../PetList/PetList'
import PetListResponsive from '../PetList/PetListResponsive'
import TipoMascotas from '../TiposMascotas/TipoMascotas'
import { Toaster } from 'react-hot-toast'
import { getBreed } from '../../../utils/getBreed'
import { getErrorsImages } from '../../../utils/getErrorsImages'
import { getListPets } from '../../../services/getListPets'
import { getPetType } from '../../../services/getPetType'
import imgDefaultPet from '../../../assets/images/profile/huella.png'
import logo from '../../../assets/images/img-logo.png'
import { previewsImg } from '../../../utils/uploadProfile'
import { useForm } from 'react-hook-form'
import { useFormPet } from '../../../hooks/useFormPet'
import { useGetToken } from 'hooks/useGetToken'
import { useNavigate } from 'react-router-dom'

const FormPets = () => {
  const redirectUrl = useNavigate()
  const [otherTypes, setotherTypes] = useState('')
  const [idPet, setIdPet] = useState<string | undefined>()
  const [images, setImages] = useState<ListImages>({ images: [], imagesDel: [] })
  const [error, setError] = useState<string>('')
  const [imageModal, setImageModal] = useState<boolean>(false)
  const { setPetType, petBreed, setPetList, modalAddPet, petList, isRegistering, petType, setPetBreed, user, petData, setPetData, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Pet>()
  const [modalTypePets, setModalTypePets] = useState(false)
  const { sentData } = useFormPet()

  const handlerChangeCantImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!error && e.target.files?.item(0)) {
      setImages({
        ...images,
        images: [...images.images, e.target.files?.item(0)!] as File[]
      })
    }
  }

  const handleOmited = () => {
    if (isRegistering) {
      redirectUrl('/main/configure-searches')
    }

    if (!isRegistering) {
      redirectUrl('/main/services')
    }
  }
  const onSubmit = handleSubmit((values) => {
    if (!error) {
      sentData(values, images, petData as Pet | undefined, setPetData as (c: Pet | undefined) => void)
      document.getElementById('img-profile')?.setAttribute('src', imgDefaultPet)
      reset()
      getBreed('Perro', petType, setPetBreed, token)
      setImages({ images: [], imagesDel: [] })
      setotherTypes('')
    }
  })

  const handleCanceled = () => {
    document.getElementById('img-profile')?.setAttribute('src', iconsImages?.PROFILE_EMPTY?.images.sm as string)
    reset()
    getBreed('Perro', petType, setPetBreed, token)
    setPetData(undefined)
    setImages({ images: [], imagesDel: [] })
    document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: none')
    setotherTypes('')
  }
  useEffect(() => {
    if (!petData) {
      handleCanceled()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petData])
  useEffect(() => {
    const petDefaults = petType?.filter((pet) => pet.icon !== null).map((pet) => pet.name)
    if (petDefaults?.includes(otherTypes)) {
      document.getElementsByClassName('row-6').item(0)?.setAttribute('style', 'display: none')
      let element = document.getElementById(otherTypes) as HTMLInputElement
      if (element) element.checked = true
    }
  }, [otherTypes, petType])
  useEffect(() => {
    if (token) {
      getBreed(petData ? (petData.breed?.pet_type.name as string) : 'Perro', petType, setPetBreed, token)
    }
    if (petData) {
      setTimeout(() => {
        setValue('breed_id', petData?.breed?.id as string)
      }, 1200)
    }
  }, [petData, petType, setPetBreed, setValue, token])

  useEffect(() => {
    if (otherTypes && token) {
      getBreed(otherTypes, petType, setPetBreed, token)
    }
  }, [otherTypes, petType, setPetBreed, token])
  useEffect(() => {
    //obteniendo los tipos de mascotas
    if (token) {
      getPetType(token)
        .then((res) => {
          setPetType(res.results)
        })
        .catch((error) => console.log(error))
      //traer el listado de mascotas
      getListPets(user as UserData, token)
        .then((pets) => {
          if (pets.results) setPetList(pets.results)
        })
        .catch((error) => console.log(error))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (petData) {
      const dataTransfer = new DataTransfer()
      const imgPet = document.querySelector('#upload_img') as HTMLInputElement
      const files = petData.images.map((img, index) => {
        const blob = img.image as unknown as Blob
        const file = new File([blob], `${img.image}`, { type: blob.type })
        dataTransfer.items.add(file)
        return file
      })
      imgPet.files = dataTransfer.files
      previewsImg(files[0])
      setValue('name', petData.name)
      setValue('gender', petData.gender)
      setImages({
        ...images,
        images: [...files],
        imagesDel: petData.images.map((image) => image)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petData, setValue])
  return (
    <div className='container-form'>
      {iconsImages?.LOGO_MAPPET ? <img className='img-logo' src={iconsImages.LOGO_MAPPET.images.md} alt='img-logo-mappet' /> : <img className='img-logo' src={logo} alt='img-logo-mappet' />}
      <div className='container-form-pets'>
        {petList?.length !== 0 && <PetListResponsive setIdPet={setIdPet} />}
        <section>
          {!petData ? (
            <>
              <h1>Registra tu mascota</h1>
              <p>Selecciona el tipo de mascota y completa la informacion para continuar.</p>
            </>
          ) : (
            <h1 style={{ padding: '5px 0px' }}>Editar registro</h1>
          )}
          <form id='petReg' className='form' encType='multipart/form-data' onSubmit={onSubmit}>
            <TipoMascotas petData={petData} otherTypes={otherTypes} setOtherTypes={setotherTypes} />
            <div className='sub-form-pet'>
              <div className='row row-6'>
                <div className='form-group'>
                  <label htmlFor='tipo'>Tipo:</label>
                  <input defaultValue={otherTypes} id='tipo' placeholder='Click para buscar..' onClick={() => setModalTypePets(true)} autoComplete='off' />
                </div>
              </div>
              <div className='row'>
                <div className='form-group'>
                  <label htmlFor='name-pets'>Nombre de tu mascota:</label>
                  <input
                    type='text'
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Nombre de mascota requerido'
                      },
                      pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
                    })}
                    id='name-pets'
                    placeholder='EX. Cocoa'
                  />
                  {errors?.name?.type === 'required' && <p className='errors'>{errors.name.message}</p>}
                  {errors?.name?.type === 'pattern' && <p className='errors'>Nombre de mascota no permitido.</p>}
                </div>
                <div className='form-group'>
                  <label htmlFor='instagram'>Instagram(opcional):</label>
                  <input type='text' name='instagram' id='instagram' placeholder='Ingresa tu cuenta de instagram' />
                  {/* {errors?.name && <p className='errors'>{errors.name}</p>} */}
                </div>
              </div>
              <div className='row'>
                <div className='form-group'>
                  <label htmlFor='breed'>Raza:</label>
                  <select
                    {...register('breed_id', {
                      required: {
                        value: true,
                        message: 'La raza es requeridad'
                      }
                    })}
                    id='breed'
                  >
                    <option value=''>Seleccione una raza</option>
                    {petBreed?.map((breed) => (
                      <option key={breed.id} value={breed.id}>
                        {breed.breed_name}
                      </option>
                    ))}
                  </select>
                  <i className='fa-solid fa-caret-down arrow-down-pets'></i>
                  {errors?.breed_id && <p className='errors'>{errors.breed_id.message}</p>}
                </div>
                <div className='form-group'>
                  <label htmlFor='gender'>Genero:</label>
                  <select {...register('gender')} id='gender'>
                    <option value='male'>Macho</option>
                    <option value='female'>Hembra</option>
                  </select>
                  <i className='fa-solid fa-caret-down arrow-down-pets'></i>
                </div>
              </div>
            </div>
            <div className='img_container'>
              <div className='profile_img'>{images.images.length === 0 && <img src={iconsImages?.PROFILE_EMPTY?.images.sm} alt='' id='img-profile' />}</div>
              <label htmlFor='upload_img' className='btn btn-camera'>
                Cargar fotografia {images.images.length > 0 ? images.images.length : 0}
                <input
                  type='file'
                  id='upload_img'
                  {...register('images', {
                    onChange: (e) => {
                      handlerChangeCantImages(e)
                      previewsImg(e.target.files[0])
                      e.target.files[0] && setError(getErrorsImages(e.target.files[0].name))
                      e.target.type = 'text'
                      e.target.type = 'file'
                    }
                  })}
                  hidden
                  disabled={images.images.length >= 5 ? true : false}
                  accept='image/jpeg, image/jpg, image/png, image/gif, image/bmp'
                />
              </label>
              {images.images.length > 0 && (
                <p onClick={() => setImageModal(true)} className='see-images'>
                  {' '}
                  Ver fotos
                </p>
              )}
            </div>
            {error && <p className='errors'>{error}</p>}
            <hr />
            {!petData ? (
              <div className='button-group-form'>
                <input type='button' value='omitir' className='btn btn-omitir' onClick={() => handleOmited()} />
                <input type='submit' value='Agregar mascota' className='btn btn-submit' />
              </div>
            ) : (
              <div className='button-group-form'>
                <input type='button' value='Cancelar' className='btn btn-omitir' onClick={() => handleCanceled()} />
                <input type='submit' value='Finalizar edición' className='btn btn-submit' />
              </div>
            )}
          </form>
        </section>
        <PetList setIdPet={setIdPet} setOtherTypes={setotherTypes} />
      </div>
      <Toaster />
      {modalTypePets && <ModalTypesPets setOtherTypes={setotherTypes} setModal={setModalTypePets} />}
      {modalAddPet && <ModalDetailsPets idPet={idPet as string} />}
      {imageModal && <ModalImages setCantImages={setImages} cantImages={images} setImageModal={setImageModal} setError={setError} />}
    </div>
  )
}

export default FormPets
