import { PetReport } from '../types'

const getMyReports = async (token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const reportsListPromise = await fetch(`${URL}/api/v1/reports/list/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })

  const reportsList = await reportsListPromise.json()

  reportsList.results.forEach((report: PetReport) => {
    report.report_image.forEach((element: any) => {
      element.image = element.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
    })

    report.register_type = 'report'

    report.profile.profile_picture = report.profile.profile_picture?.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT!, URL!)
  })

  return reportsList
}

export default getMyReports
