import React, { useCallback, useContext, useState } from 'react'

import { MappetContext } from '../utils'
import { getAddress } from '../services/getAddress'

const useGetDirections = (debounceGetDirection: React.MutableRefObject<NodeJS.Timeout | undefined>) => {
  const [directions, setDirections] = useState([])
  const { userLocation } = useContext(MappetContext)
  const getDirections = useCallback(
    async (address: string, coords: number[]) => {
      getAddress(address, coords)
        .then((res) => {
          setDirections(res)
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
    [setDirections]
  )

  const handleGetDirection = (name: string) => {
    if (debounceGetDirection.current) clearTimeout(debounceGetDirection.current)
    debounceGetDirection.current = setTimeout(() => {
      const coords: number[] = [userLocation?.lat as number, userLocation?.lng as number]
      getDirections(name, coords)
    }, 350)
  }
  return { handleGetDirection, directions, setDirections }
}

export default useGetDirections
