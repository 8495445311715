export const userExists = async (id_user: string, phone: string = '') => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/validate/?user_id=${id_user}&phone=${phone}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    }
  })
  const resUser = await response.json()
  resUser.profile_picture = resUser.profile_picture?.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, process.env.REACT_APP_BACKEND_URL)
  return resUser
}
