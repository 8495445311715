function createStartRate(rating: number, star: string, star_active: string): void {
  let cont = rating

  for (let i = 0; i < 5; i++) {
    let element = document.getElementById(`start-${i + 1}`) as HTMLLabelElement
    if (i < cont) {
      /* #f7ea44 */
      element.innerHTML = `<img src=${star_active} alt='star red'/>`
    } else {
      element.innerHTML = `<img src=${star} alt='star red'/>`
    }
  }
}

export default createStartRate
