import './assets/css/styles.css'
import './index.css'

import { Account, ConfigureSearch, Home, RegisterView, Services, SuccessfulRegister, Survey } from './ui/views'
import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'

import FormUser from './ui/components/Form/FormUser'
import LoggedView from './ui/views/LoggedView/LoggedView'
import { MappetContext } from './utils'
import Page404 from './ui/views/Page404/Page404'
import PhoneViewWhatsapp from './ui/views/PhoneViewWhatsapp/PhoneViewWhatsapp'
import PrivateRoutes from './ui/components/PrivateRoutes'
import ReportsViews from './ui/views/Reports/ReportsViews'

function App() {
  const { googleUser, cellPhone } = useContext(MappetContext)

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/phone-view-whatsapp' element={<PhoneViewWhatsapp />} />
        <Route path='/main/register/users' element={googleUser?.sub || cellPhone.cell !== 0 ? <FormUser /> : <Home />} />
        <Route path='/main' element={<PrivateRoutes />}>
          <Route path='/main/register/pets' element={<RegisterView />} />
          <Route path='/main/configure-searches' element={<ConfigureSearch />} />
          <Route path='/main/register/success' element={<SuccessfulRegister />} />
          {/* <Route path='/main/services' element={<LoggedView component={<Services />} />} /> */}
          <Route path='/main/reports' element={<LoggedView component={<ReportsViews />} />} />
          {/* <Route path='/main/reports/:uuid' element={<ReportDetail />} /> */}
          <Route path='/main/account' element={<LoggedView component={<Account />} />} />
          <Route path='/main/survey' element={<LoggedView component={<Survey />} />} />
        </Route>
        <Route path='/main/services' element={<LoggedView component={<Services />} />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </div>
  )
}

export default App
