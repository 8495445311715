export const updateDocPet = async (name: string, id: number, token: string) => {
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/pets/update_pets_documents/${id}/`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: JSON.stringify({ name })
  })
  const resJson = await response.json()
  return resJson
}
