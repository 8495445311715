import { useCallback, useContext, useEffect } from 'react'

import { MappetContext } from '../utils'
import { getListPlaces } from '../services/getListPlacesCloser'
import { saveUserInteractions } from '../services/saveUserInteractions'
import { useGetToken } from './useGetToken'

const usePlacesCloser = (setDirections: React.Dispatch<React.SetStateAction<never[]>>, coord: number[], inputE: HTMLInputElement) => {
  const { setPlaceCloser, user, typeView, setUserInteraction, userInteraction } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const getPlacesCloser = useCallback(
    async (data: any, coord: number[]) => {
      const resPlacesCloser = await getListPlaces(coord, '', data.name, token)
      setPlaceCloser(resPlacesCloser.results)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPlaceCloser, token]
  )
  const handleGetresults = (data: any) => {
    if (data !== null) {
      getPlacesCloser(data, coord)
      setDirections([])
      setUserInteraction({
        ...userInteraction,
        profile: user,
        profile_id: user?.id as number,
        latitude: data.location.lat,
        longitude: data.location.lng,
        place: data.place_id,
        action: data.name,
        action_category: 'Buscador',
        action_config: { typeView }
      })
      //inputE.value = data.name
    }
  }
  useEffect(() => {
    if (Object.entries(userInteraction).length !== 0 && token) {
      saveUserInteractions(userInteraction, token).then((res) => {
        console.log('ok')
        setUserInteraction({})
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserInteraction, userInteraction, token])
  return { handleGetresults }
}

export default usePlacesCloser
