import './FormStyles.css'

import { User, UserData } from '../../../types'
import { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import ModalTermsConditiones from '../Modal/ModalTermsConditiones'
import React from 'react'
import logo from '../../../assets/images/img-logo.png'
import { useForm } from 'react-hook-form'
import { useFormUser } from '../../../hooks/useForm'
import { useNavigate } from 'react-router-dom'

const initialForm: User = {
  id_user: '',
  profile_picture: '',
  name: '',
  email: '',
  phone: '',
  is_terms_and_condition_accepted: '',
  instagram_username: ''
}
const FormUser = () => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<User>()
  const { user, modalTermsConditions, setModalTermsConditions, setUser, logoutUser, googleUser, cellPhone, setGoogleUser } = useContext(MappetContext)
  const { sentData } = useFormUser(initialForm)
  const redirectUrl = useNavigate()
  const [errorPhone, setErrorPhone] = useState<string>('')

  const cancelSubmit = () => {
    setUser(logoutUser as UserData)
    localStorage.clear()
    redirectUrl('/')
  }

  const onSubmit = handleSubmit((values) => {
    if (!errorPhone) {
      sentData(values, setErrorPhone, reset)
    }
  })

  function getInputNumber() {
    document.getElementById('number')?.addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault()
      }
    })
    setErrorPhone('')
  }
  useEffect(() => {
    setGoogleUser(JSON.parse(localStorage.getItem('dataGoogle') as string))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (user?.id) redirectUrl('/main/reports')
    if (cellPhone.cell === 0) {
      console.log('entor cel')
      setValue('phone', cellPhone.cell.toString())
    } else {
      console.log('entro emal')
      setValue('email', googleUser?.email as string)
      setValue('name', googleUser?.name as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleUser])
  return (
    <div className='container-form'>
      <img className='img-logo' src={logo} alt='img-logo-mappet' />
      <h1>Crea tu cuenta</h1>
      <p>Bienvenido a Mappet, completa tu informacion para ingresar e informarte de lugares pet friendly en tu ciudad:</p>
      <form className='form' onSubmit={onSubmit}>
        <div className='form-data'>
          <div className='form-group'>
            <label htmlFor='name'>Nombre Completo:</label>
            <input
              type='text'
              id='name'
              {...register('name', {
                required: {
                  value: true,
                  message: 'el Nombre es Requerido'
                },
                pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
              })}
              placeholder='Ingresa tu nombre completo'
            />
            {errors?.name?.type === 'required' && <p className='errors'>{errors.name.message}</p>}
            {errors?.name?.type === 'pattern' && <p className='errors'>'EL nombre no debe tener caracteres especiales'</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='Celular'>Celular:</label>
            <input
              id='number'
              type='number'
              {...register('phone', {
                required: {
                  value: true,
                  message: 'El numero de telefono es obligatorio'
                },
                pattern: /^3[0-2]{1}[0-9]{1}[0-9]{7}$/,
                onChange: () => getInputNumber()
              })}
              placeholder='Ingresa tu numero de celular'
              disabled={cellPhone.cell === 0 ? true : false}
            />
            {errors?.phone?.type === 'required' && <p className='errors'>{errors.phone.message}</p>}
            {errors.phone?.type === 'pattern' && <p className='errors'>Ingrese un numero de telefono valido.</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email:</label>
            <input
              type='text'
              {...register('email', {
                required: {
                  value: true,
                  message: 'El email es requerido'
                },
                pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
              })}
              placeholder='Ingresa tu email'
              disabled={cellPhone.cell ? false : true}
            />
            {errors?.email?.type === 'required' && <p className='errors'>{errors.email.message}</p>}
            {errors?.email?.type === 'pattern' && <p className='errors'>'EL correo ingresado no es un correo valido.'</p>}
          </div>
        </div>
        <div className='form-group'>
          <input
            id='politica'
            type='checkbox'
            {...register('is_terms_and_condition_accepted', {
              required: {
                value: true,
                message: 'Por favor acepte los terminos y condiciones.'
              }
            })}
          />
          <label htmlFor='politica'>
            Al continuar acepto el{' '}
            <button className='button-open-terms' onClick={() => setModalTermsConditions(true)}>
              Tratamiento de mis datos y las políticas de uso
            </button>{' '}
            de Mappet.
          </label>
          {errors?.is_terms_and_condition_accepted && <p className='errors'>{errors.is_terms_and_condition_accepted.message}</p>}
        </div>
        <div className='group-buttons'>
          <button className='btn btn-cancel' onClick={() => cancelSubmit()}>
            Cancelar
          </button>
          <input type='submit' value='Continuar' className='btn btn-user-submit' />
        </div>
      </form>
      {modalTermsConditions && <ModalTermsConditiones />}
    </div>
  )
}

export default FormUser
