import Modal from './Modal'
import { deleteDocPet } from '../../../services/deleteDocPet'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  idDoc: number
  setShowEditDoc: React.Dispatch<React.SetStateAction<boolean>>
  setShowModalDel: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalDeleteDoc = ({ idDoc, setShowEditDoc, setShowModalDel }: Props) => {
  const { data: token } = useGetToken()
  function deleteDocumentsPets() {
    deleteDocPet(idDoc, token).then((res) => {
      setShowModalDel(false)
      setShowEditDoc(false)
    })
  }

  return (
    <Modal>
      <div className='container-details-modal'>
        <span className='close' onClick={() => setShowModalDel(false)}>
          X
        </span>
        <div className='details-pets'>
          <div className='details-info'>
            <h2>Eliminar imagen</h2>
            <p>Una vez se realiza el proceso no se podrá recuperar la imagen. ¿Estas seguro que deseas eliminarla?</p>
          </div>
        </div>
        <div className='details-buttons'>
          <button className='btn button-close-terms' onClick={deleteDocumentsPets}>
            Eliminar
          </button>
          <button className='btn' style={{ color: '#1e1547' }} onClick={() => setShowModalDel(false)}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteDoc
