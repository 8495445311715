export const updateReport = async (id: number, data: any, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/reports/update/${id}/`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: JSON.stringify(data)
  }).then((res) => res.json())

  return response
}
