import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { PlaceCommentCard } from '../../components'

const CardsViews = () => {
  const { placeDetailsService } = useContext(MappetContext)

  const { placesCloser } = useContext(MappetContext)
  return (
    <div className={`main-card-views  ${placeDetailsService ? 'showing-details' : ''}`}>
      {placesCloser!.length > 0 ? placesCloser?.map((place: any) => <PlaceCommentCard place={place} key={place.uuid} />) : <p>No hay sitios que mostrar... Porfavor elija una categoria.</p>}
    </div>
  )
}
export default CardsViews
