import './ButtonFilter.css'

import { Link } from 'react-router-dom'
import React from 'react'

type Props = {
  setFilter: React.Dispatch<React.SetStateAction<string>>
  name: string
  value: string
}
const ButtonFilter = ({ setFilter, name, value }: Props) => {
  return (
    <Link to='#' onClick={() => setFilter(value)} className='button-filter-pets'>
      {name}
    </Link>
  )
}

export default ButtonFilter
