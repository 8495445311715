const deleteReport = (idReport: number, token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const deleteReportResponse = fetch(`${URL}/api/v1/reports/delete/${idReport}/`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })

  return deleteReportResponse
}

export default deleteReport
