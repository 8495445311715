import { Navigate, Outlet, useLocation } from 'react-router-dom'
import React, { useContext } from 'react'

import CryptoJS from 'crypto-js'
import { MappetContext } from '../../utils'
import { userExists } from '../../services/userExists'

const PrivateRoutes = () => {
  const { user, googleUser } = useContext(MappetContext)
  const location = useLocation()
  //verificar si el usuario esta registrado
  if (googleUser?.sub) {
    userExists(googleUser?.sub as string)
      .then((item: any) => {
        if (item.detail === 'Usuario no existe') {
          googleUser.sub = ''
        } else {
          let userDataString = JSON.stringify(item)
          const cipherUserData = CryptoJS.AES.encrypt(userDataString, process.env.REACT_APP_ENC_SECRET_KEY!).toString()
          localStorage.setItem('user', cipherUserData)
        }
      })
      .catch((error) => console.log(error))
  }

  return user?.id ? <Outlet /> : <Navigate to='/' replace state={{ url: location.pathname }} />
}

export default PrivateRoutes
