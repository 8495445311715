export const saveDocPets = async (id: string, images: File | null | undefined, tipo_doc: string, token: string) => {
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL
  const data = new FormData()

  if (!images?.name.startsWith('http:/') && !images?.name.startsWith('https:/')) {
    data.append('name', images?.name.split('.')[0] as string)
    data.append('document_type', tipo_doc)
    data.append('image', images as unknown as string)
    data.append('pet_id', id)

    const response = await fetch(`${URL}/api/v1/pets/create_pets_documents/`, {
      method: 'POST',
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: `${API_TOKEN}`
      },
      body: data
    })
    const resJson = await response.json()
    return resJson
  }
}
