import './CardStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'
import { preferenceCard, preferenceMap } from '../../../assets/images/mappet'

import { MappetContext } from '../../../utils'

interface PreferenceCardProps {
  preference: 'Mapa' | 'Tarjetas'
  isSelected: boolean
}

const PreferenceCard = ({ preference, isSelected }: PreferenceCardProps) => {
  const { iconsImages } = useContext(MappetContext)
  return (
    <div className={`preference-card ${isSelected ? 'selected-card ' : ''}`}>
      <div className='left-column'>
        {iconsImages?.MAPVIEW_IMAGE ? (
          <img src={preference === 'Mapa' ? iconsImages.MAPVIEW_IMAGE.images.md : iconsImages.CARDVIEW_IMAGE?.images.md} alt='preference-cover' />
        ) : (
          <img src={preference === 'Mapa' ? preferenceMap : preferenceCard} alt='preference-cover' />
        )}
      </div>
      <div className='right-column'>
        <h4>{`Vista de ${preference}`}</h4>
        <p>{preference === 'Mapa' ? 'La información se verá georeferenciada en un mapa' : 'Verás la información más cercana a tu ubicación'}</p>
      </div>
    </div>
  )
}

export default PreferenceCard
