import React from 'react'
import ic_close from '../../../assets/images/mappet/ic_close.png'

type Props = {
  setShowOptions: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditDoc: React.Dispatch<React.SetStateAction<boolean>>
}
const OptionsVIewer = ({ setShowOptions, setShowEditDoc }: Props) => {
  return (
    <div className='modal-options'>
      <div className='container-options'>
        <button className='close-options' onClick={() => setShowOptions(false)}>
          <img src={ic_close} alt='cerrar opciones' />
        </button>
        <div className='options-menu'>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowEditDoc(true)
              setShowOptions(false)
            }}
          >
            Editar
          </p>
          <hr />
          <p>Compartir</p>
        </div>
      </div>
    </div>
  )
}

export default OptionsVIewer
