import { UserInteraction } from '../types'
export const saveUserInteractions = async (data: Partial<UserInteraction>, token: string) => {
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL

  const response = await fetch(`${URL}/api/v1/places/register_user_activity/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: JSON.stringify(data)
  })
  const resJson = await response.json()
  return resJson
}
