export async function getCodeInstagram(code: string, id_pet: number | null, token: string) {
  const API_TOKEN = token
  const data = new FormData()
  data.append('code', code)
  if (!id_pet) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/instagram/images/`, {
      method: 'POST',
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: `${API_TOKEN}`
      },
      body: data
    })

    const res_code = await response.json()
    return res_code
  } else {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/pets/instagram/images/?id_pet=${id_pet}`, {
      method: 'POST',
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: `${API_TOKEN}`
      },
      body: data
    })

    const res_code = await response.json()
    return res_code
  }
}
