import '../../../assets/css/styles.css'
import './AccountComponentsStyles.css'

import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { getAboutMappet } from '../../../services'

type AccountActions = 'pets' | 'notifications' | 'edit' | 'about' | 'policy' | 'logout'
interface AccountAboutProps {
  setSelectedAction: Dispatch<SetStateAction<AccountActions>>
}

const AccountAbout = ({ setSelectedAction }: AccountAboutProps) => {
  useEffect(() => {
    getAboutMappet()
      .then((res: string) => {
        if (document.getElementById('policy-html')) {
          document.getElementById('policy-html')!.innerHTML = res
        }
      })
      .catch((err: any) => console.log(err))
  }, [])

  return (
    <div className='account-about-container'>
      <i onClick={() => setSelectedAction('pets')} className='back-button fa-solid fa-arrow-left'></i>
      <h2 className='title_modal'>Acerca de Mappet</h2>
      <hr />
      <div id='policy-html'>
        <p>Cargando...</p>
      </div>
    </div>
  )
}

export default AccountAbout
