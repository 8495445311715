import { ListImagesEdit, Pet } from '../types'

import { MappetContext } from '../utils'
import { UserData } from './../types.d'
import { getBreed } from '../utils/getBreed'
import { getListPets } from '../services/getListPets'
import { mostrarModalSuccess } from '../utils/mostrarModalSuccess'
import { saveImagesPetRegister } from '../services/saveImagesPetsRegister'
import { updatePet } from '../services/updatePets'
import { useContext } from 'react'
import { useGetToken } from './useGetToken'

export const useSaveEditPet = () => {
  const { setPetList, petType, setPetBreed, user } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const sentData = (dataForm: Pet, image: ListImagesEdit, petData: Pet | undefined, setShowEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
    const data = new FormData()
    data.append('profile_id', user?.id as unknown as string)
    data.delete('pet')
    data.delete('instagram')
    Object.entries(dataForm).forEach(([key, value]) => {
      data.append(`${key}`, `${value}`)
    })

    updatePet(data, petData?.id as string, token).then((res: any) => {
      if (image.imagesLocal.length !== 0) {
        saveImagesPetRegister(res.id, image.imagesLocal, token).then((response) => {
          mostrarModalSuccess('reg-success')
          console.log(response)
          getBreed('Perro', petType, setPetBreed, token)
          getListPets(user as UserData, token)
            .then((pets) => {
              setPetList(pets.results)
              setShowEdit(false)
            })
            .catch((error) => console.log(error))
        })
      }
      getBreed('Perro', petType, setPetBreed, token)
      getListPets(user as UserData, token)
        .then((pets) => {
          setPetList(pets.results)
          setShowEdit(false)
        })
        .catch((error) => console.log(error))
    })
  }
  return {
    sentData
  }
}
