import { ListImagesEdit, UserData } from '../../../types'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import deleteImagesRegisterPets from '../../../services/deleteImagesRegisterPets'
import { getListPets } from '../../../services/getListPets'
import { useContext } from 'react'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  modalDelImg: {
    view: boolean
    name: string
    id: number
  }
  setModalDelImg: React.Dispatch<
    React.SetStateAction<{
      view: boolean
      name: string
      id: number
    }>
  >
  setImages: (value: React.SetStateAction<ListImagesEdit>) => void
  images: ListImagesEdit
}

const ModalDeleteImagePet = ({ modalDelImg, setModalDelImg, setImages, images }: Props) => {
  const { user, setPetList } = useContext(MappetContext)
  const { data: token } = useGetToken()
  function deleteImages() {
    if (modalDelImg.name) {
      setImages({ ...images, imagesLocal: images.imagesLocal.filter((image) => image.name !== modalDelImg.name) })
    }
    if (modalDelImg.id) {
      setImages({ ...images, imagesServer: images.imagesServer && images.imagesServer.filter((image) => image.id !== modalDelImg.id) })
      deleteImagesRegisterPets(modalDelImg.id as number, user.token as string).then((res) => {
        if (res.ok) getListPets(user as UserData, token).then((res) => setPetList(res.results))
      })
    }
    setModalDelImg({ ...modalDelImg, view: false })
  }
  return (
    <Modal>
      <div className='container-details-modal'>
        <span className='close' onClick={() => setModalDelImg({ ...modalDelImg, view: false })}>
          X
        </span>
        <div className='details-pets'>
          <div className='details-info'>
            <h2>Eliminar mascota</h2>
            <p>Una vez se realiza el proceso no se podrá recuperar la imagene de la mascota: ¿Estas seguro que deseas eliminarla?</p>
          </div>
        </div>
        <div className='details-buttons'>
          <button className='btn button-close-terms' onClick={() => deleteImages()}>
            Si
          </button>
          <button className='btn' style={{ color: '#1e1547' }} onClick={() => setModalDelImg({ ...modalDelImg, view: false })}>
            No
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteImagePet
