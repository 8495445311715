export const saveListImages = async (id: string, images: File[] | null | undefined, token: string) => {
  const data = new FormData()
  data.append('report_id', id)
  const arrayImages = Array.from(images as Iterable<File> | ArrayLike<File>)
  arrayImages.forEach((image) => {
    if (!image.name.startsWith('http:/') && !image.name.startsWith('https:/')) data.append('images', image)
  })
  const API_TOKEN = token
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/reports/images/`, {
    method: 'POST',
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    },
    body: data
  })
  const resJson = await response.json()
  return resJson
}
