import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'

const ModalTermsConditiones = () => {
  const { setModalTermsConditions } = useContext(MappetContext)
  return (
    <Modal>
      <div className='container_modal_condition'>
        <h1 className='title_modal'>Terms and Conditions</h1>
        <hr />
        <p className='parraf_modal'>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus ipsam perferendis rem dolore. Expedita accusamus, ea incidunt laboriosam minima non, adipisci, ex accusantium quasi
          repellat quidem facere ratione? Qui, rem! Cum fugit fugiat vero aspernatur odio minima, obcaecati vitae distinctio voluptatibus blanditiis eligendi illum omnis, illo ab! Commodi explicabo in
          nulla ratione mollitia, voluptates esse obcaecati tenetur eius. Quia, voluptatum. Eaque at iste modi est sapiente odit culpa temporibus debitis porro quod, in aperiam cupiditate tenetur
          voluptates. Incidunt, nihil minus vitae quibusdam consequatur mollitia expedita numquam, et quasi iste assumenda! Omnis possimus explicabo aperiam, fuga ipsam quas labore tenetur id unde
          consequuntur quisquam. Provident eaque a perferendis deleniti, voluptatum suscipit nisi! Cupiditate, tempora enim explicabo quasi corporis repudiandae. Excepturi, sit! Reprehenderit,
          quibusdam quaerat adipisci quia in cumque eaque provident dolorem nam sapiente repellendus animi beatae autem suscipit illum ipsum, corporis dignissimos, veritatis et delectus atque vel.
          Consectetur cum tenetur fugit. At pariatur quidem nemo. Quas iure nesciunt quaerat aperiam dolores odio veritatis magnam. Praesentium est totam eveniet ex dolore, quod tenetur eos fugit
          commodi exercitationem. Perferendis quaerat ea nihil voluptatibus! Perspiciatis eum, unde cum ipsa ipsum libero iusto, quo illo, beatae voluptates ut animi. Molestias placeat expedita
          voluptatibus suscipit vero autem debitis culpa? Reiciendis ipsa, laboriosam labore iste quasi provident. Veritatis dolore sed blanditiis voluptates eum, beatae nihil placeat vitae? Modi
          voluptas at, fugiat quos, nihil exercitationem blanditiis omnis, aspernatur tempora sint nisi quaerat. <br />
          <br /> Repellat esse iste quos! Fuga commodi amet esse possimus cupiditate iure cum assumenda doloribus expedita tenetur omnis, cumque eos culpa perferendis? Quis quibusdam, repudiandae
          animi nostrum corrupti quod hic iusto! Eum, cumque distinctio! Consectetur! Eos quibusdam quasi consequatur ipsam sed ducimus tenetur mollitia et quas voluptates, odit nisi excepturi illo
          laudantium. Nesciunt, provident iure nihil totam animi cumque quibusdam, similique dolore et assumenda pariatur. Eaque perferendis illo porro iusto est veritatis illum vero itaque non ab
          maxime officiis impedit quo eos quam dignissimos, neque ad totam voluptatem. Molestiae fuga alias tenetur reiciendis hic officiis. Corrupti possimus inventore alias dolorem recusandae, ab
          eaque dolore atque consequuntur fuga tempora voluptate quo odit minus porro enim quis repudiandae, quam cumque nam? Doloribus porro sequi dolorem possimus asperiores. Voluptas ipsa
          distinctio placeat expedita eius soluta, quas nobis provident fuga itaque dolores molestiae quia dignissimos exercitationem possimus minima optio. Ad est consequuntur iusto, dolorum tempore
          voluptate nobis corporis voluptatum. Quas, molestias! Id libero illum officia, alias ab enim sapiente sint repellat autem ut neque ipsa magni eos officiis aliquam vitae doloribus architecto
          asperiores harum ratione aut. Quas, excepturi eveniet. Quod debitis obcaecati quam nobis amet libero dolorem nesciunt animi earum reiciendis modi, facilis iste eveniet in laudantium,
          provident omnis, consequuntur incidunt ut ratione qui impedit. Qui rerum voluptatem recusandae. A repellendus neque natus saepe beatae ipsum, dolores explicabo commodi vero blanditiis
          pariatur quisquam quo quos provident voluptates quod suscipit ea fugiat. Earum quibusdam, cumque voluptatibus ipsa perferendis laboriosam ducimus? Ab, magnam similique ipsum nulla illum
          voluptas, aspernatur mollitia omnis suscipit architecto maiores autem sed obcaecati velit corporis quis nostrum expedita dolorem animi optio. Labore nesciunt quae quas earum veritatis!
          Aliquam eligendi id est ipsa, nesciunt omnis dolorum, obcaecati nam doloremque asperiores esse, repellendus possimus eveniet. Obcaecati nesciunt, impedit aliquid pariatur quos amet voluptate
          ratione, soluta iure consectetur harum tempora. Eos quia, libero ipsam ducimus voluptate nobis facere temporibus veritatis nulla at neque odio dicta ex dolores aperiam? Debitis ea labore
          cupiditate enim iste qui omnis nisi distinctio sit repellat. Alias est magni, asperiores ipsam veniam optio commodi nemo rerum quia ratione consectetur, deserunt expedita deleniti soluta
          iste accusantium. Consequatur, rem iusto? Voluptates ipsum explicabo hic sapiente voluptatem, rerum officiis. Error porro cum autem! Ducimus eos reprehenderit aut deserunt cupiditate quam
          adipisci magnam quae sed tempore, non quas perferendis! Illo necessitatibus officia ut fuga est similique quos doloribus unde officiis. Quasi quibusdam voluptatum debitis vel? Et voluptatum
          modi officiis, nulla quasi facilis praesentium iure cumque quos consequuntur aperiam accusantium? Tempora fugiat quaerat sapiente quam temporibus voluptate iste maiores molestias aliquid?
          Ducimus saepe libero placeat cupiditate, enim impedit aliquam doloremque fuga ab laborum tempora totam odio ad laudantium harum error sequi. Qui, maxime? Veritatis, officiis quos! Magnam
          sequi iure at nihil. Ex nisi, earum quo laborum labore, iure nesciunt maxime nobis dolorem, beatae ab doloribus sint odio dolores corporis consequatur iste. Laudantium delectus rerum odit
          assumenda ipsam a, tempore necessitatibus consectetur. Optio eius aliquid velit praesentium alias nihil libero. Officia dolore ratione magni amet inventore beatae, maiores quas illo repellat
          quos quis ipsum culpa! Eum, sint inventore! Unde assumenda veritatis nihil? Earum quae tempore repellendus totam nesciunt explicabo dolorem perferendis, necessitatibus debitis maxime omnis
          similique et, aspernatur, soluta molestiae vitae velit esse mollitia nulla. Perferendis voluptates ad blanditiis error? Dolorem, vel. Repellat, minima? Quibusdam nam eos doloremque sint,
          molestias, modi animi, ipsa qui quidem velit atque? Quos iusto officia incidunt rem voluptas sequi ullam cumque iure laboriosam quae sit, ipsum nam? Magni laudantium ad blanditiis non eius
          officia, incidunt nisi tempore quasi dignissimos, et amet corporis minus aspernatur alias at cum atque molestias dolore quia? Sed suscipit deserunt esse vitae fugiat! Inventore veniam nemo
          itaque, tempore optio id labore blanditiis perspiciatis! Architecto unde ratione voluptatum laudantium porro labore, eaque amet quam fuga temporibus ab possimus soluta maxime. <br />
          <br />
        </p>
        <button className='button button-close-terms' onClick={() => setModalTermsConditions(false)}>
          Cerrar
        </button>
      </div>
    </Modal>
  )
}

export default ModalTermsConditiones
