const deleteImageProfile = (token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const deleteImageProfile = fetch(`${URL}/api/v1/profile/delete_photo/`, {
    method: 'PATCH',
    headers: {
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })

  return deleteImageProfile
}

export default deleteImageProfile
