import './AccountStyles.css'
import '../../../assets/css/styles.css'

import { AccountAbout, AccountNotifications, AccountPetList, AccountPolicy, FinishedSurveyModal } from '../../components'
import { GoogleUser, Pet } from '../../../types'
import { Link, useNavigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { imgElement3, imgMappet } from '../../../assets/images/mappet'

import AccoundEditPerfil from '../../components/AccountComponents/AccoundEditPerfil'
import AccountDocPets from '../../components/AccountComponents/AccountDocPets'
import AccountEditPet from '../../components/AccountComponents/AccountEditPet'
import { MappetContext } from '../../../utils'
import { Toaster } from 'react-hot-toast'
import { getCodeInstagram } from '../../../services/getCodeInstagram'
import { useGetToken } from 'hooks/useGetToken'

type AccountActions = 'pets' | 'notifications' | 'edit' | 'about' | 'policy' | 'logout'

const Account = () => {
  const { setUser, user, logoutUser, setGoogleUser, showFinishedSurveyModal, setUpdateReportId, setShowOtherUser, showOtherUser, setShowPlaceMap, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const [selectedAction, setSelectedAction] = useState<AccountActions>('pets')
  const [docPets, setDocPets] = useState<Pet>()
  const [showDocPets, setShowDocPets] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const resCode = window.location.href.includes('?') && window.location.href.split('?')[1].split('=')[1]
  const code = resCode && resCode.substring(0, resCode.length - 2)

  useEffect(() => {
    setUpdateReportId(null)
    setShowOtherUser({ ...showOtherUser, isVisible: false })
    setShowPlaceMap(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function connectInstagram() {
    let id_clent = process.env.REACT_APP_CLIENT_INSTAGRAM
    let url_redirect = process.env.REACT_APP_REDIRECT_URL
    window.open(`https://api.instagram.com/oauth/authorize?client_id=${id_clent}&redirect_uri=${url_redirect}&scope=user_profile,user_media&response_type=code`, '_self')
  }

  const redirectUrl = useNavigate()

  const onLogout = () => {
    localStorage.clear()
    setUser(logoutUser)
    setGoogleUser({} as GoogleUser)
    setTimeout(() => {
      redirectUrl('/')
    }, 3000)
  }

  useEffect(() => {
    if (code && token) {
      getCodeInstagram(code as string, null, token).then((response) => console.log(response))
    }
  }, [code, docPets?.id, token])
  return (
    <div className='account-main'>
      <div className='account-view-container'>
        {showFinishedSurveyModal && <FinishedSurveyModal />}
        <img className='corner-decoration' src={imgElement3} alt='' />
        <div className='top-container'>
          <div
            className='profile-pict'
            style={{
              backgroundImage: user ? `url(${user?.profile_picture})` : `url(${imgMappet})`
            }}
          >
            {!user?.profile_picture && <img src={iconsImages?.DEFAULT_USER_COMMENT ? iconsImages.DEFAULT_USER_COMMENT.images.md : imgMappet} alt='default images' />}
          </div>
          <div className='account-info'>
            <h3>{user && user.name}</h3>
            <p>
              {
                // 304 661 0821
                user ? user.phone?.replace('+57', '') : 'Desconocido'
              }
            </p>
            {user?.instagram_username ? (
              <p style={{ color: 'red' }}>{user?.instagram_username}</p>
            ) : (
              <p onClick={connectInstagram} style={{ cursor: 'pointer' }}>
                Conectar con Instagram
              </p>
            )}
            {/* {user.instagram ? <a href='/main/account'>{user.instagram}</a> : <a href='/main/account'>Conectar con Instagram</a>} */}
          </div>
        </div>
        <div className='bottom-container'>
          <div className='right-panel'>
            {selectedAction === 'pets' ? (
              !showDocPets ? (
                <AccountPetList setDocPets={setDocPets} setShowDocPets={setShowDocPets} />
              ) : !showEdit ? (
                <AccountDocPets setDocPets={setDocPets} docPets={docPets} setShowDocPets={setShowDocPets} setShowEdit={setShowEdit} showEdit={showEdit} />
              ) : (
                <AccountEditPet pet={docPets as Pet} setShowEdit={setShowEdit} setShowDocPets={setShowDocPets} setDocPets={setDocPets} />
              )
            ) : selectedAction === 'notifications' ? (
              <AccountNotifications setSelectedAction={setSelectedAction} />
            ) : selectedAction === 'about' ? (
              <AccountAbout setSelectedAction={setSelectedAction} />
            ) : selectedAction === 'edit' ? (
              <AccoundEditPerfil setSelectedAction={setSelectedAction} />
            ) : (
              <AccountPolicy setSelectedAction={setSelectedAction} />
            )}
          </div>
          <div className='account-actions'>
            <Link to='#' onClick={() => setSelectedAction('pets')} className={selectedAction === 'pets' ? 'selected' : ''}>
              <span>Mis mascotas</span>
            </Link>
            <Link to='#' onClick={() => setSelectedAction('notifications')} className={selectedAction === 'notifications' ? 'selected' : ''}>
              <span>Notificaciones</span>
            </Link>
            <Link to='#' onClick={() => setSelectedAction('edit')} className={selectedAction === 'edit' ? 'selected' : ''}>
              <span>Editar mis datos</span>
            </Link>
            <Link to='#' onClick={() => setSelectedAction('about')} className={selectedAction === 'about' ? 'selected' : ''}>
              <span>Acerca de Mappet</span>
            </Link>
            <Link to='#' onClick={() => setSelectedAction('policy')} className={selectedAction === 'policy' ? 'selected' : ''}>
              <span>Política de tratamiento de datos</span>
            </Link>
            <Link to='/' onClick={() => onLogout()} className={`logout ${selectedAction === 'logout' ? 'selected' : ''}`}>
              <span>Cerrar sesión</span>
            </Link>
            <footer className='account-footer'>
              {iconsImages?.DEFAULT_ICON ? (
                <img src={iconsImages.DEFAULT_ICON.images.md} alt='mappet-logo' className='logo-footer' />
              ) : (
                <img src={imgMappet} alt='mappet-logo' className='logo-footer' />
              )}

              <p>-</p>
              <p>Versión 1.0.0</p>
            </footer>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Account
