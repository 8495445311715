import { Place, PlaceComment } from '../types'

import { useState } from 'react'

const ReportHook = () => {
  // const [petReports, setPetReports] = useState<Array<PetReport>>([])
  const [commentedPlaces, setCommentedPlaces] = useState<Array<Place>>([])
  const [loadingReports, setLoadingReports] = useState<boolean>(true)
  const [placeComments, setPlaceComments] = useState<Array<PlaceComment>>([])

  return { placeComments, setPlaceComments, commentedPlaces, setCommentedPlaces, loadingReports, setLoadingReports }
}

export { ReportHook }
