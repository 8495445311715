import React from 'react';
import { PetReport, Place } from '../../../types'
import PetReportDetail from './PetReportDetail';
import PlaceReportDetail from './PlaceReportDetail';

interface Report {
    reportData: PetReport | Place
}

const ReportDetail = ({ reportData }: Report) => {

    return (
            reportData.register_type === 'place'
                ? <PlaceReportDetail reportData={reportData} />
                : <PetReportDetail reportData={reportData} />
    )
}

export default ReportDetail