export async function getImagesAndIcons() {
  const URL = process.env.REACT_APP_BACKEND_URL
  const response = await fetch(`${URL}/api/v1/mappeticons/list/`, {
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`
    }
  })
  const dataIconsImg = await response.json()
  return dataIconsImg
}
