import imgDefault from '../assets/images/profile/huella.png'
export const previewsImg = (imagen: File | null) => {
  let imgUrl: string = ''
  if (imagen && imagen?.name.startsWith('http')) {
    imgUrl = imagen.name as string
    document.getElementById('img-profile')?.setAttribute('src', imgUrl)
    return
  }
  let ext = imagen?.name.split('.')[1] as string
  const extImag = ['jpg', 'jpeg', 'gif', 'png', 'bmp']
  if (imagen && extImag.indexOf(ext as string) !== -1) {
    imgUrl = URL.createObjectURL(imagen as Blob)
    document.getElementById('img-profile')?.setAttribute('src', imgUrl)
  } else {
    document.getElementById('img-profile')?.setAttribute('src', imgDefault)
  }
}
