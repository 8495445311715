import { useQuery } from 'react-query'

export const useGetToken = () => {
  const token = localStorage.getItem('token')
  const { data } = useQuery(['token'], () => {
    if (token !== undefined) {
      return JSON.parse(token as string)
    }
  })
  console.log(data)
  return {
    data
  }
}
