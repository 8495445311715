export const getListPlaces = async (location: number[], type: string | null = '', keyword: string | null = '', token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const response = await fetch(`${URL}/api/v1/places/google_places_near_user/?location=${location[0]}1%2C${location[1]}&keyword=${keyword ? keyword : ''}&type=${type ? type : ''}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const resPlaceClose = await response.json()
  resPlaceClose.results.forEach((list: any) => {
    list.place_images.forEach((element: any) => {
      element.image = element.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
    })
  })
  return resPlaceClose
}
