import { MappetContext } from '../utils/contextApi'
import { UseFormReset } from 'react-hook-form'
import { User } from '../types'
import { createUser } from '../services/createUser'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const useFormUser = (initialForm: User) => {
  const navigate = useNavigate()
  const { googleUser, setIsRegistering, setUser } = useContext(MappetContext)

  const sentData = (dataUser: User, setErrorPhone: React.Dispatch<React.SetStateAction<string>>, reset: UseFormReset<User>) => {
    dataUser.id_user = googleUser!.sub as string
    createUser(dataUser)
      .then((res) => {
        if (res.phone[0] !== 'Ya existe un/a Perfil con este/a Celular.') {
          setUser(res)
          setIsRegistering(true)
          navigate('/main/register/pets')
          reset()
        } else {
          setErrorPhone(res.phone[0] as string)
        }
      })
      .catch(() => console.log('Error de conexión'))
  }
  return {
    sentData
  }
}
