import { ListImages, PetFriendly } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import ModalGetAddress from '../../components/Modal/ModalGetAddress'
import ModalImages from '../../components/Modal/ModalImages'
import createStartRate from '../../../utils/createStartRate'
import { getErrorsImages } from '../../../utils/getErrorsImages'
import { useForm } from 'react-hook-form'
import { useFormReportPetFriendly } from '../../../hooks/useFormReportPetFriendly'

interface Props {
  modalAdr: Boolean
  setModalAdr: (c: Boolean) => void
}

const FriendlyReport = ({ modalAdr, setModalAdr }: Props) => {
  const [addr, setAddr] = useState<any>({ name: '' })
  const [rate, setRate] = useState<number>(0)
  const [data, setData] = useState<PetFriendly>()
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [cantImage, setCantImage] = useState<ListImages>({ images: [], imagesDel: [] })
  const [isValidated, setIsValidated] = useState<boolean>(false)
  const { sentData } = useFormReportPetFriendly(addr, data, cantImage)
  const { iconsImages } = useContext(MappetContext)

  const handlerChangeCantImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCantImage({
      ...cantImage,
      images: [e.target.files![0]]
    })
    e.target.type = 'text'
    e.target.type = 'file'
  }

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm<PetFriendly>()

  const onSubmit = handleSubmit((values) => {
    if (!error) {
      setData(values)
      sentData(values, addr, cantImage)
      reset()
      setAddr({ name: '' })
      setRate(0)
      setIsValidated(!isValidated)
      setCantImage({ images: [], imagesDel: [] })
    }
  })
  const handleStarts = (e: React.MouseEvent<HTMLInputElement>) => {
    setRate(Number(e.currentTarget.value))
  }
  useEffect(() => {
    if (iconsImages?.STAR && iconsImages.STAR_ACTIVE) {
      createStartRate(rate, iconsImages.STAR.images.sm, iconsImages.STAR_ACTIVE.images.sm)
    }
  }, [iconsImages?.STAR, iconsImages?.STAR_ACTIVE, rate])
  useEffect(() => {
    setValue('address', addr.name, { shouldValidate: isValidated as boolean })
  }, [addr.name, setValue, setModalAdr, isValidated])

  return (
    <>
      <form onSubmit={onSubmit} encType='multipart/form-data'>
        <div className='form-report'>
          <label htmlFor='address'>Ubicación</label>
          <input
            type='text'
            {...register('address', {
              required: {
                value: true,
                message: 'La dirección es requerida'
              },
              onBlur: () => {
                setIsValidated(true)
              }
            })}
            id='address'
            placeholder='Ingrese el nombre del lugar'
            onClick={() => setModalAdr(true)}
            autoComplete='off'
            readOnly
          />
          {errors.address && <span className='errors'>{errors.address.message}</span>}
        </div>
        <p>Califica y deja una opinion del lugar (opcional):</p>
        <div className='opinion'>
          <div className='rating'>
            <div className='start-container'>
              {Array(5)
                .fill('')
                .map((_, index) => (
                  <p key={index}>
                    <label htmlFor={`starts-${index + 1}`} className='paws' id={`start-${index + 1}`}></label>
                    <input
                      type='radio'
                      {...register('rating', {
                        required: {
                          value: true,
                          message: 'Por favor de una calificación'
                        }
                      })}
                      onClick={(e) => handleStarts(e)}
                      id={`starts-${index + 1}`}
                      value={index + 1}
                    />
                  </p>
                ))}
            </div>
            {!rate && <p>Sin calificación</p>}
            {errors.rating && <span className='errors'>{errors.rating.message}</span>}
          </div>
          <div className='form-report'>
            <label htmlFor='opinion'>Opinión</label>
            <input
              type='text'
              {...register('comment', {
                required: {
                  value: true,
                  message: 'Por favor de una opinión del lugar'
                }
              })}
              id='opinion'
              placeholder='¿Te gustó? ¿Es fácil de encontrar?'
            />
            {errors.comment && <span className='errors'>{errors.comment.message}</span>}
          </div>
        </div>

        <div className='form-buttom-friendly'>
          <label htmlFor='img-report-friendly' className='btn btn-photo'>
            Cargar fotografías ({cantImage.images.length === 0 ? 0 : cantImage.images.length})
          </label>
          <input
            type='file'
            id='img-report-friendly'
            hidden
            {...register('place_image', {
              required: {
                value: true,
                message: 'Debe proporcionar una imagen del lugar.'
              },
              onChange: (e) => {
                handlerChangeCantImages(e)
                if (!error) setError(e.target.files[0] !== undefined ? getErrorsImages(e.target.files[0].name) : '')
              }
            })}
            accept='image/png, image/jpeg, image/gif, image/jpg, image/bmp'
          />
          <input type='submit' className='btn btn-report' value='Enviar Reporte' />
        </div>
        {cantImage.images.length > 0 && (
          <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setImageModal(true)}>
            Ver fotos({cantImage.images.length})
          </p>
        )}
        {errors.place_image && <span className='errors'>{errors.place_image.message}</span>}
        {error && <span className='errors'>{error}</span>}
      </form>
      {modalAdr && <ModalGetAddress setAddr={setAddr} setModalAdr={setModalAdr} />}
      {imageModal && <ModalImages setImageModal={setImageModal} cantImages={cantImage} setCantImages={setCantImage} setError={setError} />}
    </>
  )
}

export default FriendlyReport
