import { GooglePlace, GoogleRoute, GoogleUser, IconsImages, Pet, PetBreed, PetReport, PetType, Place, UserData, UserInteraction, listImagesIcons } from '../types'
import React, { createContext, useEffect, useState } from 'react'

import CryptoJS from 'crypto-js'
import { getImagesAndIcons } from '../services/getImagesAndIcons'
import getUserLocation from './getUserLocation'

const cipherUserData = localStorage.getItem('user')
const bytesUserData = cipherUserData ? CryptoJS.AES.decrypt(cipherUserData!, process.env.REACT_APP_ENC_SECRET_KEY!) : ''
const userDataString = cipherUserData ? bytesUserData.toString(CryptoJS.enc.Utf8) : null

interface Context {
  cellPhone: { cell: number }
  iconsImages: listImagesIcons | undefined
  setIconsImages: (c: listImagesIcons) => void
  listIconsImages: IconsImages[]
  setListIconsImages: (c: IconsImages[]) => void
  typeView: string
  setTypeView: (c: string) => void
  modalVisible: boolean
  setModalVisible: (c: boolean) => void
  showGetCoord: boolean
  setShowGetCoord: (c: boolean) => void
  showOtherUser: { isVisible: boolean; idPlace: string }
  setShowOtherUser: (c: { isVisible: boolean; idPlace: string }) => void
  showPlaceMap: boolean
  setShowPlaceMap: (c: boolean) => void
  modalTermsConditions: boolean
  setModalTermsConditions: (c: boolean) => void
  modalAddPet: boolean
  setModalAddPet: (c: boolean) => void
  googleUser: Partial<GoogleUser> | undefined
  setGoogleUser: (c: GoogleUser) => void
  user: Partial<UserData>
  setUser: (c: Partial<UserData>) => void
  logoutUser: UserData
  petType: PetType[] | undefined
  setPetType: (c: PetType[]) => void
  pet: PetType | undefined
  setPet: (c: PetType) => void
  petBreed: PetBreed[] | null
  setPetBreed: (c: PetBreed[]) => void
  petList: Pet[] | undefined
  setPetList: (c: Pet[]) => void
  navbarCollapseClass: string
  setNavbarCollapseClass: (c: string) => void
  isRegistering: boolean
  setIsRegistering: (c: boolean) => void
  showReportDetails: PetReport | Place | null
  setShowReportDetails: (c: PetReport | Place | null) => void
  showDeleteReportModal: PetReport | null
  setShowDeleteReportModal: (c: PetReport | null) => void
  userLocation: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  setUserLocation: (c: google.maps.LatLng | google.maps.LatLngLiteral | undefined) => void
  currentIndex: number
  setCurrentIndex: (c: number) => void
  placesCloser: GooglePlace[] | null
  setPlaceCloser: (c: GooglePlace[]) => void
  showFinishedSurveyModal: string | null
  setShowFinishedSurveyModal: (c: string | null) => void
  selectPage: string
  setSelectPage: (c: string) => void
  updateReportId: number | null
  setUpdateReportId: (c: number | null) => void
  petReports: Array<PetReport>
  setPetReports: (c: Array<PetReport>) => void
  petData: Pet | undefined
  setPetData: (c: Pet | undefined) => void
  placeDetailsService: any | null
  setPlaceDetailsService: (c: any | null) => void
  showCommentPlaceModal: GooglePlace | null
  setShowCommentPlaceModal: (c: GooglePlace | null) => void
  routeCoords: GoogleRoute | null
  setRouteCoords: (c: GoogleRoute | null) => void
  googleRouteResponse: null
  setGoogleRouteResponse: (c: any) => void
  userInteraction: Partial<UserInteraction>
  setUserInteraction: (c: Partial<UserInteraction>) => void
}

export const MappetContext = createContext<Context>({
  cellPhone: { cell: 0 },
  iconsImages: undefined,
  setIconsImages: () => {},
  listIconsImages: [],
  setListIconsImages: () => [],
  typeView: 'map',
  setTypeView: () => '',
  modalVisible: false,
  setModalVisible: () => {},
  showGetCoord: false,
  setShowGetCoord: () => {},
  showOtherUser: { isVisible: false, idPlace: '' },
  setShowOtherUser: () => {},
  showPlaceMap: false,
  setShowPlaceMap: () => {},
  modalTermsConditions: false,
  setModalTermsConditions: () => {},
  modalAddPet: false,
  setModalAddPet: () => {},
  googleUser: JSON.parse(localStorage.getItem('dataGoogle') as string),
  setGoogleUser: () => {},
  user: JSON.parse(userDataString as string),
  setUser: () => {},
  petType: [{ name: '', id: '', icon: '' }],
  setPetType: () => [{}],
  pet: { name: '', id: '', icon: '' },
  setPet: () => {},
  petBreed: [],
  setPetBreed: () => [{}],
  logoutUser: { name: '', id_user: '', email: '', id: '', date_joined: '', phone: '', token: '', is_terms_and_condition_accepted: false, profile_picture: '', instagram_username: '' },
  petList: [],
  setPetList: () => [{}],
  navbarCollapseClass: 'string',
  setNavbarCollapseClass: () => {},
  isRegistering: false,
  setIsRegistering: () => {},
  showReportDetails: null,
  setShowReportDetails: () => {},
  showDeleteReportModal: null,
  setShowDeleteReportModal: () => {},
  userLocation: { lat: 0, lng: 0 },
  setUserLocation: () => {},
  currentIndex: 0,
  setCurrentIndex: () => {},
  placesCloser: null,
  setPlaceCloser: () => [{}],
  showFinishedSurveyModal: null,
  setShowFinishedSurveyModal: () => {},
  selectPage: '',
  setSelectPage: () => {},
  updateReportId: null,
  setUpdateReportId: () => {},
  petReports: [],
  setPetReports: () => {},
  petData: undefined,
  setPetData: () => {},
  placeDetailsService: null,
  setPlaceDetailsService: () => {},
  showCommentPlaceModal: null,
  setShowCommentPlaceModal: () => {},
  routeCoords: null,
  setRouteCoords: () => {},
  googleRouteResponse: null,
  setGoogleRouteResponse: () => {},
  userInteraction: {},
  setUserInteraction: () => {}
})

export const MappetDataProvider = ({ children }: any) => {
  const cellPhone = { cell: 3001234567 }
  const [typeView, setTypeView] = useState<string>('map')
  const logoutUser: UserData = { name: '', id_user: '', email: '', id: '', date_joined: '', phone: '', token: '', is_terms_and_condition_accepted: false, profile_picture: '', instagram_username: '' }
  const [pet, setPet] = useState<PetType>({} as PetType)
  const [petList, setPetList] = useState<Pet[]>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [showOtherUser, setShowOtherUser] = useState({ isVisible: false, idPlace: '' })
  const [showPlaceMap, setShowPlaceMap] = useState<boolean>(false)
  const [petType, setPetType] = useState<PetType[]>([])
  const [petBreed, setPetBreed] = useState<PetBreed[]>([])
  const [modalTermsConditions, setModalTermsConditions] = useState<boolean>(false)
  const [modalAddPet, setModalAddPet] = useState<boolean>(false)
  const [showGetCoord, setShowGetCoord] = useState<boolean>(false)
  const [navbarCollapseClass, setNavbarCollapseClass] = useState<string>('')
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [showReportDetails, setShowReportDetails] = useState<PetReport | Place | null>(null)
  const [showDeleteReportModal, setShowDeleteReportModal] = useState<PetReport | null>(null)
  const [googleUser, setGoogleUser] = useState<Partial<GoogleUser>>(JSON.parse(localStorage.getItem('dataGoogle') as string))
  const [userLocation, setUserLocation] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>({ lat: 0, lng: 0 })
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [placesCloser, setPlaceCloser] = useState<GooglePlace[]>([])
  const [showFinishedSurveyModal, setShowFinishedSurveyModal] = useState<string | null>(null)
  const [selectPage, setSelectPage] = useState<string>('reportar')
  const [updateReportId, setUpdateReportId] = useState<number | null>(null)
  const [petReports, setPetReports] = useState<Array<PetReport>>([])
  const [petData, setPetData] = useState<Pet | undefined>()
  const [placeDetailsService, setPlaceDetailsService] = useState<GooglePlace | null>(null)
  const [showCommentPlaceModal, setShowCommentPlaceModal] = useState<GooglePlace | null>(null)
  const [routeCoords, setRouteCoords] = useState<GoogleRoute | null>(null)
  const [googleRouteResponse, setGoogleRouteResponse] = useState<any>(null)
  const [userInteraction, setUserInteraction] = useState<Partial<UserInteraction>>({})

  const [user, setUser] = useState<Partial<UserData>>(JSON.parse(userDataString as string))
  const [iconsImages, setIconsImages] = useState<listImagesIcons>()
  const [listIconsImages, setListIconsImages] = useState<IconsImages[]>([])

  useEffect(() => {
    getImagesAndIcons().then((res: IconsImages[]) => {
      setListIconsImages(res)
    })
    setGoogleUser(JSON.parse(localStorage.getItem('dataGoogle') as string) as Partial<GoogleUser>)
  }, [user])

  useEffect(() => {
    if (navigator.geolocation) {
      getUserLocation()
        .then((lngLat) => {
          setUserLocation(lngLat)
          setShowGetCoord(false)
        })
        .catch((err) => {
          setShowGetCoord(true)
        })
    } else {
      alert('Su dispositivo no cuenta con Geolocalizacion')
    }
  }, [])
  useEffect(() => {
    if (listIconsImages.length !== 0) {
      setIconsImages({
        ...iconsImages,
        DEFAULT_ICON: listIconsImages.find((item) => item.key === 'default_icon') as IconsImages,
        EMPTY_PROFILE_PET: listIconsImages.find((item) => item.key === 'empty_profile_pet') as IconsImages,
        LOGO_MAPPET: listIconsImages.find((item) => item.key === 'logo_mappet') as IconsImages,
        INDEX_IMAGE_1: listIconsImages.find((item) => item.key === 'index1_image') as IconsImages,
        INDEX_IMAGE_2: listIconsImages.find((item) => item.key === 'index2_image') as IconsImages,
        INDEX_IMAGE_3: listIconsImages.find((item) => item.key === 'index3_image') as IconsImages,
        MAPVIEW_IMAGE: listIconsImages.find((item) => item.key === 'mapview_image') as IconsImages,
        CARDVIEW_IMAGE: listIconsImages.find((item) => item.key === 'cardview_image') as IconsImages,
        THANKYOU_IMAGE: listIconsImages.find((item) => item.key === 'thankyou_image') as IconsImages,
        EMERGENCIES: listIconsImages.find((item) => item.key === 'emergencies') as IconsImages,
        VETERINARY_CARE: listIconsImages.find((item) => item.key === 'veterinary_care') as IconsImages,
        COFFEE: listIconsImages.find((item) => item.key === 'coffee') as IconsImages,
        RESTAURANT: listIconsImages.find((item) => item.key === 'restaurant') as IconsImages,
        BAR: listIconsImages.find((item) => item.key === 'bar') as IconsImages,
        SHOPPING_MALL: listIconsImages.find((item) => item.key === 'shopping_mall') as IconsImages,
        PARK: listIconsImages.find((item) => item.key === 'park') as IconsImages,
        FOOD: listIconsImages.find((item) => item.key === 'food') as IconsImages,
        MEDICINES: listIconsImages.find((item) => item.key === 'medicines') as IconsImages,
        TOYS: listIconsImages.find((item) => item.key === 'toys') as IconsImages,
        CLOTHES: listIconsImages.find((item) => item.key === 'clothes') as IconsImages,
        ADD: listIconsImages.find((item) => item.key === 'add') as IconsImages,
        BACK: listIconsImages.find((item) => item.key === 'back') as IconsImages,
        CAMERA: listIconsImages.find((item) => item.key === 'camera') as IconsImages,
        CLOSE: listIconsImages.find((item) => item.key === 'close') as IconsImages,
        COLLAPSE: listIconsImages.find((item) => item.key === 'collapse') as IconsImages,
        DEFAULT_USER_COMMENT: listIconsImages.find((item) => item.key === 'default_user_comment') as IconsImages,
        DELETE: listIconsImages.find((item) => item.key === 'delete') as IconsImages,
        DELETE_DARK: listIconsImages.find((item) => item.key === 'delete_dark') as IconsImages,
        DROPDOWN: listIconsImages.find((item) => item.key === 'dropdown') as IconsImages,
        EDIT: listIconsImages.find((item) => item.key === 'edit') as IconsImages,
        EVENTS: listIconsImages.find((item) => item.key === 'events') as IconsImages,
        EXPAND: listIconsImages.find((item) => item.key === 'expand') as IconsImages,
        FILTER: listIconsImages.find((item) => item.key === 'filter') as IconsImages,
        FILTER_CLEAN: listIconsImages.find((item) => item.key === 'filter_clean') as IconsImages,
        GPS: listIconsImages.find((item) => item.key === 'gps') as IconsImages,
        INSTAGRAM: listIconsImages.find((item) => item.key === 'instagram') as IconsImages,
        MAP_FILTER: listIconsImages.find((item) => item.key === 'map_filter') as IconsImages,
        MAP_FILTER_ACTIVE: listIconsImages.find((item) => item.key === 'map_filter_active') as IconsImages,
        MAPPET_CLEAN: listIconsImages.find((item) => item.key === 'mappet_clean') as IconsImages,
        OTHER: listIconsImages.find((item) => item.key === 'other') as IconsImages,
        PROFILE: listIconsImages.find((item) => item.key === 'profile') as IconsImages,
        PROFILE_ACTIVE: listIconsImages.find((item) => item.key === 'profile_active') as IconsImages,
        REPORTS: listIconsImages.find((item) => item.key === 'reports') as IconsImages,
        REPORTS_ACTIVE: listIconsImages.find((item) => item.key === 'reports_active') as IconsImages,
        SALOON: listIconsImages.find((item) => item.key === 'saloon') as IconsImages,
        SEARCH: listIconsImages.find((item) => item.key === 'search') as IconsImages,
        SELECTED_CATEGORY: listIconsImages.find((item) => item.key === 'selected_category') as IconsImages,
        SERVICES: listIconsImages.find((item) => item.key === 'services') as IconsImages,
        SERVICES_ACTIVE: listIconsImages.find((item) => item.key === 'services_active') as IconsImages,
        SPA: listIconsImages.find((item) => item.key === 'spa') as IconsImages,
        STAR: listIconsImages.find((item) => item.key === 'star') as IconsImages,
        STAR_ACTIVE: listIconsImages.find((item) => item.key === 'star_active') as IconsImages,
        TASK: listIconsImages.find((item) => item.key === 'task') as IconsImages,
        CLOUD_AND_MOON: listIconsImages.find((item) => item.key === 'cloud_and_moon') as IconsImages,
        SUN_UP: listIconsImages.find((item) => item.key === 'sun_up') as IconsImages,
        SUN: listIconsImages.find((item) => item.key === 'sun') as IconsImages,
        SUNSET: listIconsImages.find((item) => item.key === 'sunset') as IconsImages,
        MOON: listIconsImages.find((item) => item.key === 'moon') as IconsImages,
        ADD_IMAGE: listIconsImages.find((item) => item.key === 'add_image') as IconsImages,
        BACKGROUND_IMAGE_1: listIconsImages.find((item) => item.key === 'back_ground_image_1') as IconsImages,
        BACKGROUND_IMAGE_2: listIconsImages.find((item) => item.key === 'back_ground_image_2') as IconsImages,
        BACKGROUND_IMAGE_3: listIconsImages.find((item) => item.key === 'back_ground_image_3') as IconsImages,
        EMPTY_MAPPET: listIconsImages.find((item) => item.key === 'empty_mappet') as IconsImages,
        STRECH_BG_CARDS: listIconsImages.find((item) => item.key === 'strech_bg_cards') as IconsImages,
        NOT_FOUND: listIconsImages.find((item) => item.key === 'not_found') as IconsImages,
        PROFILE_EMPTY: listIconsImages.find((item) => item.key === 'profile_empty') as IconsImages
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIconsImages])

  return (
    <MappetContext.Provider
      value={{
        cellPhone,
        iconsImages,
        setIconsImages,
        listIconsImages,
        setListIconsImages,
        modalVisible,
        setModalVisible,
        showGetCoord,
        setShowGetCoord,
        showOtherUser,
        setShowOtherUser,
        showPlaceMap,
        setShowPlaceMap,
        modalTermsConditions,
        setModalTermsConditions,
        modalAddPet,
        setModalAddPet,
        googleUser,
        setGoogleUser,
        user,
        setUser,
        logoutUser,
        petType,
        setPetType,
        petBreed,
        setPetBreed,
        pet,
        setPet,
        petList,
        setPetList,
        navbarCollapseClass,
        setNavbarCollapseClass,
        isRegistering,
        setIsRegistering,
        showReportDetails,
        setShowReportDetails,
        showDeleteReportModal,
        setShowDeleteReportModal,
        userLocation,
        setUserLocation,
        currentIndex,
        setCurrentIndex,
        placesCloser,
        setPlaceCloser,
        typeView,
        setTypeView,
        showFinishedSurveyModal,
        setShowFinishedSurveyModal,
        selectPage,
        setSelectPage,
        updateReportId,
        setUpdateReportId,
        petReports,
        setPetReports,
        petData,
        setPetData,
        placeDetailsService,
        setPlaceDetailsService,
        showCommentPlaceModal,
        setShowCommentPlaceModal,
        routeCoords,
        setRouteCoords,
        googleRouteResponse,
        setGoogleRouteResponse,
        userInteraction,
        setUserInteraction
      }}
    >
      {children}
    </MappetContext.Provider>
  )
}
