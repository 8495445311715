import React, { RefObject, useEffect, useRef, useState } from 'react'

import { Image } from '../../../types'

type Props = {
  listImg: Image[]
}

const AccountSlideImg = ({ listImg }: Props) => {
  const imgRef = useRef<RefObject<HTMLUListElement>>()
  const [current, setCurrent] = useState(0)

  function hanldeDirection(index: number) {
    setCurrent(index)
  }

  useEffect(() => {
    const NodeList = document.getElementById('list-img') as HTMLUListElement
    const currentNode = NodeList?.querySelectorAll('li>img')[current]
    currentNode &&
      currentNode.scrollIntoView({
        behavior: 'smooth'
      })
  }, [current])
  return (
    <div className='slide-main-container'>
      <div className='list-container-img-slide'>
        <div className='container-images'>
          <ul className='list-images-details' id='list-img' ref={imgRef as unknown as RefObject<HTMLUListElement>}>
            {listImg.map((image, index) => (
              <li key={image.id}>
                <img src={image.image} alt={image.image} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='dot-img-container'>
        {listImg.map((_, index) => (
          <span key={index} className='dot-item' onClick={() => hanldeDirection(index)}></span>
        ))}
      </div>
    </div>
  )
}

export default AccountSlideImg
