import '../Reports/ReportsStyles.css'
import '../../../assets/css/styles.css'

import { DocumentsPets, Image, Pet, UserData } from '../../../types'
import { useContext, useEffect, useRef, useState } from 'react'

import AccountSlideImg from './AccountSlideImg'
import { MappetContext } from '../../../utils'
import ViewerImages from '../ViewerImages/ViewerImages'
import { getCodeInstagram } from '../../../services/getCodeInstagram'
import { getListDocPets } from '../../../services/getListDocPets'
import { getListPets } from '../../../services/getListPets'
import ic_add_img from '../../../assets/images/mappet/img_add_image.png'
import ic_back from '../../../assets/images/mappet/ic_back.png'
import ic_edit from '../../../assets/images/mappet/ic_edit.png'
import { imgMappet } from '../../../assets/images/mappet'
import { saveDocPets } from '../../../services/saveDocPets'
import toast from 'react-hot-toast'
import { useGetToken } from 'hooks/useGetToken'

enum TYPE_DOC {
  CERTIFICADOS = 'CERTIFICATE',
  ID_DOC = 'ID_DOCUMENT',
  HISTORIA_CLINICA = 'CLINICAL_HISTORY'
}

type Props = {
  setShowDocPets: React.Dispatch<React.SetStateAction<boolean>>
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>
  docPets: Pet | undefined
  setDocPets: React.Dispatch<React.SetStateAction<Pet | undefined>>
  showEdit: boolean
}

const AccountDocPets = ({ docPets, setShowDocPets, setShowEdit, setDocPets, showEdit }: Props) => {
  const [listDocs, setListDocs] = useState<DocumentsPets[]>([])
  const [titles, setTitles] = useState<string[]>()
  const [showViewer, setShowViewer] = useState(false)
  const [currentImages, setCurrentImages] = useState<number>()
  const [reportImages, setReportImages] = useState<Image[]>()
  const { petList, user, setPetList } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const titleDoc = useRef<string>()

  const { iconsImages } = useContext(MappetContext)
  useEffect(() => {
    if (token) {
      getListPets(user as UserData, token).then((res) => setPetList(res.results))
      let listImages = petList?.find((pet) => pet.id === docPets?.id)
      setReportImages(
        listImages!.images.map((image: Image) => {
          return image
        })
      )
      console.log('entro al docs')
      setDocPets(listImages as Pet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReportImages, setShowEdit, setPetList, user, docPets?.images, setDocPets, showEdit, token])

  const resCode = window.location.href.includes('?') && window.location.href.split('?')[1].split('=')[1]
  const code = resCode && resCode.substring(0, resCode.length - 2)

  function connectInstagram() {
    let id_clent = process.env.REACT_APP_CLIENT_INSTAGRAM
    let url_redirect = process.env.REACT_APP_REDIRECT_URL
    window.open(`https://api.instagram.com/oauth/authorize?client_id=${id_clent}&redirect_uri=${url_redirect}&scope=user_profile,user_media&response_type=code`, '_self')
  }

  function setChangeIndex(index: number, typeDoc: string) {
    setCurrentImages(index)
    setShowViewer(true)
    titleDoc.current = typeDoc
  }
  function getListDocs(e: React.ChangeEvent<HTMLInputElement>) {
    const list = e.currentTarget.files?.item(0)
    let name = e.currentTarget.name

    if (name === 'identificacion') {
      saveDocPets(docPets?.id as string, list, TYPE_DOC.ID_DOC, token).then((res) => {
        getListDocPets(docPets?.id as string, token).then((docs) => {
          const newArray = docs.results
          setListDocs(newArray)
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>DOCUMENTOS</p>
              <p>Documentos cargados con exito</p>
            </div>,
            {
              position: 'top-right',
              style: {
                backgroundColor: 'red',
                color: '#ffffff'
              }
            }
          )
        })
      })
    }
    if (name === 'certificados') {
      saveDocPets(docPets?.id as string, list, TYPE_DOC.CERTIFICADOS, token).then((res) => {
        getListDocPets(docPets?.id as string, token).then((docs) => {
          const newArray = docs.results
          setListDocs(newArray)
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>DOCUMENTOS</p>
              <p>Documentos cargados con exito</p>
            </div>,
            {
              position: 'top-right',
              style: {
                backgroundColor: 'red',
                color: '#ffffff'
              }
            }
          )
        })
      })
    }
    if (name === 'historias') {
      saveDocPets(docPets?.id as string, list, TYPE_DOC.HISTORIA_CLINICA, token).then((res) => {
        getListDocPets(docPets?.id as string, token).then((docs) => {
          const newArray = docs.results
          setListDocs(newArray)
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>DOCUMENTOS</p>
              <p>Documentos cargados con exito</p>
            </div>,
            {
              position: 'top-right',
              style: {
                backgroundColor: 'red',
                color: '#ffffff'
              }
            }
          )
        })
      })
    }
  }
  useEffect(() => {
    if (token) {
      getListDocPets(docPets?.id as string, token).then((docs) => {
        const newArray = docs.results
        setListDocs(newArray)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListDocs, token])

  useEffect(() => {
    if (listDocs) {
      const listTitle = listDocs && listDocs.map((doc: DocumentsPets) => doc.document_type)
      setTitles([...new Set<string>(listTitle)])
    }
  }, [listDocs])

  useEffect(() => {
    if (code && token) {
      getCodeInstagram(code as string, docPets?.id as number, token).then((response) => console.log(response))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, docPets?.id, token])
  return (
    <div className='details-container-docs'>
      <button className='back-button-docs' onClick={() => setShowDocPets(false)}>
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt={iconsImages.BACK.key} /> : <img src={ic_back} alt='back.' />}
      </button>
      <div className='info-pet-doc-container'>
        <div className='main-container-images'>
          <div className='button-edit-doc' onClick={() => setShowEdit(true)}>
            {iconsImages?.EDIT ? <img src={iconsImages.EDIT.images.sm} alt={iconsImages.EDIT.key} /> : <img src={ic_edit} alt='editar mascota.' />}
          </div>
          {docPets!.images.length > 1 && reportImages ? (
            <AccountSlideImg listImg={reportImages as Image[]} />
          ) : (
            <div className='details-imgs-doc'>
              {docPets?.images.length === 0 ? (
                <img className='report-img' src={iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : imgMappet} alt='report-img1' />
              ) : (
                docPets?.images.length === 1 && <img className='report-img' src={docPets?.images[0].image} alt='report-img2' />
              )}
            </div>
          )}
        </div>
        <div className='info-pet-doc'>
          <h2>{docPets?.name}</h2>
          <p>
            {docPets?.breed?.breed_name}({docPets?.gender})
          </p>
          {docPets?.instagram_username ? (
            <div className='btn-group-doc'>
              <p style={{ color: 'red', fontSize: '1.4em' }}>{docPets.instagram_username}</p>
              <span className='refresh' onClick={() => connectInstagram()}>
                <i className='fa-solid fa-rotate'></i>
              </span>
            </div>
          ) : (
            <div className='btn-group-doc'>
              <button className='btn btn-red' onClick={() => connectInstagram()}>
                Conectar con Instagram
              </button>
            </div>
          )}
        </div>
      </div>

      <hr />
      <div className='button-doc'>
        <h2>Documentos de Identificación</h2>
        <p>(Carnets, Tarjetas, IDs, etc)</p>
      </div>
      <input type='file' id='identificacion' name='identificacion' hidden onChange={getListDocs} accept='image/jpeg, image/jpg, image/png, image/gif, image/bmp' />
      {listDocs === undefined || !titles?.includes(TYPE_DOC.ID_DOC) ? (
        <div className='group-buttons-doc'>
          <label htmlFor='identificacion' className='btn btn-add-doc'>
            Agregar
          </label>
        </div>
      ) : (
        <div className='container-images-docs'>
          <label className='item-images' htmlFor='identificacion'>
            {iconsImages?.ADD_IMAGE ? <img src={iconsImages.ADD_IMAGE.images.sm} alt={iconsImages.ADD_IMAGE.key} /> : <img src={ic_add_img} alt='add images' />}
          </label>

          {listDocs
            .filter((doc) => doc.document_type === TYPE_DOC.ID_DOC)
            .map((doc: DocumentsPets, index: number) => (
              <div className='item-images' key={`${doc.name}-${index}`} onClick={() => setChangeIndex(doc.id, TYPE_DOC.ID_DOC)}>
                <img src={doc.image} alt={`${doc.name}-${index}`} />
              </div>
            ))}
        </div>
      )}

      <hr />
      <div className='button-doc'>
        <h2>Certificados</h2>
        <p>(Apoyo emocional, Médico, Chips, etc)</p>
      </div>
      <input type='file' id='certificados' name='certificados' hidden onChange={getListDocs} accept='image/jpeg, image/jpg, image/png, image/gif, image/bmp' />
      {listDocs === undefined || !titles?.includes(TYPE_DOC.CERTIFICADOS) ? (
        <div className='group-buttons-doc'>
          <label htmlFor='certificados' className='btn btn-add-doc'>
            Agregar
          </label>
        </div>
      ) : (
        <div className='container-images-docs'>
          <label className='item-images' htmlFor='certificados'>
            {iconsImages?.ADD_IMAGE ? <img src={iconsImages.ADD_IMAGE.images.sm} alt={iconsImages.ADD_IMAGE.key} /> : <img src={ic_add_img} alt='add images' />}
          </label>

          {listDocs
            .filter((doc) => doc.document_type === TYPE_DOC.CERTIFICADOS)
            .map((cert: DocumentsPets, index: number) => (
              <div className='item-images' key={`${cert.name}-${index}`} onClick={() => setChangeIndex(cert.id, TYPE_DOC.CERTIFICADOS)}>
                <img src={cert.image} alt={`${cert.name}-${index}`} />
              </div>
            ))}
        </div>
      )}

      <hr />

      <div className='button-doc'>
        <h2>Anexos a Historia Clinica</h2>
        <p>(Examenes, Radiografias, Laboratorios, etc)</p>
      </div>
      <input type='file' id='historias' name='historias' hidden onChange={getListDocs} accept='image/jpeg, image/jpg, image/png, image/gif, image/bmp' />
      {listDocs === undefined || !titles?.includes(TYPE_DOC.HISTORIA_CLINICA) ? (
        <div className='group-buttons-doc'>
          <label htmlFor='historias' className='btn btn-add-doc'>
            Agregar
          </label>
        </div>
      ) : (
        <div className='container-images-docs'>
          <label htmlFor='historias' className='item-images'>
            {iconsImages?.ADD_IMAGE ? <img src={iconsImages.ADD_IMAGE.images.sm} alt={iconsImages.ADD_IMAGE.key} /> : <img src={ic_add_img} alt='add images' />}
          </label>

          {listDocs
            .filter((doc) => doc.document_type === TYPE_DOC.HISTORIA_CLINICA)
            .map((med: DocumentsPets, index: number) => (
              <div className='item-images' key={`${med.name}-${index}`} onClick={() => setChangeIndex(med.id, TYPE_DOC.HISTORIA_CLINICA)}>
                <img src={med.image} alt={`${med.name}-${index}`} />
              </div>
            ))}
        </div>
      )}
      {showViewer && (
        <ViewerImages
          listDocs={listDocs}
          setShowViewer={setShowViewer}
          petName={docPets as Pet}
          currentImages={currentImages}
          setListDocs={setListDocs}
          setCurrentImages={setCurrentImages}
          titleDoc={titleDoc}
        />
      )}
    </div>
  )
}

export default AccountDocPets
