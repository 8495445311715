import './ReportsStyles.css'
import '../../../assets/css/styles.css'

import { Image, Place } from '../../../types'
import { useContext, useEffect } from 'react'

import Background1 from '../../../assets/images/backgrounds/img-element2.png'
import Comment from './Comment'
import { DetailSlider } from '..'
import { MappetContext } from '../../../utils'
import { ReportHook } from '../../../hooks/reportHook'
import { getUserPlaceComments } from '../../../services'
import { imgMappet } from '../../../assets/images/mappet'
import { useGetToken } from 'hooks/useGetToken'

interface Report {
  reportData: Place
}

const PlaceReportDetail = ({ reportData }: Report) => {
  const { setShowReportDetails, showReportDetails, iconsImages } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const { placeComments, setPlaceComments } = ReportHook()

  let reportImages: Array<string> = reportData.place_images.map((image: Image) => {
    return image.image
  })

  useEffect(() => {
    getUserPlaceComments(reportData.id, token)
      .then((res) => setPlaceComments(res.results))
      .catch((err) => console.log(err))

    setPlaceComments([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReportDetails])

  return (
    <div className='details-container'>
      <button className='back-button' onClick={() => setShowReportDetails(null)}>
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt='back images' /> : <i className='fa-solid fa-arrow-left'></i>}
      </button>
      <div className='details-imgs'>
        {!reportData.place_images ? (
          <img className='report-img' src={iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : imgMappet} alt='report-img1' />
        ) : reportData.place_images.length === 1 ? (
          <img className='report-img' src={reportData.place_images[0].image} alt='report-img2' />
        ) : (
          <DetailSlider slides={reportImages} />
        )}
        {iconsImages?.BACKGROUND_IMAGE_2 ? <img className='background-img' src={iconsImages.BACKGROUND_IMAGE_2.images.md} alt='' /> : <img className='background-img' src={Background1} alt='' />}
      </div>
      <h2>{reportData.name}</h2>
      {/* <p className='place-categories'>{reportData.place_id.categories.join(' - ')}</p> */}
      <hr />
      <div className='comments-container'>
        {placeComments.length > 0 ? (
          placeComments.map((placeComment, index) => {
            return <Comment comment={placeComment} key={index} />
          })
        ) : (
          <p className='loading-message'>Cargando comentarios...</p>
        )}
      </div>
    </div>
  )
}

export default PlaceReportDetail
