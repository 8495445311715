import './DetailSliderStyles.css'

import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { MappetContext } from '../../../utils'

interface Props {
  slides: Array<string>
}

function DetailSlider({ slides }: Props) {
  const slideshow = useRef<HTMLDivElement>(null)
  const { currentIndex, setCurrentIndex } = useContext(MappetContext)

  const goToSlide = useCallback(
    (index: number) => {
      if (slideshow.current) {
        const slideWidth = slideshow.current.offsetWidth
        slideshow.current.style.transition = `500ms ease-in-out all`
        slideshow.current.style.transform = `translateX(-${index * slideWidth}px)`
        setCurrentIndex(index)
      }
    },
    [setCurrentIndex]
  )

  useEffect(() => goToSlide(currentIndex), [currentIndex, slides, goToSlide])

  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % slides.length
    goToSlide(nextIndex)
  }

  /*const goToPreviousSlide = () => {
    const previousIndex = (currentIndex + slides.length - 1) % slides.length
    goToSlide(previousIndex)
  }*/

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 5000)
    return () => clearInterval(intervalId)
  })

  return (
    <div className='main-slides-container'>
      <div className='slides-container' ref={slideshow}>
        {slides.map((slide, index) => (
          <div key={index} className='slide report-img'>
            <div className='container-slide-img'>
              <img className='slide-img' src={slide} alt='report' />
            </div>
          </div>
        ))}
      </div>
      <div className='slider-dots'>
        {slides.map((slide, index) => {
          return <i key={index} className={`fa-${currentIndex === index ? 'solid' : 'regular'}  regular fa-circle slider-dot`} onClick={() => setCurrentIndex(index)}></i>
        })}
      </div>
    </div>
  )
}

export default DetailSlider
