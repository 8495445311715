export function getDistancePlaceCloser(lat1: number, lon1: number, lat2: number, lon2: number){
    
    let difLat = (gradosARadianes(lat1) - gradosARadianes(lat2))
    let difLon = (gradosARadianes(lon1) - gradosARadianes(lon2))
    let distanceEarth = 6378.137

    let a = Math.pow(Math.sin(difLat / 2.0), 2) + Math.cos(gradosARadianes(lat1)) * Math.cos(gradosARadianes(lat2)) * Math.pow(Math.sin(difLon / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (distanceEarth * c).toFixed(2);
}

const gradosARadianes = (grados: number) => {
    return grados * Math.PI / 180;
};