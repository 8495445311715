import './FooterStyles.css'
import '../../../assets/css/styles.css'

import React from 'react'
import footerImage from '../../../assets/images/mappet/img-element1.png'

const Footer = () => {
  return (
    <div className='footer'>
      <img src={footerImage} alt='imagen del footer' />
    </div>
  )
}

export default Footer
