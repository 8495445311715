import { GooglePlace, ListImages, PetFriendly } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import ModalImages from './ModalImages'
import ModalRegisterSuccess from './ModalRegisterSuccess'
import createStartRate from '../../../utils/createStartRate'
import { getErrorsImages } from '../../../utils/getErrorsImages'
import { useForm } from 'react-hook-form'
import { useFormReportPetFriendly } from '../../../hooks/useFormReportPetFriendly'

interface CommentPlaceModalProps {
  placeData: GooglePlace
}

const CommentPlaceModal = ({ placeData }: CommentPlaceModalProps) => {
  const { setShowCommentPlaceModal, iconsImages } = useContext(MappetContext)
  const [addr, setAddr] = useState<any>({ name: '' })
  const [rate, setRate] = useState<number>(0)
  const [data, setData] = useState<PetFriendly>()
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [cantImage, setCantImage] = useState<ListImages>({ images: [], imagesDel: [] })
  const [isValidated, setIsValidated] = useState<boolean>(false)
  const { sentData } = useFormReportPetFriendly(addr, data, cantImage)

  const handlerChangeCantImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCantImage({
      ...cantImage,
      images: [e.target.files![0]]
    })
  }
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm<PetFriendly>()

  const onSubmit = handleSubmit((values) => {
    if (!error) {
      setData(values)
      sentData(values, addr, cantImage)
      reset()
      setAddr({ name: '' })
      setRate(0)
      setIsValidated(!isValidated)
      setCantImage({ images: [], imagesDel: [] })
    }
  })

  const handleStarts = (e: React.MouseEvent<HTMLInputElement>) => {
    setRate(Number(e.currentTarget.value))
  }

  useEffect(() => {
    if (iconsImages?.STAR && iconsImages.STAR_ACTIVE) {
      createStartRate(rate, iconsImages.STAR.images.sm, iconsImages.STAR_ACTIVE.images.sm)
    }
  }, [iconsImages?.STAR, iconsImages?.STAR_ACTIVE, rate])

  useEffect(() => {
    setValue('address', addr.name, { shouldValidate: isValidated as boolean })
  }, [addr.name, setValue, isValidated, placeData.address])

  useEffect(() => {
    setAddr({ name: placeData.address, place_id: placeData.google_place_id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal>
      <div className='container-submitted-modal container-comment-place-modal'>
        <h2>¿Visitaste este lugar?</h2>
        <p>Califica y deja una opinión del lugar (opcional):</p>
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <input type='text' {...register('address')} id='address' hidden />
          <div className='form-report'>
            <label htmlFor='opinion'>Opinión</label>
            <input
              type='text'
              {...register('comment', {
                required: {
                  value: true,
                  message: 'Por favor de una opinión del lugar'
                }
              })}
              id='opinion'
              placeholder='¿Te gustó? ¿Es fácil de encontrar?'
            />
            {errors.comment && <span className='errors'>{errors.comment.message}</span>}
          </div>
          {errors.rating && <span className='errors'>{errors.rating.message}</span>}
          <div className='rating'>
            <div className='start-container'>
              {Array(5)
                .fill('')
                .map((_, index) => (
                  <p key={index}>
                    <label htmlFor={`starts-${index + 1}`} id={`start-${index + 1}`}></label>
                    <input
                      type='radio'
                      {...register('rating', {
                        required: {
                          value: true,
                          message: 'Por favor de una calificación'
                        }
                      })}
                      onClick={(e) => handleStarts(e)}
                      id={`starts-${index + 1}`}
                      value={index + 1}
                    />
                  </p>
                ))}
            </div>
            {!rate && <p>Sin calificación</p>}
          </div>
          <div className='form-buttom-friendly'>
            <label htmlFor='img-report-friendly' className='btn btn-photo'>
              Cargar fotografías ({cantImage.images.length === 0 ? 0 : cantImage.images.length})
            </label>
            <input
              type='file'
              id='img-report-friendly'
              hidden
              {...register('place_image', {
                onChange: (e) => {
                  handlerChangeCantImages(e)
                  setError(getErrorsImages(e.target.files[0].name))
                }
              })}
              accept='image/png, image/jpeg, image/gif, image/jpg, image/bmp'
            />
          </div>
          {cantImage.images.length > 0 && (
            <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setImageModal(true)}>
              Ver fotos({cantImage.images.length})
            </p>
          )}
          {error && <span className='errors'>{error}</span>}
          <div className='details-buttons'>
            <button className='btn btn-cancel' onClick={() => setShowCommentPlaceModal(null)}>
              Cancelar
            </button>
            <input type='submit' className='btn btn-submit' style={{ marginLeft: '1em' }} value='Enviar Reporte' />
          </div>
        </form>
      </div>
      <ModalRegisterSuccess title='Reporte Registrado Exitosamente' description='Los datos de tu reporte se guardaron correctamente' />
      {imageModal && <ModalImages setImageModal={setImageModal} cantImages={cantImage} setCantImages={setCantImage} setError={setError} />}
    </Modal>
  )
}

export default CommentPlaceModal
