import { DirectionsRenderer, DirectionsService, GoogleMap, Marker } from '@react-google-maps/api'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { GooglePlace } from '../../../types'
import { MappetContext } from '../../../utils'
import React from 'react'
import { getDistancePlaceCloser } from '../../../utils/getDistancePlaceCloser'
import { getListPlaces } from '../../../services/getListPlacesCloser'
import { useGetToken } from 'hooks/useGetToken'

type Props = {
  nameFilters: string
}
const Map = ({ nameFilters }: Props) => {
  const [showSearch, setshowSearch] = useState(false)
  const {
    googleRouteResponse,
    setGoogleRouteResponse,
    userLocation,
    placeDetailsService,
    setPlaceDetailsService,
    routeCoords,
    setRouteCoords,
    placesCloser,
    setUserLocation,
    setPlaceCloser,
    setShowPlaceMap,
    setShowOtherUser,
    showOtherUser
  } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const centerMain = useMemo(() => userLocation, [userLocation])
  const mapRef = useRef<google.maps.Map | null>(null)
  const [distance, setDistance] = useState(0)

  const directionsCallback = (res: any) => {
    if (res !== null) {
      if (res.status === 'OK') {
        setGoogleRouteResponse(res)
      } else {
        console.log('Directions request failed:', res.status)
      }
    }
  }
  const handleMapCenter = (latlng: { lat: number; lng: number }) => {
    const map = mapRef.current
    if (map) {
      const lat = latlng.lat
      const lng = latlng.lng
      setUserLocation({ lat, lng })
      setDistance(parseFloat(getDistancePlaceCloser(centerMain!.lat as number, centerMain!.lng as number, lat, lng)))
      if (distance > 0.5) {
        setDistance(0)
        setshowSearch(true)
      }
    }
  }

  function newGetDistancePlaceCloser() {
    getListPlaces([centerMain?.lat as number, centerMain?.lng as number], nameFilters, '', token).then((res) => {
      setPlaceCloser(res.results)
    })
  }

  function updateService(place: GooglePlace) {
    setPlaceDetailsService(place)
    setRouteCoords(null)
    setGoogleRouteResponse(null)
    setShowPlaceMap(true)
    setShowOtherUser({ ...showOtherUser, isVisible: false })
  }
  useEffect(() => {
    if (distance > 0.5) {
      setshowSearch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance])
  return (
    <div className={`services-view ${placeDetailsService ? 'showing-details' : ''}`}>
      {showSearch && (
        <div className='container-btn-search'>
          <button
            className='btn btn-search'
            onClick={() => {
              setshowSearch(false)
              newGetDistancePlaceCloser()
            }}
          >
            Buscar
          </button>
        </div>
      )}
      <GoogleMap
        zoom={15}
        center={centerMain}
        mapContainerClassName='map-container'
        onClick={(e) => handleMapCenter(e.latLng?.toJSON() as { lat: number; lng: number })}
        onLoad={(map) => {
          if (map as Partial<GoogleMap | null>) {
            mapRef.current = map
          }
        }}
        id='mapMain'
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit.station.airport',
              stylers: [{ visibility: 'off' }]
            }
          ]
        }}
      >
        <Marker
          position={centerMain!}
          icon={{
            path: 'M6,0.031 C3.283,0.031 1.08,2.15 1.08,4.764 C1.08,5.439 1.23,6.081 1.494,6.662 L6.084,13.983 L10.506,6.662 C10.771,6.081 10.918,5.438 10.918,4.764 C10.918,2.15 8.717,0.031 6,0.031 L6,0.031 Z M6,8.121 C4.295,8.121 2.915,6.734 2.915,5.023 C2.915,3.314 4.295,1.926 6,1.926 C7.703,1.926 9.086,3.314 9.086,5.023 C9.086,6.734 7.703,8.121 6,8.121 L6,8.121 Z M7.977,4.983 C7.977,6.08 7.091,6.968 5.999,6.968 C4.908,6.968 4.022,6.079 4.022,4.983 C4.022,3.888 4.908,3 5.999,3 C7.091,3 7.977,3.889 7.977,4.983 L7.977,4.983 M3.29899999,11.8232422 C1.58199999,12.1872422 0.869751004,12.6649999 0.869751004,13.2019999 C0.869751004,13.9709999 2.7,15.031 5.985,15.031 C9.27,15.031 11.1014406,13.9709999 11.1014406,13.2019999 C11.1014406,12.6669999 10.393,12.2032422 8.70000002,11.8232422 L8.7,11.003 C10.565,11.369 11.954,12.104 11.954,13.202 C11.954,14.786 8.87799986,15.9729004 5.98499986,15.9729004 C3.09199986,15.9729004 0.0160000001,14.786 0.0160000001,13.202 C0.0160000001,12.102 1.414,11.353 3.289,11 L3.29899999,11.8232422 Z',
            scale: 2.2,
            strokeColor: '#2606b4'
          }}
        />
        {placesCloser &&
          placesCloser.map((place: GooglePlace, index: number) => (
            <div key={place.uuid}>
              {(place.distance = getDistancePlaceCloser(centerMain?.lat as number, centerMain?.lng as number, place?.latitude, place?.longitude) as unknown as number)}
              <Marker
                position={{
                  lat: place.latitude,
                  lng: place.longitude
                }}
                key={index}
                onClick={() => updateService(place)}
              />
            </div>
          ))}
        {routeCoords && (
          <DirectionsService
            // Proporciona las opciones de solicitud de dirección
            options={{
              destination: routeCoords.to,
              origin: centerMain!,
              travelMode: google.maps.TravelMode.DRIVING // Puedes usar 'DRIVING', 'WALKING', 'BICYCLING' o 'TRANSIT'
            }}
            // Proporciona la función de devolución de llamada
            callback={(res) => {
              if (!googleRouteResponse) {
                directionsCallback(res)
              }
            }}
          />
        )}
        {googleRouteResponse && (
          <DirectionsRenderer
            // Proporciona la respuesta de la solicitud de dirección
            options={{
              directions: googleRouteResponse
            }}
          />
        )}
      </GoogleMap>
    </div>
  )
}
export default Map
