import React, { useContext, useEffect, useRef, useState } from 'react'
import { User, UserData } from '../../../types'

import CryptoJS from 'crypto-js'
import { MappetContext } from '../../../utils'
import deleteImageProfile from '../../../services/deleteImageProfile'
import ic_add from '../../../assets/images/mappet/ic_add.png'
import ic_delete from '../../../assets/images/mappet/ic_delete.png'
import toast from 'react-hot-toast'
import { unlinkInstagram } from '../../../services/unlinkInstagram'
import { updateProfileData } from '../../../services/updateProfileData'
import { uploadProfilePicture } from '../../../services'
import { useForm } from 'react-hook-form'
import { useGetToken } from 'hooks/useGetToken'
import { userExists } from '../../../services/userExists'

type AccountActions = 'pets' | 'notifications' | 'edit' | 'about' | 'policy' | 'logout'
interface AccountPolicyProps {
  setSelectedAction: React.Dispatch<React.SetStateAction<AccountActions>>
}
const AccoundEditPerfil = ({ setSelectedAction }: AccountPolicyProps) => {
  const { user, setUser, iconsImages, googleUser } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const [, setErrorPhone] = useState<string>('')
  const reference = useRef<HTMLInputElement | null>(null)
  const {
    register,
    //reset,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<User>()

  const onSubmit = handleSubmit((values) => {
    sendDataProfile(values)
  })

  function handlerUnlinkInstagram() {
    unlinkInstagram(null, token).then((res) => {
      setUser({ ...user, instagram_username: res.instagram_username })
    })
  }

  async function sendDataProfile(values: User) {
    await updateProfileData(values, token)
      .then((res) => {
        setUser({
          ...user,
          name: res.name,
          phone: res.phone,
          email: res.email
        })
        let cipherUserData = localStorage.getItem('user')
        const bytesUserData = CryptoJS.AES.decrypt(cipherUserData!, process.env.REACT_APP_ENC_SECRET_KEY!)
        const userDataString = bytesUserData.toString(CryptoJS.enc.Utf8)
        let newUser = JSON.parse(userDataString)
        newUser.name = res.name
        newUser.phone = res.phone
        newUser.email = res.email
        cipherUserData = CryptoJS.AES.encrypt(JSON.stringify(newUser), process.env.REACT_APP_ENC_SECRET_KEY!).toString()
        localStorage.setItem('user', cipherUserData)
        toast(
          <div>
            <p style={{ fontWeight: 'bold' }}>EDITAR PERFIL</p>
            <p>Datos editados con exito</p>
          </div>,
          {
            position: 'top-right',
            style: {
              backgroundColor: 'red',
              color: '#ffffff'
            }
          }
        )
      })
      .catch((err) => console.log(err))
  }

  async function deleteImgProfile() {
    await deleteImageProfile(token)
      .then((res) => {
        if (res.ok) {
          setUser({
            ...user,
            profile_picture: null
          })
        }
      })
      .catch((err) => console.log(err))
  }
  function getInputNumber() {
    document.getElementById('number')?.addEventListener('keydown', function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault()
      }
    })
    setErrorPhone('')
  }

  useEffect(() => {
    if (user) {
      user.phone = user.phone?.replace('+57', '')
      setValue('phone', user.phone as string)
      setValue('email', user.email as string)
      setValue('name', user.name as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (googleUser?.sub) {
      userExists(googleUser?.sub as string).then((user_item: UserData) => {
        setUser({
          ...user,
          instagram_username: user_item.instagram_username
        })
        let cipherUserData = localStorage.getItem('user')
        const bytesUserData = CryptoJS.AES.decrypt(cipherUserData!, process.env.REACT_APP_ENC_SECRET_KEY!)
        const userDataString = bytesUserData.toString(CryptoJS.enc.Utf8)
        let newUser = JSON.parse(userDataString)
        newUser.instagram_username = user_item.instagram_username
        cipherUserData = CryptoJS.AES.encrypt(JSON.stringify(newUser), process.env.REACT_APP_ENC_SECRET_KEY!).toString()
        localStorage.setItem('user', cipherUserData)
      })
    }
  }, [googleUser, setUser, user])
  return (
    <div className='account-editprofail-container'>
      <i onClick={() => setSelectedAction('pets')} className='back-button fa-solid fa-arrow-left'></i>
      <h2 className='title_modal'>Editar mis datos</h2>
      <div className='image-edit'>
        {user?.profile_picture ? (
          <>
            <img src={user.profile_picture as string} alt={user.name} />
            <button className='delete-photo-profile' type='button' onClick={deleteImgProfile}>
              {iconsImages?.DELETE ? <img src={iconsImages.DELETE.images.sm} alt={iconsImages.DELETE.key} /> : <img src={ic_delete} alt='borrar foto de perfil' />}
            </button>
          </>
        ) : (
          <>
            <label className='add-photo-profile' htmlFor='add-photo-profile'>
              {iconsImages?.ADD ? <img src={iconsImages.ADD.images.sm} alt={iconsImages.ADD.key} /> : <img src={ic_add} alt='agregar foto de perfil' />}
            </label>
            <input
              type='file'
              id='add-photo-profile'
              hidden
              onChange={(e) => {
                uploadProfilePicture(reference, user as Partial<UserData>)
                  .then((res) => {
                    setUser({ ...user, profile_picture: res.profile_picture.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, process.env.REACT_APP_BACKEND_URL) })
                  })
                  .catch((err) => console.error(err))
              }}
              ref={reference}
            />
          </>
        )}
      </div>
      <hr />
      <div>
        <form className='form' onSubmit={onSubmit}>
          <div className='form-data-edit'>
            <div className='form-group'>
              <label htmlFor='name'>Nombre Completo:</label>
              <input
                type='text'
                id='name'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'el Nombre es Requerido'
                  },
                  pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
                })}
                placeholder='Ingresa tu nombre completo'
              />
              {errors?.name?.type === 'required' && <p className='errors'>{errors.name.message}</p>}
              {errors?.name?.type === 'pattern' && <p className='errors'>'EL nombre no debe tener caracteres especiales'</p>}
            </div>
            <div className='form-group'>
              <label htmlFor='Celular'>Celular:</label>
              <input
                id='number'
                type='number'
                {...register('phone', {
                  required: {
                    value: true,
                    message: 'El numero de telefono es obligatorio'
                  },
                  pattern: /^3[0-2]{1}[0-9]{1}[0-9]{7}$/,
                  onChange: () => getInputNumber()
                })}
                placeholder='Ingresa tu numero de celular'
              />
              {errors?.phone?.type === 'required' && <p className='errors'>{errors.phone.message}</p>}
              {errors.phone?.type === 'pattern' && <p className='errors'>Ingrese un numero de telefono valido.</p>}
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email:</label>
              <input
                type='text'
                {...register('email', {
                  required: {
                    value: true,
                    message: 'El email es requerido'
                  },
                  pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
                })}
                placeholder='Ingresa tu email'
                disabled
              />
              {errors?.email?.type === 'required' && <p className='errors'>{errors.email.message}</p>}
              {errors?.email?.type === 'pattern' && <p className='errors'>'EL correo ingresado no es un correo valido.'</p>}
            </div>
          </div>
          <hr />
          <div className='group-instagran'>
            <p className='info-instagran'>
              <span>Instagran</span>
              {user.instagram_username ? <span style={{ color: 'red' }}>{user.instagram_username}</span> : <span style={{ color: 'red' }}>@instagram-de-prueba</span>}
            </p>
            <button className='delete-instagran' type='button' onClick={handlerUnlinkInstagram}>
              {iconsImages?.DELETE ? <img src={iconsImages.DELETE.images.sm} alt={iconsImages.DELETE.key} /> : <img src={ic_delete} alt='Unlink Instagram' />}
            </button>
          </div>
          <hr />
          <div className='group-buttons edit-buttons'>
            <input type='submit' value='Guardar información' className='btn btn-user-submit btn-save-edit' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default AccoundEditPerfil
