import { ListImages, PetReport } from '../../../types'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import ModalGetAddress from '../../components/Modal/ModalGetAddress'
import ModalImages from '../../components/Modal/ModalImages'
import { getBreed } from '../../../utils/getBreed'
import { getErrorsImages } from '../../../utils/getErrorsImages'
import { getPetType } from '../../../services/getPetType'
import { useForm } from 'react-hook-form'
import { useFormReportPetsLost } from '../../../hooks/useFormReportPetsLost'
import { useGetToken } from 'hooks/useGetToken'

interface Props {
  modalAdr: Boolean
  setModalAdr: (c: Boolean) => void
}
interface initialFormReportPetsLost {
  address: string
  pet_type: string
  report_type: string
  pet_name: string
  description: string
  breed: string
  images: string
}

const PetLostReports = ({ modalAdr, setModalAdr }: Props) => {
  const {
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<initialFormReportPetsLost>({
    defaultValues: {
      address: ''
    }
  })
  const [addr, setAddr] = useState<any>({ name: '' })
  const [cantImages, setCantImages] = useState<ListImages>({ images: [], imagesDel: [] })
  const [data, setData] = useState<initialFormReportPetsLost>()
  const [error, setError] = useState<string>('')
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [isValidated, setIsValidated] = useState<Boolean | boolean>(false)
  const { petBreed, petType, setPetBreed, setPetType, petReports, updateReportId } = useContext(MappetContext)
  const { data: token } = useGetToken()
  const { sentData } = useFormReportPetsLost(data, addr, cantImages)
  const [updateReport, setUpdateReport] = useState<PetReport | undefined>()

  const onSubmit = handleSubmit((values) => {
    if (!error) {
      setData(values)
      sentData(values, cantImages, updateReport, setUpdateReport)
      reset()
      setCantImages({ images: [], imagesDel: [] })
      setAddr({ name: '' })
      setIsValidated(!isValidated)
    }
  })
  const handlerChangePetType = (name: string = 'Perro') => {
    if (token) getBreed(name, petType, setPetBreed, token)
  }
  const handlerChangeCantImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) console.log('entro al evento')
    if (!error)
      setCantImages({
        ...cantImages,
        images: [...cantImages.images, e.target.files?.item(0)!]
      })
    e.target.type = 'text'
    e.target.type = 'file'
  }
  const handleDisplayPet = (reportType: string) => {
    let dispaley_pet = document.getElementsByClassName('row-dos').item(0) as HTMLDivElement
    if (reportType === 'found') {
      dispaley_pet.style.display = 'none'
    } else {
      dispaley_pet.style.display = 'flex'
    }
    // handleChange(e)
  }

  useEffect(() => {
    if (token) {
      //obteniendo los tipos de mascotas

      getPetType(token)
        .then((res) => {
          setPetType(res.results)
        })
        .catch((error) => console.log(error))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])
  useEffect(() => {
    setValue('address', updateReport ? updateReport?.address : addr.name, { shouldValidate: isValidated as boolean })
  }, [addr.name, setValue, setModalAdr, isValidated, updateReport])

  useEffect(() => {
    if (updateReportId) {
      setUpdateReport(petReports.find((element) => element.id === updateReportId))
    }

    if (!updateReportId) {
      setUpdateReport(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (token) {
      getBreed(updateReport ? updateReport.pet_type : 'Perro', petType, setPetBreed, token)
      setTimeout(() => {
        updateReport && setValue('breed', updateReport?.breed!)
      }, 1500)
    }
  }, [updateReport, setPetBreed, petType, setValue, token])
  useEffect(() => {
    const dataTransfer = new DataTransfer()

    if (updateReport) {
      const files = updateReport.report_image.map((img, index) => {
        const blob = img.image as unknown as Blob
        const file = new File([blob], `${img.image}`, { type: blob.type })
        dataTransfer.items.add(file)
        return file
      })

      const inputFile = document.querySelector('#img-report') as HTMLInputElement
      inputFile.files = dataTransfer.files
      setValue('description', updateReport?.description)
      setValue('pet_name', updateReport?.pet_name)
      setValue('pet_type', updateReport?.pet_type)
      setValue('report_type', updateReport?.report_type)
      setCantImages({
        ...cantImages,
        images: [...files],
        imagesDel: updateReport.report_image.map((image) => image)
      })
    }

    handleDisplayPet(updateReport?.report_type!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, updateReport])
  return (
    <>
      <form onSubmit={onSubmit} className='form-pet-lost' encType='multipart/form-data'>
        <div className='form-report'>
          <label htmlFor='address'>Ubicación</label>

          <input
            id='address'
            type='text'
            {...register('address', {
              required: {
                value: true,
                message: 'Ubicación requerida'
              },
              onBlur: () => {
                setIsValidated(true)
              }
            })}
            placeholder='Ingrese el nombre del lugar'
            onClick={() => setModalAdr(true)}
            autoComplete='off'
            readOnly
          />
          {errors?.address && <span className='errors'>{errors.address.message}</span>}
        </div>
        <div className='row row-uno'>
          <div className='form-report'>
            <label htmlFor='pet_type'>Tipo de mascota</label>
            <select
              {...register('pet_type', {
                onChange: (e) => handlerChangePetType(e.target.value)
              })}
              id='pet_type'
            >
              {petType?.map((pet) => (
                <option key={pet.id} value={pet.name}>
                  {pet.name}
                </option>
              ))}
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
          </div>

          <div className='form-report'>
            <label htmlFor='report_type'>Tipo de reporte</label>
            <select
              {...register('report_type', {
                onChange: (e) => handleDisplayPet(e.target.value)
              })}
              id='report_type'
              defaultValue={updateReport?.report_type}
            >
              <option value='missing'>Perdí una mascota</option>
              <option value='found'>Encontre una mascota</option>
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
          </div>
        </div>
        <div className='row row-dos'>
          <div className='form-report'>
            <label htmlFor='pet_name'>Nombre de tu mascota:</label>
            <input type='text' placeholder='Ingrese el nombre de la mascota' {...register('pet_name')} id='pet_name' />
          </div>
          <div className='form-report'>
            <label htmlFor='breed'>Raza</label>
            <select
              {...register('breed', {
                required: {
                  value: getValues().report_type === 'missing' ? true : false,
                  message: 'Por favor elija una raza.'
                }
              })}
              id='breed'
            >
              <option value=''>Selecciona una raza</option>
              {petBreed?.map((breed) => (
                <option key={breed.id} value={breed.breed_name}>
                  {breed.breed_name}
                </option>
              ))}
            </select>
            <i className='fa-solid fa-caret-down arrow-down'></i>
            {errors.breed && <span className='errors'>{errors.breed.message}</span>}
          </div>
        </div>
        <div className='form-report'>
          <label htmlFor='description'>Descripción:</label>
          <textarea
            placeholder='Ingrese una descripción de la mascota'
            {...register('description', {
              required: {
                value: true,
                message: 'Ingrese una descripción de la mascota'
              }
            })}
            id='description'
          />
          {errors?.description && <span className='errors'>{errors.description.message}</span>}
        </div>
        <div className='form-buttom-pet-lost'>
          <label htmlFor='img-report' className='btn btn-photo'>
            cargar fotografias ({cantImages.images?.length! === 0 ? 0 : cantImages.images.length})
          </label>
          <input
            type='file'
            id='img-report'
            hidden
            {...register('images', {
              onChange: (e) => {
                handlerChangeCantImages(e)
                if (!error) setError(e.target.files[0] !== undefined ? getErrorsImages(e.target.files[0].name) : '')
              }
            })}
            disabled={cantImages.images.length >= 5 ? true : false}
            accept='image/jpeg, image/png, image/gif, image/bmp, image/jpg'
          />

          <input type='submit' className='btn btn-report' value='Enviar Reporte' />
        </div>
        {cantImages.images.length > 0 && (
          <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setImageModal(true)}>
            ver fotos({cantImages.images.length})
          </p>
        )}
        {error && <span className='errors'>{error}</span>}
      </form>
      {modalAdr && <ModalGetAddress setAddr={setAddr} setModalAdr={setModalAdr} />}
      {imageModal && <ModalImages setImageModal={setImageModal} cantImages={cantImages} setCantImages={setCantImages} setError={setError} />}
    </>
  )
}

export default PetLostReports
