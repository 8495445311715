import { ListImages, PetReport } from '../types'

import { MappetContext } from '../utils'
import { createReportPetLots } from '../services/createReportPetLost'
import { saveListImages } from '../services/saveListImages'
import toast from 'react-hot-toast'
import { updateReport } from '../services/updateReport'
import { useContext } from 'react'
import { useGetToken } from './useGetToken'

export const useFormReportPetsLost = (data: any, addr: any, cantImages: ListImages) => {
  const { updateReportId, setUpdateReportId } = useContext(MappetContext)
  const { data: token } = useGetToken()

  const sentData = (data: any, cantImages: ListImages, updateReportData: PetReport | undefined, setUpdateReportData: React.Dispatch<React.SetStateAction<PetReport | undefined>>) => {
    if (data.report_type === 'found') {
      data.breed = 'sin raza'
      data.pet_name = 'sin nombre'
    }
    if (addr.name !== '') {
      data.address = addr.address
      data.longitude = addr.location.lng
      data.latitude = addr.location.lat
    }

    if (addr.name === '') {
      data.address = updateReportData?.address
      data.longitude = updateReportData?.longitude
      data.latitude = updateReportData?.latitude
    }

    if (!updateReportId) {
      createReportPetLots(data, token)
        .then((res) => {
          saveListImages(res.id, cantImages.images, token).then((img) => console.log('imagenes_res::', img))
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>Reporte Guardado con exito</p>
              <p>Los datos se guardaron exitosamente</p>
            </div>,
            {
              position: 'top-right',
              style: {
                color: '#ffffff',
                backgroundColor: 'red'
              }
            }
          )
        })
        .catch(() => console.log('Error de conexión'))
    }

    if (updateReportId) {
      updateReport(updateReportId, data, token)
        .then((res) => {
          saveListImages(res.id, cantImages.images, token).then((img) => console.log('imagenes_res::', img))
          setUpdateReportId(null)
          toast(
            <div>
              <p style={{ fontWeight: 'bold' }}>Reporte Editado con exito</p>
              <p>Los datos se Editaron exitosamente</p>
            </div>,
            {
              position: 'top-right',
              style: {
                color: '#ffffff',
                backgroundColor: 'red'
              }
            }
          )
          setUpdateReportData(undefined)
        })
        .catch((err) => console.log(err))
    }
  }
  return {
    sentData
  }
}
