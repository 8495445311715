import React, { useContext, useEffect } from 'react'

import { MappetContext } from '../../../utils'

interface Props {
  setCloseFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const DropDownFilter = ({ setCloseFilter }: Props) => {
  const { setTypeView, typeView } = useContext(MappetContext)
  const handleTypeView = (value: string) => {
    setCloseFilter(false)
    setTypeView(value)
  }
  useEffect(() => {
    if (typeView === 'map') {
      document.getElementById('viewMap')?.setAttribute('checked', 'true')
      document.getElementById('viewCard')?.removeAttribute('checked')
    } else {
      document.getElementById('viewCard')?.setAttribute('checked', 'true')
      document.getElementById('viewMap')?.removeAttribute('checked')
    }
  }, [typeView])
  return (
    <div className='main-dropdown'>
      <section className='title-dropdown'>
        <i className='fa-sharp fa-solid fa-filter'></i>
        <h2>Filtros</h2>
      </section>
      <hr />
      <section className='type-view-dropdown'>
        <div className='group-type'>
          <input type='radio' name='type-view' value='map' id='viewMap' hidden onChange={(e) => handleTypeView(e.target.value)} />
          <label htmlFor='viewMap'>Vista tipo mapa. (por defecto)</label>
        </div>
        <div className='group-type'>
          <input type='radio' name='type-view' value='card' id='viewCard' hidden onChange={(e) => handleTypeView(e.target.value)} />
          <label htmlFor='viewCard'>Vista tipo tarjetas.</label>
        </div>
      </section>
    </div>
  )
}

export default DropDownFilter
