import './SurveyStyles.css'
import '../../../assets/css/styles.css'

import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import SurveyQuestion from './SurveyQuestion'
import { imgElement3 } from '../../../assets/images/mappet'
import { useNavigate } from 'react-router-dom'

const Survey = () => {
  const { user, setShowFinishedSurveyModal } = useContext(MappetContext)
  const nav = useNavigate()

  const petName = 'Greíz'

  const survey = {
    mainMessage: `Estamos preocupados por ${petName}, queremos saber si su nutrición es correcta, no se tomará más de 4 minutos:`,
    questions: [
      {
        question: 'Pregunta 1 de encuesta..',
        answerType: 'text'
      },
      {
        question: 'Pregunta 2 de encuesta',
        answerType: 'text'
      }
    ],
    finishedMessage: 'Texto dinámico'
  }

  const onSubmit = () => {
    setShowFinishedSurveyModal(survey.finishedMessage)
    nav('/main/account')
  }

  return (
    <div className='survey-view'>
      <button className='back-button' onClick={() => nav('/main/account')}>
        <i className='fa-solid fa-arrow-left'></i>
      </button>
      <img src={imgElement3} className='corner-decoration' alt='' />
      <h2>Hola, {user?.name}</h2>
      <p>{survey.mainMessage}</p>
      <form action='' encType='multipart/form-data' onSubmit={onSubmit}>
        {survey.questions.map((question) => {
          return <SurveyQuestion question={question} />
        })}
        <div className='survey-btn-container'>
          <input type='submit' className='btn survey-btn' value='Enviar datos' />
        </div>
      </form>
    </div>
  )
}

export default Survey
