import './ModalValidateWhatsApp.css'

import Modal from '../Modal'
import React from 'react'
import ic_back from '../../../../assets/images/mappet/ic_back.png'
import { useNavigate } from 'react-router-dom'

type Props = {
  setModalValidate: React.Dispatch<React.SetStateAction<boolean>>
}
const ModalValidateWhatsApp = ({ setModalValidate }: Props) => {
  const redirectUrl = useNavigate()
  function handleSubmit(event: React.FocusEvent<HTMLFormElement>) {
    event.preventDefault()
    redirectUrl('/main/register/users')
  }
  return (
    <Modal>
      <div className='container-form form-validate-whatsapp-main'>
        <header className='header-validate-whatsapp'>
          <img src={ic_back} alt='ocultar el modal' onClick={() => setModalValidate(false)} />
        </header>
        <p>Ingresa el número de 4 digitos que llego a tu WhatsApp para continuar</p>
        <form className='form form-validate-whatsapp' onSubmit={handleSubmit}>
          <div className='form-data'>
            <div className='form-group'>
              <label htmlFor='number'>Ingresa tu codigo de verificación:</label>
              <input style={{ width: '30%', minWidth: '300px' }} className='cell-phone' type='number' id='number' placeholder='Ex. 1234' />
            </div>
          </div>
          <div className='form-group button-validate'>
            <button className='button button-wcolor'>Validar</button>
          </div>
        </form>
      </div>
      )
    </Modal>
  )
}

export default ModalValidateWhatsApp
