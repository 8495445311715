const getUserLocation = async (): Promise<{ lat: number; lng: number }> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({ lat: coords.latitude, lng: coords.longitude })
      },
      (err) => {
        reject({ error: err })
      }
    )
  })
}

export default getUserLocation
