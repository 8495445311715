const ModalGetCoords = () => {
  return (
    <div className='modal-location'>
      <div className='main-get-coord'>
        <p>Debe permitir la ubicacion en su dispositivo o Computadora para una mejor experiencia con la App. Cuando lo hagas solo actualiza la pagina y dale permitir ubicación.</p>
      </div>
    </div>
  )
}

export default ModalGetCoords
