import { UserData } from '../types'

export const getListPets = async (user: UserData, token: string) => {
  const URL = process.env.REACT_APP_BACKEND_URL
  const API_TOKEN = token
  const response = await fetch(`${URL}/api/v1/pets/list/?profile_id=${user.id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const resListPets = await response.json()
  resListPets.results.forEach((list: any) => {
    list.images.forEach((element: any) => {
      element.image = element.image.replace(process.env.REACT_APP_BACKEND_URL_NO_PORT, URL)
    })
  })
  return resListPets
}
