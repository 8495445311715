import React, { useContext, useEffect, useRef, useState } from 'react'

import DropDownCategories from './DropDownCategories'
import DropDownDirections from './DropDownDirections'
import DropDownFilter from './DropDownFilter'
import { MappetContext } from '../../../utils'
import { PetDefaultImg } from '../../../assets/images/default_images'
import useGetDirections from '../../../hooks/useGetDirections'

export type DropDownFilters = {
  lengthFilters: number
  image: string
  nameFilter: string
  valueName: string
}

type Props = {
  setNameFilters: React.Dispatch<React.SetStateAction<string>>
}

const Filters = ({ setNameFilters }: Props) => {
  const [filterSelect, setFilterSelect] = useState<DropDownFilters>()
  const [closeFilter, setCloseFilter] = useState<boolean>(false)
  const [closeCategorie, setCloseCategorie] = useState<boolean>(false)
  const debounceGetDirection = useRef<NodeJS.Timeout>()
  const { userLocation, iconsImages } = useContext(MappetContext)
  const coords = useRef<number[]>([userLocation?.lat as number, userLocation?.lng as number])

  const { handleGetDirection, directions, setDirections } = useGetDirections(debounceGetDirection)

  const showFilter = () => {
    setCloseFilter(!closeFilter)
    setCloseCategorie(false)
  }
  const showCategory = () => {
    setCloseCategorie(!closeCategorie)
    setCloseFilter(false)
  }
  useEffect(() => {
    if (filterSelect?.valueName) {
      setNameFilters(filterSelect.valueName)
    }
  }, [filterSelect, setNameFilters])
  return (
    <div className='main-filter'>
      <div className='group-input'>
        <i className='fa-sharp fa-solid fa-magnifying-glass'></i>
        <input
          type='text'
          placeholder='EX. Cocoa'
          onChange={(e) => handleGetDirection(e.target.value)}
          id='address-map'
          onClick={() => {
            setCloseCategorie(false)
            setCloseFilter(false)
          }}
        />
        {directions.length > 0 && <DropDownDirections coord={coords} directions={directions} setDirections={setDirections} />}
      </div>
      <div className='group-input-filter'>
        <div className='filter-map'>
          {closeFilter ? (
            <>
              <i className='fa-solid fa-xmark' onClick={() => showFilter()}></i>
              <DropDownFilter setCloseFilter={setCloseFilter} />
            </>
          ) : (
            <i className='fa-sharp fa-solid fa-filter' onClick={() => showFilter()}></i>
          )}
        </div>
        <div className='group-filter'>
          {!filterSelect ? (
            <>
              <p>Sin Resultados</p>
              <span>Seleccionar categoria</span>
            </>
          ) : (
            <>
              <p>{filterSelect.nameFilter}</p>
              <span>{filterSelect.lengthFilters} resultados</span>
            </>
          )}
          <div className='img-filter' onClick={() => showCategory()}>
            {closeCategorie ? (
              <>
                <span className='close-categorie'>X</span>
              </>
            ) : !filterSelect ? (
              <img src={iconsImages?.DEFAULT_ICON ? iconsImages.DEFAULT_ICON.images.sm : PetDefaultImg} alt='filter images' />
            ) : (
              <img src={filterSelect.image} alt='filterimages' />
            )}
          </div>
          {closeCategorie && <DropDownCategories coord={coords} setCloseCategory={setCloseCategorie} setCloseFilter={setCloseFilter} setFilterSelect={setFilterSelect} />}
        </div>
      </div>
    </div>
  )
}

export default Filters
