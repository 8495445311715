export const getUserPlaceComments = async (place_id: number, token: string) => {
  const API_TOKEN = token
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/places/list/user/comments/?place_id=${place_id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
      Authorization: `${API_TOKEN}`
    }
  })
  const userPlaceComments = await response.json()
  return userPlaceComments
}
