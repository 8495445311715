import React, { useContext, useState } from 'react'

import { MappetContext } from '../../../utils'
import Modal from './Modal'
import { getAddress } from '../../../services/getAddress'

interface Props {
  setModalAdr: (c: Boolean) => void
  setAddr: (c: Object) => void
}
const ModalGetAddress = ({ setAddr, setModalAdr }: Props) => {
  const [arrayAddress, setArrayAddress] = useState<any[]>([])
  const { userLocation } = useContext(MappetContext)
  let name_address: string | undefined
  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    name_address = e.currentTarget.value
  }
  const handlerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (name_address) {
      getAddress(name_address, [userLocation?.lat as number, userLocation?.lng as number]).then((address) => {
        setArrayAddress(address)
      })
    } else {
      setArrayAddress([])
    }
  }
  return (
    <Modal>
      <div className='container-modal-address'>
        <span className='close-modal-address' onClick={() => setModalAdr(false)}>
          X
        </span>
        <form onSubmit={handlerSubmit}>
          <input className='input-address' type='text' placeholder='Ingrese la direccion' onChange={(e) => handleChangeAddress(e)} value={name_address} autoFocus />
        </form>
        <div className='list-address'>
          {arrayAddress.length === 0 ? (
            <p>Sin direccion que mostrar</p>
          ) : (
            arrayAddress?.map((adr, index) => (
              <div
                key={index}
                className='address-info'
                onClick={() => {
                  setAddr(adr)
                  setModalAdr(false)
                }}
              >
                <h4>{adr.name}</h4>
                <p>{adr.address}</p>
                <hr />
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalGetAddress
