import './ServicesStyles.css'
import '../../../assets/css/styles.css'

import { Comment, CommentPlaceModal, DetailSlider } from '../'
import React, { useContext, useEffect, useState } from 'react'
import { getAllPlaceComments, getMyPlaces } from '../../../services'

import Background1 from '../../../assets/images/backgrounds/img-element2.png'
import { GooglePlace } from '../../../types'
import { MappetContext } from '../../../utils'
import RatingStars from '../RatingStars/RatingStars'
import { ReportHook } from '../../../hooks/reportHook'
import { imgMappet } from '../../../assets/images/mappet'
import { saveUserInteractions } from '../../../services/saveUserInteractions'
import { useGetToken } from 'hooks/useGetToken'
import useListPlaces from '../../../hooks/useListPlaces'

export interface PlaceDetailServiceProps {
  placeData: GooglePlace
}

const PlaceDetailService = ({ placeData }: PlaceDetailServiceProps) => {
  const place_types = {
    veterinary_care: 'Veterinaria',
    cafe: 'Café',
    restaurant: 'Restaurante',
    bar: 'Bar',
    shopping_mall: 'Comercial',
    park: 'Parque',
    pet_store: 'Tienda de mascotas'
  }

  const { showCommentPlaceModal, setShowCommentPlaceModal, setRouteCoords, setGoogleRouteResponse, setShowPlaceMap, setTypeView, typeView, user, userInteraction, setUserInteraction, iconsImages } =
    useContext(MappetContext)
  const { data: token } = useGetToken()
  const { placeComments, setPlaceComments } = ReportHook()
  const [loadingComments, setLoadingComments] = useState<boolean>(true)
  const { arrayImages, setListPlaces } = useListPlaces({ placeData }, placeComments)
  useEffect(() => {
    if (token) {
      setPlaceComments([])
      setLoadingComments(true)

      getAllPlaceComments(placeData.google_place_id!, token)
        .then((res) => {
          setPlaceComments(res.results)
          setLoadingComments(false)
        })
        .catch((err) => setLoadingComments(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeData.google_place_id, setListPlaces, setPlaceComments, showCommentPlaceModal, token])

  useEffect(() => {
    getMyPlaces(token).then((res) => {
      setListPlaces(res.results)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListPlaces, placeComments, token])

  useEffect(() => {
    setUserInteraction({
      ...userInteraction,
      profile: user,
      profile_id: user?.id as number,
      latitude: placeData.latitude,
      longitude: placeData.longitude,
      place: placeData.google_place_id,
      action: placeData.name,
      action_category: 'PointCard',
      action_config: { typeView }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeData.google_place_id])

  useEffect(() => {
    if (userInteraction.profile_id && token) {
      saveUserInteractions(userInteraction, token).then((res) => {
        console.log('ok')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInteraction, token])

  return (
    <div className='place-detail-service'>
      {showCommentPlaceModal && <CommentPlaceModal placeData={showCommentPlaceModal} />}
      {/* {
                routeCoords &&
                    <CommentPlaceModal placeData={showCommentPlaceModal!} />
            } */}
      <button
        className='back-button'
        onClick={() => {
          setShowPlaceMap(false)
          setUserInteraction({})
        }}
      >
        {iconsImages?.BACK ? <img src={iconsImages.BACK.images.sm} alt='back images' /> : <i className='fa-solid fa-arrow-left'></i>}
      </button>
      <button
        className='path-button'
        onClick={() => {
          setTypeView('map')
          setGoogleRouteResponse(null)
          setRouteCoords(null)
          setShowPlaceMap(false)
          setRouteCoords({
            from: {
              lat: placeData.latitude,
              lng: placeData.longitude
            },
            to: {
              lat: placeData.latitude,
              lng: placeData.longitude
            }
          })
        }}
      >
        {iconsImages?.GPS ? <img src={iconsImages.GPS.images.sm} alt='gpd images' /> : <i className='fa-regular fa-paper-plane'></i>}
      </button>
      <div className='details-imgs'>
        {/* {!reportData.place_images ? ( */}
        {arrayImages.length > 0 ? <DetailSlider slides={arrayImages as string[]} /> : <img className='report-img' src={imgMappet} alt='report-img1' />}
        {/* ) : reportData.place_images.length === 1 ? (
                <img className='report-img' src={reportData.place_images[0].image} alt='report-img2' />
                ) : (
                //   <DetailSlider slides={reportImages} />
                )} */}
        {iconsImages?.BACKGROUND_IMAGE_2 ? <img className='background-img' src={iconsImages.BACKGROUND_IMAGE_2.images.md} alt='' /> : <img className='background-img' src={Background1} alt='' />}
        <img className='background-img' src={Background1} alt='' />
      </div>
      <h2>{placeData.name}</h2>
      {placeData.average_rating === 0 ? <p className='no-rating'>Sin calificación</p> : <RatingStars rating={placeData.average_rating} />}
      <p className='place-categories'>{place_types[placeData.type]}</p>
      <p className='place-distancia'>Distancia: {placeData.distance}Km</p>
      <hr />
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tellus nisl, scelerisque sit amet urna vitae, condimentum placerat arcu. Morbi iaculis sed urna in porttitor. </p>
      <hr />
      <div className='comments-container'>
        {loadingComments ? (
          <p className='loading-message'>Cargando comentarios...</p>
        ) : placeComments.length > 0 ? (
          placeComments.map((placeComment, index) => {
            return <Comment comment={placeComment} key={index} />
          })
        ) : (
          <p className='loading-message'>No hay comentarios que mostrar</p>
        )}
      </div>
      <hr />
      <div className='btn-calificar-container'>
        <button className='btn btn-calificar' onClick={() => setShowCommentPlaceModal(placeData)}>
          Calificar
        </button>
      </div>
    </div>
  )
}

export default PlaceDetailService
