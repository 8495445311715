export const mostrarModalSuccess = (id_ref: string) => {
  if (id_ref === 'edit-profile-success') {
    document.getElementById('edit-profile-success')?.setAttribute('style', 'animation:modal-message 6s ease')
    setTimeout(() => {
      document.getElementById('edit-profile-success')?.removeAttribute('style')
    }, 4000)
  } else if (id_ref === 'reg-success') {
    document.getElementById('reg-success')?.setAttribute('style', 'animation:modal-message 6s ease')
    setTimeout(() => {
      document.getElementById('reg-success')?.removeAttribute('style')
    }, 4000)
  }
}
