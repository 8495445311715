import '../../../assets/css/styles.css'
import './NavbarStyles.css'

import { Link, useLocation } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'

import { MappetContext } from '../../../utils'
import { imgMappet } from '../../../assets/images/mappet'

const Navbar = () => {
  const { setIsRegistering } = useContext(MappetContext)
  const { iconsImages } = useContext(MappetContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsRegistering(false), [])

  const [selection, setSelection] = useState<string>('')
  const { pathname } = useLocation()

  useEffect(() => setSelection(pathname), [pathname])

  const { navbarCollapseClass, setNavbarCollapseClass } = useContext(MappetContext)
  const onToggleCollapse = () => {
    let collapseClass: string = 'collapse'
    setNavbarCollapseClass(navbarCollapseClass.includes(collapseClass) ? '' : collapseClass)
  }

  return (
    <nav className={`side-nav ${navbarCollapseClass}`}>
      <button onClick={() => onToggleCollapse()} className={`close-collapse-nav ${navbarCollapseClass}`}>
        {/* <button onClick={() => onToggleCollapse()} className={`close-collapse-nav collapse`}> */}
        <i className='fa-solid fa-xmark'></i>
      </button>
      <Link className='nav-brand' to='/'>
        {iconsImages?.DEFAULT_ICON ? (
          <img className='nav-logo' src={iconsImages.DEFAULT_ICON.images.original} alt={iconsImages.DEFAULT_ICON.key} />
        ) : (
          <img className='nav-logo' src={imgMappet} alt='' />
        )}
      </Link>
      <ul>
        <li>
          <Link to='/main/services' id='services-button' className={`nav-opt button ${selection === '/main/services' ? 'selected' : ''}`} onClick={() => onToggleCollapse()}>
            {iconsImages?.SERVICES_ACTIVE ? <img src={iconsImages?.SERVICES_ACTIVE.images.sm} alt='services active'></img> : <i className='fa fa-thin fa-map-pin'></i>}
            <span>Servicios</span>
          </Link>
        </li>
        <li>
          <Link to='/main/reports' id='reports-button' className={`nav-opt button ${selection === '/main/reports' ? 'selected' : ''}`} onClick={() => onToggleCollapse()}>
            {iconsImages?.REPORTS ? <img src={iconsImages?.REPORTS.images.sm} alt='reports active'></img> : <i className='fa-regular fa-user'></i>}

            <span>Reportes</span>
          </Link>
        </li>
        <li>
          <Link to='/main/account' id='account-button' className={`nav-opt button ${selection === '/main/account' ? 'selected' : ''}`} onClick={() => onToggleCollapse()}>
            {iconsImages?.PROFILE_ACTIVE ? <img src={iconsImages.PROFILE_ACTIVE.images.sm} alt='profile active'></img> : <i className='fa-regular fa-user'></i>}
            <span>Cuenta</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
