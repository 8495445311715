import './PetListStyles.css'

import React, { useContext } from 'react'

import { MappetContext } from '../../../utils'
import { Pet } from '../../../types'
import ic_delete from '../../../assets/images/mappet/ic_delete.png'
import ic_edit from '../../../assets/images/mappet/ic_edit.png'
import imgDefault from '../../../assets/images/profile/huella.png'

const petsDefault = ['ave', 'perro', 'gato', 'pez']
interface Props {
  setIdPet: (id: string) => void
  setOtherTypes: React.Dispatch<React.SetStateAction<string>>
}

const PetList = ({ setIdPet, setOtherTypes }: Props) => {
  const { petList, setModalAddPet, setPetData, iconsImages } = useContext(MappetContext)

  const handleEdit = (data: Pet | undefined) => {
    setPetData(data as Pet)
    if (!petsDefault.includes(data?.breed?.pet_type.name.toLocaleLowerCase() as string)) {
      setOtherTypes(data?.breed?.pet_type.name as string)
    }
  }

  const handlerShowModal = (id: string) => {
    setIdPet(id)
    setModalAddPet(true)
  }
  return (
    <div className='container-main'>
      <h4>Mascotas Registradas</h4>
      <div className='list-pets'>
        {petList?.length !== 0 ? (
          petList?.map((pet) => (
            <div className='pet-info' key={pet.id}>
              {iconsImages?.EMPTY_MAPPET ? (
                <img src={pet.images.length === 0 ? iconsImages.EMPTY_MAPPET.images.sm : pet.images[0]['image']} alt='pet-group' />
              ) : (
                <img src={pet.images.length === 0 ? imgDefault : pet.images[0]['image']} alt='pet-group' />
              )}
              <div className='info-pet'>
                <p>{pet.name}</p>
                <p>
                  {pet.breed?.pet_type.name} -- {pet.breed?.breed_name}
                </p>
              </div>
              <div className='buttons-pets'>
                <button className='button btn-edit' onClick={() => handleEdit(pet)}>
                  {iconsImages?.EDIT ? <img src={iconsImages.EDIT.images.sm} alt='editar mascota' /> : <img src={ic_edit} alt='editar mascota' />}
                </button>
                <button className='button btn-del' onClick={() => handlerShowModal(pet.id as string)}>
                  {iconsImages?.DELETE ? <img src={iconsImages.DELETE.images.sm} alt='Eliminar mascota' /> : <img src={ic_delete} alt='eliminar mascota' />}
                </button>
              </div>
            </div>
          ))
        ) : (
          <span className='no-pet'>No Hay mascotas registradas...</span>
        )}
      </div>
    </div>
  )
}

export default PetList
